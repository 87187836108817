import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import ContentLayout from "../../layout/ContentLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineSearch } from "react-icons/ai";
import pdfIcon from "../../assets/images/pdf-Icon.png";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import MonthWiseReportPDFDocument from "./MonthWiseReportPDFDocument";

const MonthWiseReportPage = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [month, setMonth] = useState(new Date());
  const [year, setYear] = useState(new Date());

  const [formData, setFormData] = useState({
    selected_month: month,
    selected_year: year,
  });

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const handleYearChange = (e) => {
    const selectedYear = parseInt(e.target.value, 10);
    setFormData((prevData) => ({
      ...prevData,
      selected_year: selectedYear,
    }));
    console.log("Selected year:", selectedYear);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const handleMonthChange = (e) => {
    const selectedMonth = parseInt(e.target.value, 10);
    setFormData((prevData) => ({
      ...prevData,
      selected_month: selectedMonth,
    }));
    console.log("Selected month:", selectedMonth);
  };

  const [totalSales, setTotalSales] = useState(0);
  const [totalExpenseDebit, setTotalExpenseDebit] = useState(0);
  const [totalExpenseCredit, setTotalExpenseCredit] = useState(0);
  const [totalPurchase, setTotalPurchase] = useState(0);
  const [totalPaymentDebit, setTotalPaymentDebit] = useState(0);
  const [totalPaymentCredit, setTotalPaymentCredit] = useState(0);
  const handleDataRequest = () => {
    console.log("form data:", formData);
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}month-wise-report`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        loadingCallback(false);
        if (res.data.success) {
          console.log(res.data);
          setTotalSales(res?.data?.data?.total_sales);
          setTotalExpenseDebit(res?.data?.data?.total_expense_debit);
          setTotalExpenseCredit(res?.data?.data?.total_expense_credit);
          setTotalPurchase(res?.data?.data?.total_purchase);
          setTotalPaymentDebit(res?.data?.data?.total_payment_debit);
          setTotalPaymentCredit(res?.data?.data?.total_payment_credit);

          setReports(res?.data?.data);
          console.log("sales", res?.data?.data?.total_sales);
          console.log("expense d", res?.data?.data?.total_expense_debit);
          console.log("expense c", res?.data?.data?.total_expense_credit);
        }
      })
      .catch((error) => console.log(error));
  };

  const generatePdf = async () => {
    // Extract the selected month and year from formData
    const { selected_month, selected_year } = formData;

    // Format the selected month and year for display
    const formattedMonth = months[selected_month - 1]; // Convert month index to name
    const formattedYear = selected_year;

    // Prepare the PDF document
    const doc = (
      <MonthWiseReportPDFDocument
        reports={reports}
        totalSales={totalSales}
        totalExpenseDebit={totalExpenseDebit}
        totalExpenseCredit={totalExpenseCredit}
        totalPurchase={totalPurchase}
        totalPaymentDebit={totalPaymentDebit}
        totalPaymentCredit={totalPaymentCredit}
        user={user}
        selectedMonth={formattedMonth}
        selectedYear={formattedYear}
      />
    );

    // Generate the PDF as a blob
    const pdfBlob = await pdf(doc).toBlob();

    // Create a URL for the blob and open it in a new tab
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <ContentLayout>
        <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] grid grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-12 justify-between items-center">
          {/* card */}
          <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] w-full col-span-3 items-stretch bg-[#FF7A00] text-white flex justify-between py-7 px-3 rounded-2xl">
            <div className="space-y-1">
              <p className="font-semibold whitespace-nowrap text-xl">
                <sub className="text-[9px] font-extralight ml-1">BDT</sub>
              </p>
              <p className="text-xs font-normal">Month Wise Report</p>
            </div>
          </div>
          <div className="col-span-7 items-center">
            <div className="flex  w-full  items-center mt-[12px] md:mt-0 flex-col md:flex-row font-nunito gap-2">
              <div className="mt-2 flex-1">
                <select
                  onChange={handleMonthChange}
                  className="border border-[#4383d7e6] rounded-xl w-full px-5 py-2 focus:outline-none z-20"
                >
                  <option value="">Select a month</option>
                  {months.map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-2 flex-1">
                <select
                  onChange={handleYearChange}
                  className="border border-[#4383d7e6] rounded-xl w-full px-5 py-2 focus:outline-none z-20"
                >
                  <option value="">Select a year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <button
            onClick={() => handleDataRequest()}
            className=" bg-[#034AFF] cursor-pointer col-span-2 font-nunito text-white rounded-xl px-3 xl:px-5 py-2 text-md"
          >
            Report
          </button>
        </div>
        {/* table */}
        <div>
          <div className="bg-white rounded-3xl font-nunito mt-4 p-[26px]">
            <div className="flex justify-between items-center">
              <div className="flex gap-3 items-center">
                {/* section title */}
                <h2 className="text-xl font-semibold">Month Wise Report</h2>
              </div>
              {/* search & pdf button */}
              <div className="flex gap-3 items-center">
                <div className="flex relative w-[95%]">
                  <input
                    autoFocus
                    type="text"
                    className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                    placeholder="Search Transaction"
                    // onChange={(e) => setSearchFilter({...searchFilter, keyword: e.target.value})}
                  />
                  <div className="absolute top-[17px] left-[10px]">
                    <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                  </div>

                  <div className="absolute top-[17px]  right-[10px]">
                    <div
                      // ref={menuRef}
                      // ref={buttonRef}
                      // onClick={handleButtonClick}

                      className=" relative flex flex-col items-center"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                          fill="#102048"
                        />
                        <path
                          d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                          fill="#102048"
                        />
                        <path
                          d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                          fill="#102048"
                        />
                        <path
                          d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                          fill="#102048"
                        />
                        <path
                          d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                          fill="#102048"
                        />
                        <path
                          d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                          fill="#102048"
                        />
                        <path
                          d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                          fill="#102048"
                        />
                        <path
                          d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                          fill="#102048"
                        />
                        <path
                          d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                          fill="#102048"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div
                  onClick={generatePdf}
                  className="bg-[#EBF1FF] rounded-full w-[34px] h-[34px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
                >
                  <img className="w-[16px]" src={pdfIcon} alt="pdfIcon" />
                </div>
              </div>
            </div>
            {!loading ? (


              <div className="bg-white rounded-3xl font-nunito mt-4">
                <div className="bg-white rounded-lg overflow-hidden">
                  <div className="flex mt-4 border" style={{ minHeight: "100%" }}>
                    {/* Sales Column */}
                    <div className="w-1/2 border-r flex flex-col">
                      <h3 className="px-4 py-2 font-semibold bg-gray-100 text-center border-b-2">
                        Sales
                      </h3>
                      <div className="flex-1 flex flex-col">
                        <table className="w-full text-sm table-fixed">
                          <thead className="bg-gray-100 font-semibold text-sm font-nunito">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Customer Name
                              </td>
                              <td className="px-4 py-2 text-right">
                                Credit (BDT)
                              </td>
                            </tr>
                          </thead>
                        </table>
                        <div className="overflow-y-auto flex-1">
                          <table className="w-full text-sm table-fixed">
                            <tbody className="font-medium font-nunito">
                              {reports?.sales?.length > 0 &&
                                reports.sales.map((report, idx) => (
                                  <tr key={idx}>
                                    <td className="px-4 py-1">
                                      {report.customer_name || "Walking Customer"}
                                    </td>
                                    <td className="px-4 py-1 text-right">
                                      {report.total_credit_amount || 0}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <table className="w-full text-sm table-fixed">
                          <tfoot className="bg-gray-100 font-semibold">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Total Sales
                              </td>
                              <td className="px-4 py-2 text-right">
                                {totalSales || 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    {/* Purchases Column */}
                    <div className="w-1/2 flex flex-col ">
                      <h3 className="px-4 py-2 font-semibold bg-gray-100 text-center border-b-2">
                        Purchases
                      </h3>
                      <div className="flex-1 flex flex-col">
                        <table className="w-full text-sm table-fixed">
                          <thead className="bg-gray-100 font-semibold text-sm font-nunito">
                            <tr>
                              <td className="px-4 py-2 text-left">Vendor Name</td>
                              <td className="px-4 py-2 text-right">Debit (BDT)</td>
                            </tr>
                          </thead>
                        </table>
                        <div className="overflow-y-auto flex-1">
                          <table className="w-full text-sm table-fixed">
                            <tbody className="font-medium font-nunito">
                              {reports?.purchases?.length > 0 &&
                                reports.purchases.map((purchase, idx) => (
                                  <tr key={idx}>
                                    <td className="px-4 py-2 text-left">
                                      {purchase.vendor_name || "N/A"}
                                    </td>
                                    <td className="px-4 py-2 text-right">
                                      {purchase.total_debit_amount}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <table className="w-full text-sm table-fixed">
                          <tfoot className="bg-gray-100 font-semibold">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Total Purchases
                              </td>
                              <td className="px-4 py-2 text-right">
                                {totalPurchase || 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="flex mt-4 border" style={{ minHeight: "100%" }}>
                    {/* Expenses Column */}
                    <div className="w-1/2 border-r flex flex-col">
                      <h3 className="px-4 py-2 font-semibold bg-gray-100 text-center border-b-2">
                        Expenses
                      </h3>
                      <div className="flex-1 flex flex-col">
                        <table className="w-full text-sm table-fixed">
                          <thead className="bg-gray-100 font-semibold text-sm font-nunito">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Category Name
                              </td>
                              <td className="px-4 py-2 text-right">
                                Debit (BDT)
                              </td>
                              <td className="px-4 py-2 text-right">
                                Credit (BDT)
                              </td>
                            </tr>
                          </thead>
                        </table>
                        <div className="overflow-y-auto flex-1">
                          <table className="w-full text-sm table-fixed">
                            <tbody className="font-medium font-nunito">
                              {reports?.expenses?.length > 0 &&
                                reports.expenses.map((report, idx) => (
                                  <tr key={idx}>
                                    <td className="px-4 py-1">
                                      {report.catogory_name || "N/A"}
                                    </td>
                                    <td className="px-4 py-1 text-right">
                                      {report.expense_type === "Debit"
                                        ? report.amount
                                        : 0}
                                    </td>
                                    <td className="px-4 py-1 text-right">
                                      {report.expense_type === "Credit"
                                        ? report.amount
                                        : 0}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <table className="w-full text-sm table-fixed">
                          <tfoot className="bg-gray-100 font-semibold">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Total Expenses
                              </td>
                              <td className="px-4 py-2 text-right">
                                {totalExpenseDebit || 0}
                              </td>
                              <td className="px-4 py-2 text-right">
                                {totalExpenseCredit || 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>

                    {/* Payments Column */}
                    <div className="w-1/2 flex flex-col">
                      <h3 className="px-4 py-2 font-semibold bg-gray-100 text-center border-b-2">
                        Payments
                      </h3>
                      <div className="flex-1 flex flex-col">
                        <table className="w-full text-sm table-fixed">
                          <thead className="bg-gray-100 font-semibold text-sm font-nunito">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Payment Type
                              </td>
                              <td className="px-4 py-2 text-right">
                                Debit (BDT)
                              </td>
                              <td className="px-4 py-2 text-right">
                                Credit (BDT)
                              </td>
                            </tr>
                          </thead>
                        </table>
                        <div className="overflow-y-auto flex-1">
                          <table className="w-full text-sm table-fixed">
                            <tbody className="font-medium font-nunito">
                              {reports?.payments?.length > 0 &&
                                reports.payments.map((payment, idx) => (
                                  <tr key={idx}>
                                    <td className="px-4 py-2">
                                      {payment.payment_type_name || "Cash"}
                                    </td>
                                    <td className="px-4 py-2 text-right">
                                      {payment.payment_debit || 0}
                                    </td>
                                    <td className="px-4 py-2 text-right">
                                      {payment.payment_credit || 0}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                        <table className="w-full text-sm table-fixed">
                          <tfoot className="bg-gray-100 font-semibold">
                            <tr>
                              <td className="px-4 py-2 text-left">
                                Total Payments
                              </td>
                              <td className="px-4 py-2 text-right">
                                {totalPaymentDebit || 0}
                              </td>
                              <td className="px-4 py-2 text-right">
                                {totalPaymentCredit || 0}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="custom-loader-logo">
                {/* <BeatLoader color={"#123abc"} size={20} /> */}
                <img
                  className=""
                  src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
                  alt="logo"
                />
              </div>
            )}
          </div>
        </div>
      </ContentLayout>
    </>
  );
};

export default MonthWiseReportPage;
