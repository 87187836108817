import React, { useContext } from "react";
import {
  formatPhoneNumber,
  numberToWords,
  formatPrice,
  invoiceCalculate,
} from "../../../utils/Common";
import "./PdfHtmlContentA3.css";
import { AppContext } from "../../../context/context.js";

import QRCode from "react-qr-code";
import { PiX } from "react-icons/pi";

const PdfHtmlContentA3 = (props) => {
  const { invoice, invoiceSetting, id, parentClassName, invoiceImeis } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, "0");
  const day = String(invoiceDate.getDate()).padStart(2, "0");
  const hours = String(invoiceDate.getHours() % 12).padStart(2, "0");
  const minutes = String(invoiceDate.getMinutes()).padStart(2, "0");
  const ampm = invoiceDate.getHours() >= 12 ? "PM" : "AM";
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  const processedProductIds = new Set(); // To track processed product_ids
  const imeisByProduct = [];

  console.log(invoice)

  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  let totalItemsPrint = 0;
  let totalPricePrint = 0;

  return (
    <div
      id="pdfConentAreasA3"
      className="flex items-center justify-center font-nunito"
    >
      <div>
        {" "}
        {/* id="invoice-detail-page" className="invoice-detail-page" */}
        {/* Header */}
        <div
          className="flex flex-col items-center  mx-auto text-center"
          style={{ maxWidth: "148mm", marginTop: "70px" }}
        >
          {/* Title */}
          <div className="font-bold text-lg mb-4">Sales Invoice</div>
          {/* Client Information */}
          <div className="flex justify-between w-full ">
            <div className="text-left text-xs w-1/2 ">
              <p>
                <span className="font-semibold">Client Name</span> :
                <span className="font-semibold italic">
                  {" "}
                  {invoice.customer_name}
                </span>
              </p>
            </div>

            <div className="flex flex-col justify-end items-end text-xs w-1/2 ">
              <div className="flex flex-col items-start">
                <p>
                  {" "}
                  <span>Invoice No</span> : {invoice.id}{" "}
                </p>
                <p>
                  <span>Date</span> : {formattedDateTime}
                </p>
              </div>
            </div>
          </div>
          {/* Table */}
          <div className=" mx-auto mt-4  w-full mb-10">
            <table className="table print-table border mb-0">
              <thead id="pdfContentTHead">
                <tr className="leading-normal text-sm font-nunito">
                  <th
                    className="font-nunito text-left"
                    style={{ ...styles.thserial, width: "20px" }}
                  >
                    SL
                  </th>
                  <th
                    className="font-nunito text-start "
                    style={{ ...styles.th }}
                  >
                    Product Name
                  </th>
                  <th
                    className="font-nunito text-center"
                    style={{ ...styles.th }}
                  >
                    Part Number
                  </th>
                  <th
                    className="font-nunito text-center "
                    style={{ ...styles.th }}
                  >
                    Quantity
                  </th>
                  <th
                    className="font-nunito text-center"
                    style={{ ...styles.th }}
                  >
                    Rate
                  </th>
                  <th
                    className="font-nunito text-right"
                    style={{ ...styles.th }}
                  >
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {(() => {
                  let rowCounter = 0;

                  return invoice.sales_details.map((item) => {
                    const productId = item.product_id;

                    // Skip if the product_id has already been processed
                    if (processedProductIds.has(productId)) {
                      return null;
                    }
                    let subtotalPrice = 0;
                    subtotalPrice = formatPrice(item.qty * item.price); // Calculate subtotal price for the product
                    totalQty += Number(item.qty); // Add the quantity of the product
                    totalItems += item.qty; // Count this product as a single item
                    totalPrice += Number(subtotalPrice); // Add to the total price

                    return (
                      <tr
                        key={item.id}
                        className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative"
                      >
                        <td
                          className="text-left "
                          style={{
                            padding: "3px 3px",
                            ...styles.tdserial,
                            width: "20px",
                          }}
                        >
                          {++rowCounter}
                        </td>{" "}
                        {/* SL # */}
                        <td
                          className=""
                          style={{ padding: "3px 5px", ...styles.td }}
                        >
                          {item?.product_info?.name}
                        </td>
                        <td
                          className="text-center"
                          style={{ padding: "3px 5px", ...styles.td }}
                        >
                          {item?.product_info?.serial}
                        </td>
                        <td
                          className="text-center text-sm"
                          style={{ padding: "3px 5px", ...styles.td }}
                        >
                          {item.qty}
                        </td>
                        <td
                          className="text-right text-sm"
                          style={{ padding: "3px 5px", ...styles.td }}
                        >
                          {item.price}
                        </td>
                        <td
                          className="text-right font-semibold md:font-semibold text-sm"
                          style={{ padding: "3px 5px", ...styles.td }}
                        >
                          {subtotalPrice}
                        </td>
                      </tr>
                    );
                  });
                })()}
                {/* Subtotal Row */}
                <tr
                  className="font-nunito text-sm lg:text-base border-t border-[#E7EBF4]"
                  style={{ ...styles.tr }}
                >
                  <td
                    colSpan="2"
                    className="text-left font-bold"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    Sub Total :
                  </td>
                  <td
                    colSpan="4"
                    className="text-right font-semibold md:font-semibold text-sm"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    {formatPrice(totalPrice)}
                  </td>
                </tr>
                {/* Add and Less Row */}
                <tr
                  className="font-nunito text-sm lg:text-base border-t border-[#E7EBF4]"
                  style={{ ...styles.tr }}
                >
                  <td
                    colSpan="2"
                    className="text-left font-bold"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    Add & Less
                  </td>
                  <td
                    colSpan="4"
                    className="text-right font-semibold md:font-semibold text-sm"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    {formatPrice(invoice?.discount)}
                  </td>
                </tr>
                {/* Invoice Total */}
                <tr
                  className="font-nunito text-sm lg:text-base border-t border-[#E7EBF4]"
                  style={{ ...styles.tr }}
                >
                  <td
                    colSpan="2"
                    className="text-left font-bold"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    Invoice Total :
                  </td>
                  <td
                    colSpan="4"
                    className="text-right font-semibold md:font-semibold text-sm"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    {formatPrice(totalPrice - invoice?.discount)}
                  </td>
                </tr>
                {/* Due Amount */}
                <tr
                  className="font-nunito text-sm lg:text-base border-t border-[#E7EBF4]"
                  style={{ ...styles.tr }}
                >
                  <td
                    colSpan="2"
                    className="text-left font-bold"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    Due Amount
                  </td>
                  <td
                    colSpan="4"
                    className="text-right font-semibold md:font-semibold text-sm"
                    style={{ padding: "3px 5px", fontSize: "12px" }}
                  >
                    {formatPrice(
                      totalPrice - invoice?.discount - invoice?.paid_amount
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
            {/* amount in words */}
            <p className="text-gray-900 font-nunito text-xs  lg:text-base font-semibold leading-normal text-left pl-4 mt-1">
              <span style={{ color: "#A0AEC0" }}>
                Amount in Word : (in{" "}
                {invoiceSetting?.currency_info?.code
                  ? invoiceSetting?.currency_info?.code
                  : " BDT"}
                )
              </span>{" "}
              {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))}{" "}
              Only
            </p>
            {/* narration */}
            {/* <p className=" mt-4 font-nunito text-xs  lg:text-base font-bold leading-normal text-left pl-4">
                  <span>Narration : </span>Paid by {invoice.multiple_payment.map(payment => payment.payment_type.type_name).join(', ').replace(/, ([^,]*)$/, ' and $1')}

                </p> */}
            {formatPrice(totalPrice - invoice?.discount - invoice?.paid_amount) > 0 ? (
  <div style={styles.paymentMethodDivRed}>
    {/* DUE SVG */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 150"
      style={{ width: "100%", height: "100%" }}
    >
      <ellipse
        cx="150" // Horizontal center
        cy="75" // Vertical center
        rx="140" // Horizontal radius
        ry="65" // Vertical radius
        fill="none"
        stroke="red" // Border color
        strokeWidth="8" // Border thickness
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="50"
        fontWeight="bold"
        fill="red"
        style={{ fontFamily: "Arial, sans-serif" }}
      >
        DUE
      </text>
    </svg>
  </div>
) : (
  <div style={styles.paymentMethodDivBlue}>
    {/* PAID SVG */}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 150"
      style={{ width: "100%", height: "100%" }}
    >
      <ellipse
        cx="150" // Horizontal center
        cy="75" // Vertical center
        rx="140" // Horizontal radius
        ry="65" // Vertical radius
        fill="none"
        stroke="#0B5687" // Border color
        strokeWidth="8" // Border thickness
      />
      <text
        x="50%"
        y="50%"
        dominantBaseline="middle"
        textAnchor="middle"
        fontSize="50"
        fontWeight="bold"
        fill="#0B5687"
        style={{ fontFamily: "Arial, sans-serif" }}
      >
        PAID
      </text>
    </svg>
  </div>
)}

          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  th: {
    backgroundColor: "#FFFFE0",
    WebkitPrintColorAdjust: "exact",
    padding: "3px 5px",
    border: "1px solid black",
    fontSize: "12px",
  },
  thserial: {
    backgroundColor: "#FFFFE0",
    WebkitPrintColorAdjust: "exact",
    padding: "3px 3px",
    border: "1px solid black",
    fontSize: "12px",
  },
  td: {
    padding: "3px 5px",
    border: "1px solid black",
    fontSize: "12px",
  },
  tdserial: {
    padding: "3px 3px",
    border: "1px solid black",
    fontSize: "12px",
  },
  tr: {
    padding: "3px 5px",
    border: "1px solid black",
  },
  paymentMethodDivBlue: {
    transform: "rotate(-45deg)",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: '#407BFF',
    color: "black",
    // WebkitPrintColorAdjust: 'exact',
    margin: "30px 30px 20px 180px",
    maxWidth: "120px",
    wordWrap: "break-word",
    overflow: "hidden",
    fontSize: "18px",

    // border: '2px solid #407BFF',
    borderRadius: "50%",
    // width: '120px',
    // height: '120px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paymentMethodDivRed: {
    transform: "rotate(-45deg)",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: '#407BFF',
    color: "black",
    // WebkitPrintColorAdjust: 'exact',
    margin: "30px 30px 20px 180px",
    maxWidth: "120px",
    wordWrap: "break-word",
    overflow: "hidden",
    fontSize: "13px",

    // border: "2px solid red",
    borderRadius: "50%",
    // width: '120px',
    // height: '120px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default PdfHtmlContentA3;
