import React, { useState } from 'react';
import ContentLayout from '../../layout/ContentLayout';
import { BiSave } from 'react-icons/bi';
import ImageUploaderWithPost from './ImageUploaderWithPost';
import axios from 'axios';


const CreateSlider = () => {
  const [formData,setFormData] = useState({
    image_path : []
  })
console.log(formData);
  const handleFileChange = (files, name) => {
    console.log('Selected files:', files);
  };

  const token = localStorage.getItem('token')




  // console.log(formData);

  const handleSubmit = () => {
    try{
      const response = axios.post('https://www.outletexpense.xyz/api/save-sliders', formData,
        {
          headers: {
            "Content-Type": 'application/json',
            Authorization: `Bearer ${token}`
          }
        },
      )
      console.log(response.data)
    } catch (error) {
      console.log(error.message);
    }
  }
  return (
    <div>
      <ContentLayout>
      <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] ">
          <div className="mb-5">
            <span className="text-gray-400 text-xs">Slider</span>
            <p className="font-semibold text-sm">Create New Slider</p>
          </div>
          {/*slider form  */}
          <div className="grid grid-cols-2 gap-6 justify-center items-center">

            <div className="col-span-1 h-80">
              <ImageUploaderWithPost
                name={"image_path1"}
                isBig={true}
                onChange={handleFileChange}
                formData={formData}
                setFormData={setFormData}
                apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}

              ></ImageUploaderWithPost>
            </div>
            <div className="col-span-1">
              <div className="flex flex-col space-y-6 h-80">
                <ImageUploaderWithPost
                  name={"image_path2"}
                  onChange={handleFileChange}
                  formData={formData}
                  setFormData={setFormData}
                  apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
                ></ImageUploaderWithPost>
                <ImageUploaderWithPost
                  name={"image_path3"}
                  onChange={handleFileChange}
                  apiEndPoint={`${process.env.REACT_APP_API_URL}file-upload`}
                  formData={formData}
                  setFormData={setFormData}
                ></ImageUploaderWithPost>
              </div>
            </div>
          </div>
          <button
                onClick={handleSubmit}
                type="submit"
                className="bg-[#407BFF] mx-auto mt-5 rounded-[5px] text-[#FFF] text-sm font-nunito font-bold py-2.5 px-[67px] flex items-center gap-2"
              >
                <BiSave className="text-xl"></BiSave> <span>Add Slider</span>
              </button>
        </div>
      </ContentLayout>
    </div>
  );
};

export default CreateSlider;