import React, { useContext } from "react";
import {
  formatPhoneNumber,
  numberToWords,
  formatPrice,
  invoiceCalculate,
} from "../../../utils/Common";
import Watermark from "../Watermark/Watermark";
import "./PdfHtmlContent.css";
import { AppContext } from "../../../context/context.js";

import QRCode from "react-qr-code";
import ImageUploader3 from "../../ImageUploader3";
import classNames from "classnames";

const PdfHtmlContent = (props) => {
  const { invoice, invoiceSetting, id, parentClassName, invoiceImeis, inputs } =
    props;
  const { state, isLoading, updateInvoiceList } = useContext(AppContext);
  const userInfo = invoice.user_info;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, "0");
  const day = String(invoiceDate.getDate()).padStart(2, "0");
  const hours = String(invoiceDate.getHours() % 12).padStart(2, "0");
  const minutes = String(invoiceDate.getMinutes()).padStart(2, "0");
  const ampm = invoiceDate.getHours() >= 12 ? "PM" : "AM";
  const shop_logo = invoiceSetting?.shop_logo
    ? invoiceSetting?.shop_logo
    : `${BASE_URL}/layoutlogo.svg`;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  const processedInvoiceIds = new Set();
  const processedProductIds = new Set(); // To track processed product_ids
  const imeisByProduct = [];

  console.log(invoice);

  // Aggregate unique IMEIs with all details
  invoiceImeis.forEach((imei) => {
    const imeiValue = imei.imei.toString().trim(); // Normalize the IMEI

    // Check if the IMEI already exists in imeisByProduct
    const imeiExists = imeisByProduct.some(
      (imeiDetail) => imeiDetail.imei === imeiValue
    );

    if (!imeiExists) {
      // If the IMEI doesn't exist, add it to imeisByProduct
      imeisByProduct.push({
        product_id: imei.product_id,
        product_name: imei.product_name,
        imei: imei.imei,
        sale_price: imei.sale_price,
        purchase_price: imei.purchase_price,
        base_currency_code: imei.base_currency_code,
        currency_rate: imei.currency_rate,
        purchase_currency_code: imei.purchase_currency_code,
      });
    }
  });

  let totalQty = 0;
  let totalItems = 0;
  let totalPrice = 0;
  let grandTotal = totalPrice + 0;

  let totalQtyPrint = 0;
  let totalItemsPrint = 0;
  let totalPricePrint = 0;

  let grandTotalValue = invoice?.sales_details?.product_info?.reduce(
    (sum, product) => {
      const imeisArray = invoiceImeis?.filter(
        (imei) => imei.product_id === product.product_id
      );
      if (imeisArray?.length) {
        const qty = imeisArray.length;
        sum + qty * imeisArray[0].sale_price;
      } else {
        sum + product.qty * product.price;
      }
      return sum;
    },
    0
  );

  // console.log("sale ");

  return (
    // new invoice
    <>
      <div className="pdfConentAreas">
        <div
          id="invoice-detail-page"
          className="invoice-detail-page font-nunito"
        >
          {/* invoice header */}
          {/* <div className="text-center text-xs md:text-sm lg:text-base">
          <div>

            <img src={shop_logo} alt={invoice.invoice_id} className={parentClassName + ' mx-auto w-1/2 md-[5px] max-w-[220px]'} />

            <div className=' leading-4 sm:leading-5 md:leading-6 mt-[5px] font-normal'>
              {userInfo?.outlet_name &&
                <p className='capitalize text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
              }
              {userInfo?.address &&
                <p>{userInfo?.address}</p>
              }
              {invoiceSetting?.mobile_number &&
                <p>{formatPhoneNumber(invoiceSetting?.mobile_number, invoice?.user_info?.country_code)}</p>
              }
            <div className='flex flex-col '>
           <span>{invoiceSetting?.web_address ?
              invoiceSetting?.web_address
            : (
              ""
            )}</span>
            <span>
            {invoiceSetting?.social_link ?
              invoiceSetting?.social_link
            : (
              ""
            )}
            </span>

            </div>

            </div>
          </div>
        </div> */}
          {/* invoice header */}
          <div className="flex justify-between items-center pt-4 pb-1 border-b-4 border-blue-300">
            {/* Shop Logo Section */}
            <div className="w-1/3 flex flex-col justify-start items-start">
              <img
                src={invoiceSetting.shop_logo}
                alt="Shop Logo"
                className="w-[40px] sm:w-[60px] md:w-[80px] object-contain"
              />
              <p className="mt-2">
                {" "}
                {/* Adds some spacing between image and text */}
                <span
                  className="font-semibold text-lg"
                  style={{ letterSpacing: "0.08em" }}
                >
                  Sale Invoice
                </span>
              </p>
            </div>

            {/* QR Code Section */}
            <div className="w-1/3 flex justify-center pb-2">
              <div className="w-[45px] sm:w-[65px] md:w-[100px]">
                <QRCode
                  className="w-full object-contain"
                  value={BASE_URL + "/invoice/" + invoice?.data?.invoice_id}
                  size={100}
                />
              </div>
            </div>

            {/* Shop Info Section */}
            <div className="w-1/3 flex flex-col justify-end items-start">
              <span
                className="font-semibold text-lg sm:text-xl md:text-2xl mb-2"
                style={{ letterSpacing: "0.08em" }}
              >
                {invoiceSetting.shop_name}
              </span>
              <span className="text-sm sm:text-md text-gray-600">
                <strong>Phone:</strong> {invoice.user_info.phone}
              </span>
              <span className="text-sm sm:text-md text-gray-600">
                <strong>Email:</strong> {invoice.user_info.email}
              </span>
              <span className="text-sm sm:text-md text-gray-600">
                <strong>Address:</strong> {userInfo.address}
              </span>
            </div>
          </div>
          {/* customer info and QR */}
          <div className="flex items-center mt-[10px] justify-between">
            <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs  lg:text-base">
              {/* <p> <span className='font-semibold'>Customer Name</span>  :  {invoice.customer_name} </p>
            <p><span className='font-semibold'>Customer Phone</span> :  {invoice.country_code}{invoice.customer_phone}</p>
            <p><span className='font-semibold'> Customer ID </span> :#{invoice.customer_id}</p>
            <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p> */}
              <p>
                {" "}
                <span className="font-semibold">Bill to,</span> <br />{" "}
                <span className="font-semibold">Customer Name </span> :{" "}
                {invoice.customer_name}{" "}
              </p>
              <p>
                <span className="font-semibold">Customer Phone</span> :{" "}
                {invoice.country_code}
                {invoice.customer_phone}
              </p>
              <p>
                <span className="font-semibold">Customer ID </span> :{" "}
                {invoice.customer_id}
              </p>
            </div>
            {/* <div className="w-[45px] sm:w-[65px] md:w-[100px]"> */}
            <div className=" flex flex-col justify-start items-start text-black space-y-[2px] md:space-y-[4px] font-nunito text-xs  lg:text-base">
              {/* <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} /> */}
              <p>
                {" "}
                <span className="font-semibold">Invoice No</span> :{" "}
                {invoice.invoice_id}{" "}
              </p>
              <p>
                <span className="font-semibold">Invoice Date</span> :{" "}
                {formattedDateTime}
              </p>
            </div>
          </div>
          {/* <div className='text-xs  lg:text-base' style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito py-1 text-14 font-bold uppercase">Invoice No: {invoice.invoice_id}</p>
        </div> */}
          {/* table */}
          <div className=" mx-auto md:w-full md:max-w-full overflow-x-auto mt-4 mb-0 ">
            <table className="table border-b-4 border-blue-300">
              <thead id="pdfContentTHead">
                <tr className="leading-normal text-xs  lg:text-base font-nunito tracking-wide border-b-4 border-t-4 border-blue-300">
                  <th
                    className="font-nunito text-left"
                    style={{ padding: "3px 5px" }}
                  >
                    SL
                  </th>
                  <th
                    className="font-nunito text-start "
                    style={{ padding: "3px 5px" }}
                  >
                    Item Name
                  </th>
                  <th
                    className="font-nunito text-center"
                    style={{ padding: "3px 5px" }}
                  >
                    Warranty
                  </th>
                  <th
                    className="font-nunito text-center"
                    style={{ padding: "3px 5px" }}
                  >
                    Status
                  </th>
                  <th
                    className="font-nunito text-center"
                    style={{ padding: "3px 5px" }}
                  >
                    Quantity
                  </th>
                  <th
                    className="font-nunito text-right"
                    style={{ padding: "3px 5px" }}
                  >
                    Price
                  </th>
                  <th
                    className="font-nunito text-right "
                    style={{ padding: "3px 5px" }}
                  >
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
                {(() => {
                  let rowCounter = 0; // Define rowCounter outside the map function

                  return invoice?.sales_details?.map((item) => {
                    const productId = item.product_id;

                    // // Skip if the product_id has already been processed
                    // if (processedProductIds.has(productId)) {
                    //   return null;
                    // }

                    // Retrieve unique IMEIs and their details for the current product_id
                    const imeisDetails = imeisByProduct.filter(
                      (imeiDetail) => imeiDetail.product_id === productId
                    );
                    let subtotalPrice = 0;

                    // if (imeisDetails.length > 0) {
                    //   const qty = imeisDetails.length; // Number of unique IMEIs found for the product
                    //   totalItems += qty;

                    //   // Calculate the total sale price for all unique IMEIs
                    //   const sumPrice = imeisDetails.reduce((acc, imei) => acc + imei.sale_price, 0);
                    //   subtotalPrice = sumPrice;
                    //   totalPrice += subtotalPrice;

                    //   // Mark this product_id as processed to avoid duplication
                    //   processedProductIds.add(productId);
                    // } else {
                    subtotalPrice = formatPrice(
                      (item?.remaining_qty !== null &&
                      item?.remaining_qty !== undefined
                        ? item?.remaining_qty
                        : item?.qty) * item.price
                    ); // Calculate subtotal price for the product
                    totalQty += Number(
                      item?.remaining_qty !== null &&
                        item?.remaining_qty !== undefined
                        ? item?.remaining_qty
                        : item?.qty
                    ); // Add the quantity of the product
                    totalItems +=
                      item?.remaining_qty !== null &&
                      item?.remaining_qty !== undefined
                        ? item?.remaining_qty
                        : item?.qty; // Count this product as a single item
                    totalPrice += Number(subtotalPrice); // Add to the total price
                    // }

                    // // Render rows for IMEIs or the regular item row
                    // return imeisDetails?.length
                    //   ? imeisDetails?.map((imei) => {
                    //       rowCounter += 1; // Increment for each IMEI row

                    //       return (
                    //         <tr key={imei.id} className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative">
                    //           <td className="text-left " style={{padding: '10px' }} >{rowCounter}</td> {/* SL # */}
                    //           <td className="" style={{ padding: '10px' }}>{imei?.product_name} {imei?.optional_name}<br /><span className="text-[13px]">{imei?.imei}</span></td>
                    //           <td className="text-center " style={{ padding: '10px' }}>
                    //             {/* Warranty Logic */}
                    //             {invoice.defaultwarranties &&
                    //               invoice.defaultwarranties.map((warrantyItem) => (
                    //                 warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id === item.product_id && (
                    //                   warrantyItem.warranty_id == null ? (
                    //                     <h1 className="text-sm">{warrantyItem?.default_warranties_count || ""} {item?.product_info?.warrenty || ""}</h1>
                    //                   ) : (
                    //                     <h1 className="text-sm">{warrantyItem?.warranty?.warranties_count || ""} {warrantyItem?.warranty?.name || ""}</h1>
                    //                   )
                    //                 )
                    //               ))}
                    //           </td>
                    //           <td className="text-center text-sm" style={{  padding: '10px' }}>{(item?.remaining_qty === 0 || item?.remaining_qty < item?.qty) ? "     "+ "Returned " + (item?.qty - item?.remaining_qty) : "Sold"}</td>
                    //           <td className="text-center text-sm" style={{  padding: '10px' }}>{item?.remaining_qty !== null && item?.remaining_qty !== undefined ? item?.remaining_qty : item?.qty}</td>
                    //           <td className="text-right text-sm" style={{ padding: '10px' }}>{imei.sale_price}</td>
                    //           <td className="text-right font-semibold md:font-semibold text-sm" style={{padding: '10px' }}>
                    //             {imei.sale_price}{invoiceSetting?.currency_info?.code || ' BDT'}
                    //           </td>
                    //         </tr>
                    //       );
                    //     })
                    //   :
                    return (
                      <tr
                        key={item.id}
                        className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative"
                      >
                        <td className="text-left " style={{ padding: "10px" }}>
                          {++rowCounter}
                        </td>{" "}
                        {/* SL # */}
                        <td className="" style={{ padding: "10px" }}>
                          {item?.product_info?.name} <br />
                          <span className="text-[13px]">
                            {item?.product_imei[0]?.imei
                              ? item?.product_imei[0]?.imei
                              : item?.product_info?.serial}
                          </span>
                        </td>
                        <td className="text-right " style={{ padding: "10px" }}>
                          {/* Warranty Logic */}
                          {invoice.defaultwarranties &&
                            invoice.defaultwarranties.map(
                              (warrantyItem) =>
                                warrantyItem.invoice_id ===
                                  invoice.invoice_id &&
                                warrantyItem.product_id === item.product_id &&
                                (warrantyItem.warranty_id == null ? (
                                  <h1 className="text-sm">
                                    {warrantyItem?.default_warranties_count ||
                                      ""}{" "}
                                    {item?.product_info?.warrenty || ""}
                                  </h1>
                                ) : (
                                  <h1 className="text-sm">
                                    {warrantyItem?.warranty?.warranties_count ||
                                      ""}{" "}
                                    {warrantyItem?.warranty?.name || ""}
                                  </h1>
                                ))
                            )}
                        </td>
                        {console.log("called ", item?.remaining_qty)}
                        <td
                          className="text-center text-sm"
                          style={{ padding: "10px" }}
                        >
                          {item?.remaining_qty === 0 ||
                          item?.remaining_qty < item?.qty
                            ? "     " +
                              "Returned " +
                              (item?.qty - item?.remaining_qty)
                            : "Sold"}
                        </td>
                        <td
                          className="text-center text-sm"
                          style={{ padding: "10px" }}
                        >
                          {item?.remaining_qty !== null &&
                          item?.remaining_qty !== undefined
                            ? item?.remaining_qty
                            : item?.qty}
                        </td>
                        <td
                          className="text-right text-sm"
                          style={{ padding: "10px" }}
                        >
                          {item.price}
                        </td>
                        <td
                          className="text-right font-semibold md:font-semibold text-sm"
                          style={{ padding: "10px" }}
                        >
                          {subtotalPrice}
                          {invoiceSetting?.currency_info?.code || " BDT"}
                        </td>
                      </tr>
                    );
                  });
                })()}
              </tbody>
            </table>
          </div>
          {/* <DashedLine2 /> */}
          {/* <br /> */}
          {/* disclaimer & summary */}
          <div className="w-full flex justify-between  pt-0 ml-auto ">
            {/* payment method */}
            {
              <div style={styles.paymentMethodDiv}>
                {/* <div>
              {invoice?.multiple_payment?.length > 0 ? (
                <span>
                  Paid by {invoice?.multiple_payment?.map(payment => payment.payment_type.type_name).join(', ').replace(/, ([^,]*)$/, ' and $1')}
                </span>
              ) : (
                <span>No payment information available</span>
              )}
            </div> */}

                <div>
                  {invoice?.multiple_payment?.length > 0 ? (
                    <>
                      {(() => {
                        const grandTotal = parseFloat(
                          (
                            Number(totalPrice) +
                            Number(invoice?.vat || 0) +
                            Number(invoice?.tax || 0) +
                            Number(invoice?.delivery_fee || 0)
                          ).toFixed(2)
                        );
                        const discount = Number(invoice?.discount || 0);
                        const paidAmount = Number(
                          invoice.paid_amount && invoice.paid_amount !== 0
                            ? invoice.paid_amount
                            : 0
                        );
                        const dueAmount = grandTotal - paidAmount;

                        // Debugging values
                        console.log("grandTotal:", grandTotal);
                        console.log("discount:", discount);
                        console.log("paidAmount:", paidAmount);
                        console.log("dueAmount:", dueAmount);

                        const remainingAmount = dueAmount - paidAmount;
                        console.log("remainingAmount:", remainingAmount);

                        const isFullyPaid = dueAmount === 0;
                        const isFullyDue = paidAmount === 0;
                        const isPartiallyPaid = paidAmount > 0;
                        console.log("isFullyPaid:", isFullyPaid);
                        console.log("isFullyDue:", isFullyDue);
                        console.log("isPartiallyPaid:", isPartiallyPaid);

                        return (
                          <>
                            {isFullyDue ? (
                              <span>The amount is fully due</span>
                            ) : (
                              <>
                                {isFullyPaid ? (
                                  <span>
                                    Paid by{" "}
                                    {invoice?.multiple_payment
                                      ?.map(
                                        (payment) =>
                                          payment.payment_type.type_name
                                      )
                                      .join(", ")
                                      .replace(/, ([^,]*)$/, " and $1")}{" "}
                                  </span>
                                ) : (
                                  <span>
                                    Paid by{" "}
                                    {invoice?.multiple_payment
                                      ?.map(
                                        (payment) =>
                                          payment.payment_type.type_name
                                      )
                                      .join(", ")
                                      .replace(/, ([^,]*)$/, " and $1")}{" "}
                                    {isPartiallyPaid ? "Partially" : ""}
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        );
                      })()}
                    </>
                  ) : (
                    <span>No payment information available</span>
                  )}
                </div>
              </div>
            }

            {/* invoice sumary */}
            <div className="w-1/3 text-sm font-semibold  pl-12">
              <div className="space-y-[2px] mb-2">
                <p className="flex justify-between">
                  <span className="pl-5">Sub Total</span>
                  <span>
                    {formatPrice(totalPrice)}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="pl-5">Discount</span>
                  <span>
                    {formatPrice(invoice.discount ? invoice.discount : 0)}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
              </div>
              <hr></hr>
              <div className="my-2">
                <p className="flex justify-between">
                  <span className="pl-5">Grand Total</span>
                  <span>
                    {Number.parseFloat(
                      Number(totalPrice).toFixed(2) +
                        Number(invoice?.vat).toFixed(2) +
                        Number(invoice?.tax).toFixed(2) +
                        Number(invoice?.delivery_fee).toFixed(2)
                    ).toFixed(2) - Number(invoice?.discount).toFixed(2)}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
                <p className="flex justify-between">
                  <span className="pl-5">Paid Amount</span>
                  <span>
                    {invoice.paid_amount && invoice.paid_amount != ""
                      ? formatPrice(invoice.paid_amount)
                      : formatPrice(0)}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
              </div>
              <hr></hr>
              <p className="flex justify-between mt-2">
                <span className="pl-5">Due Amount</span>
                <span>
                  {Number.parseFloat(
                    Number(totalPrice) +
                      Number(invoice?.vat) +
                      Number(invoice?.tax) +
                      Number(invoice?.delivery_fee) -
                      Number(invoice?.discount) -
                      Number(
                        invoice.paid_amount && invoice.paid_amount != ""
                          ? formatPrice(invoice.paid_amount)
                          : formatPrice(0)
                      )
                  ).toFixed(2) < 0
                    ? 0
                    : Number.parseFloat(
                        Number(totalPrice) +
                          Number(invoice?.vat) +
                          Number(invoice?.tax) +
                          Number(invoice?.delivery_fee) -
                          Number(invoice?.discount) -
                          Number(
                            invoice.paid_amount && invoice.paid_amount != ""
                              ? formatPrice(invoice.paid_amount)
                              : formatPrice(0)
                          )
                      ).toFixed(2)}
                  {invoiceSetting?.currency_info?.code
                    ? invoiceSetting?.currency_info?.code
                    : " BDT"}
                </span>
              </p>
            </div>
          </div>
          <div style={{ ...styles.div }}>
            <p className="text-white font-nunito text-sm font-bold">
              You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
              {/* You saved {invoice.discount ? invoice.discount : 0} */}
              {invoiceSetting?.currency_info?.code
                ? invoiceSetting?.currency_info?.code
                : " BDT"}{" "}
              in this purchase{" "}
            </p>
          </div>
          {/* web address qr code */}
          <QRCode
            className="w-[70px] object-contain  p-0"
            value={
              invoice?.data?.user_info?.invoice_settings?.web_address || ""
            }
            size={70}
          />
          {/* signature */}
          {/* <div className='text-xs md:text-sm lg:text-base border\'>
            <div className='flex justify-end'>
              <p className="invisible">.</p>
              {!id && invoiceSetting?.sign_authority ? <div className='w-[120px] h-[120px] rounded-lg'>
                <img src={invoiceSetting.sign_authority} className='object-cover excludeFromPdf' />
              </div> : null}
            </div>
            <div className="flex justify-end m-2">
              <p><hr></hr> Authorized Signature </p>
            </div>
        </div> */}
          {/* terms and conditions */}
          {/* <div class=" border">
          <h3 class="text-sm font-semibold text-gray-800 mb-2">Terms and Conditions</h3>
          <ul class="list-disc list-inside text-gray-700 text-xs">
              <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
              <li>Nulla facilisi. Suspendisse potenti.</li>
              <li>Mauris ut metus non nisl pulvinar cursus.</li>
          </ul>
        </div> */}
          {/* Terms and Conditions Section */} {/* Signature Section */}
          <div className="flex space-x-4 text-xs md:text-sm lg:text-base">
            <div className=" pt-2 w-1/2">
              <h3 className="text-sm font-semibold text-gray-800 mb-2">
                Terms and Conditions
              </h3>
              <ul className="list-disc list-inside text-gray-700 text-xs">
                {/* <li>All prices include taxes. Payment must be made in full at the time of purchase via accepted payment methods.</li>
                  <li>Disputes will be resolved under the laws of Bangladesh.</li>
                  <li>Defective or incorrect items will be replaced or refunded at no additional cost.</li> */}
                {inputs.map((item, index) => (
                  <li key={item.id || index}>{item.description}</li>
                ))}
              </ul>
            </div>
            <div className=" p-4 flex flex-col items-end w-1/2">
              <div className="flex justify-end">
                <p className="invisible">.</p>
                {!id && invoiceSetting?.sign_authority ? (
                  <div className="w-[120px] h-[120px] rounded-lg">
                    <img
                      src={invoiceSetting.sign_authority}
                      className="object-cover excludeFromPdf"
                    />
                  </div>
                ) : null}
              </div>
              <div className="flex justify-end mt-2">
                <p>
                  <hr /> Authorized Signature{" "}
                </p>
              </div>
            </div>
          </div>
          {/* footer */}
          <div className="text-center font-nunito mt-4 text-xs md:text-sm lg:text-base">
            <h2 className="text-orange-500  text-xl font-semibold ">
              Thanks for purchase
            </h2>
            <p className="text-black mt-3 text-xs mb-2">
              A Product of SQUAD INNOVATORS{" "}
            </p>
          </div>
        </div>
      </div>

      {/* this is the pownload file */}
      <div className="hidden">
        <div id="pdfConentAreas" className="pdfConentAreas">
          <div
            id="invoice-detail-page"
            className="invoice-detail-page font-nunito"
          >
            {/* <div className="text-center text-xs md:text-sm lg:text-base">
              <img
                src={shop_logo}
                alt={invoice.invoice_id}
                className="mx-auto w-1/2 md-[0px] max-w-[220px]"
              />
              <div className=" leading-4 sm:leading-5 md:leading-6 mt-[5px] font-semibold space-y-1">
                {userInfo?.outlet_name ? (
                  <p className='text-[22px] font-semibold'>{userInfo?.outlet_name} </p>
                ) : (
                  ""
                )}
                {userInfo?.address ? (
                  <p>{userInfo?.address}</p>
                ) : (
                  ""
                )}
                {invoice?.user_info?.phone ? (
                  <p>{formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}</p>
                ) : (
                  ""
                )}
                <div className='flex flex-col '>
                  <span>{invoiceSetting?.web_address ?
                      invoiceSetting?.web_address
                    : (
                      ""
                    )}</span>
                    <span>
                    {invoiceSetting?.social_link ?
                      invoiceSetting?.social_link
                    : (
                      ""
                    )}
                  </span>

                </div>
              </div>
          </div> */}
            {/* invoice header */}
            <div className="flex justify-between items-center pt-4 border-b-4 border-blue-300">
              {/* Shop Logo Section */}
              <div className="w-1/3 flex flex-col justify-start items-start">
                <img
                  src={invoiceSetting.shop_logo}
                  alt="Shop Logo"
                  className="w-[40px] sm:w-[60px] md:w-[80px] object-contain"
                />
                <p className="mt-2">
                  {" "}
                  {/* Adds some spacing between image and text */}
                  <span
                    className="font-semibold text-sm"
                    style={{ letterSpacing: "0.08em" }}
                  >
                    Sale Invoice
                  </span>
                </p>
              </div>

              {/* QR Code Section */}
              <div className="w-1/3 flex justify-center">
                <div className="w-[45px] sm:w-[65px] md:w-[100px]">
                  <QRCode
                    className="w-full object-contain"
                    value={BASE_URL + "/invoice/" + invoice?.data?.invoice_id}
                    size={100}
                  />
                </div>
              </div>

              {/* Shop Info Section */}
              <div className="w-1/3 flex flex-col justify-end items-start">
                <span
                  className="font-semibold text-md sm:text-xl md:text-2xl"
                  style={{ letterSpacing: "0.08em" }}
                >
                  {invoiceSetting.shop_name}
                </span>
                <span className="text-xs text-gray-600">
                  <strong>Phone:</strong> {invoice.user_info.phone}
                </span>
                <span className="text-xs text-gray-600">
                  <strong>Email:</strong> {invoice.user_info.email}
                </span>
                <span className="text-xs text-gray-600">
                  <strong>Address:</strong> {userInfo.address}
                </span>
              </div>
            </div>
            {/* customer info and  + QR*/}
            <div className="flex items-center mt-[10px] justify-between">
              <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs  lg:text-base">
                {/* <p> <span className='font-semibold'>Customer Name</span>  :  {invoice.customer_name} </p>
              <p><span className='font-semibold'>Customer Phone</span> :  {invoice.country_code}{invoice.customer_phone}</p>
              <p><span className='font-semibold'> Customer ID </span> :#{invoice.customer_id}</p>
              <p><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p> */}
                <p>
                  {" "}
                  <span className="font-semibold">Bill to,</span> <br />{" "}
                  <span className="font-semibold">Customer Name </span> :{" "}
                  {invoice.customer_name}{" "}
                </p>
                <p>
                  <span className="font-semibold">Customer Phone</span> :{" "}
                  {invoice.country_code}
                  {invoice.customer_phone}
                </p>
                <p>
                  <span className="font-semibold"> Customer ID </span> :{" "}
                  {invoice.customer_id}
                </p>
              </div>
              {/* <div className="w-[45px] sm:w-[65px] md:w-[100px]"> */}
              <div className="flex flex-col justify-start items-start text-black space-y-[2px] md:space-y-[4px] font-nunito text-xs  lg:text-base">
                {/* <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} /> */}
                <p>
                  {" "}
                  <span className="font-semibold">Invoice No</span> :{" "}
                  {invoice.invoice_id}{" "}
                </p>
                <p>
                  <span className="font-semibold">Invoice Date</span> :{" "}
                  {formattedDateTime}
                </p>
              </div>
            </div>
            {/* invoice serial */}
            {/* <div
            className="text-xs  lg:text-base"
            style={{
              width: "100%",
              backgroundColor: "#3056FA",
              padding: "0 10px 10px 0px",
              textAlign: "center",
              margin: "5px auto",
            }}
          >
            <p className="text-white font-nunito py-1 text-14 font-bold uppercase">
              Invoice No: {invoice.invoice_id}
            </p>
          </div> */}
            {/* <div className="max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto md:w-full md:max-w-full overflow-x-auto"> */}
            {/* <div className="overflow-x-auto ">
            <table className="table">
              <thead id="pdfContentTHead">
                <tr className="leading-normal text-sm  lg:text-base font-nunito tracking-wide">
                  <th className="pb-3 font-nunito">Item Name</th>
                  <th className="pb-3 font-nunito text-center ">Warranty</th>
                  <th className="pb-3 font-nunito text-center">Quantity</th>
                  <th className="pb-3 font-nunito text-center">Price</th>
                  <th className="pb-3 font-nunito text-right">Total</th>
                </tr>
              </thead>
              <tbody textAlign="start">
                {invoice?.sales_details?.map((item) => {
                  const findImeis = invoiceImeis.filter( (imei) => imei?.product_id === item?.product_id )
                  let subtotalPrice = 0;
                  if(findImeis?.length){
                    const qty = Number.parseInt(findImeis?.length)
                    totalItemsPrint += qty;
                    const sumPrice = findImeis?.reduce( (acc, prod)=>{
                      return acc + prod.sale_price
                    },0)
                    subtotalPrice = sumPrice;
                    totalPricePrint += Number(subtotalPrice);
                  }
                  else{
                    totalQty += Number(item.qty);
                    totalItemsPrint += 1;
                    subtotalPrice = formatPrice(item.qty * item.price);
                    totalPricePrint += Number(subtotalPrice);
                  }
                  return (
                    findImeis?.length ?
                    (
                      findImeis?.map((imei)=>(
                        <tr
                        key={item.id}
                        className=" font-nunito border-[#E7EBF4] font-normal text-sm  lg:text-base relative"
                      >
                        <td className="pt-0">{imei?.product_name} {imei?.optional_name}<br /><span className='text-[13px]'>{imei?.imei}</span></td>
                        <td className="pt-0 text-center">
                          {
                            invoice.defaultwarranties &&
                            invoice.defaultwarranties.map((warrantyItem) => (
                              warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                              (
                                warrantyItem.warranty_id==null ? (
                                  <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                  {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                  </h1>
                                ) : (
                                  <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                  {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                                )
                              )
                            ))
                          }
                        </td>
                        <td className="pt-0 text-center">{"1"}</td>
                        <td className="pt-0 text-center">{imei?.sale_price}</td>
                        <td className="pt-0 text-right font-semibold md:font-semibold">
                          {imei?.sale_price}
                          {invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info
                                ?.code
                            : " BDT"}
                        </td>
                      </tr>) ))
                      :
                      (<tr
                        key={item.id}
                        className=" font-nunito border-[#E7EBF4] font-normal text-sm  lg:text-base relative"
                      >
                        <td className="pt-0">{item?.product_info?.name}<br /><span className='text-[13px]'>{item?.product_info?.serial}</span></td>
                        <td className="pt-0 text-center">
                          {
                            invoice.defaultwarranties &&
                            invoice.defaultwarranties.map((warrantyItem) => (
                              warrantyItem.invoice_id === invoice.invoice_id && warrantyItem.product_id===item.product_id &&
                              (
                                warrantyItem.warranty_id==null ? (
                                  <h1 className='text-sm'>{warrantyItem?.default_warranties_count==null?"":warrantyItem?.default_warranties_count} {" "}
                                  {item?.product_info?.warrenty==null?"":item?.product_info?.warrenty}
                                  </h1>
                                ) : (
                                  <h1 className='text-sm'>{warrantyItem?.warranty?.warranties_count==null?"":warrantyItem?.warranty?.warranties_count}
                                  {" "}{warrantyItem?.warranty?.name==null?"":warrantyItem?.warranty?.name}</h1>
                                )
                              )
                            ))
                          }
                        </td>
                        <td className="pt-0 text-center">{item.qty}</td>
                        <td className="pt-0 text-center">{item.price}</td>
                        <td className="pt-0 text-right font-semibold md:font-semibold">
                          {subtotalPrice}
                          {invoiceSetting?.currency_info?.code
                            ? invoiceSetting?.currency_info
                                ?.code
                            : " BDT"}
                        </td>
                      </tr>)
                  );
                })}
              </tbody>
            </table>
          </div> */}
            <div className="mx-auto md:w-full md:max-w-full overflow-x-auto mb-0 mt-3">
              <table className="table ">
                <thead id="pdfContentTHead" className="pb-3">
                  <tr className="leading-normal text-sm lg:text-base font-nunito tracking-wide border-b-4 border-t-4 border-blue-300 ">
                    <th
                      className="font-nunito text-left"
                      style={{ padding: "3px 5px" }}
                    >
                      #
                    </th>
                    <th
                      className="font-nunito text-start"
                      style={{ padding: "3px 5px" }}
                    >
                      Item Name
                    </th>
                    <th
                      className="font-nunito text-center"
                      style={{ padding: "3px 5px" }}
                    >
                      Warranty
                    </th>
                    <th
                      className="font-nunito text-center"
                      style={{ padding: "3px 5px" }}
                    >
                      Quantity
                    </th>
                    <th
                      className="font-nunito text-right"
                      style={{ padding: "3px 5px" }}
                    >
                      Price
                    </th>
                    <th
                      className="font-nunito text-right"
                      style={{ padding: "3px 5px" }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="mt-2">
                  {(() => {
                    let rowCounter = 0; // Counter for serial numbers
                    const processedProductIds = new Set(); // Track processed product IDs to avoid duplicates

                    return invoice?.sales_details?.map((item) => {
                      const findImeis = invoiceImeis.filter(
                        (imei) => imei.product_id === item.product_id
                      );

                      // If product_id has been processed, skip rendering it again
                      if (processedProductIds.has(item.product_id)) {
                        return null;
                      }

                      processedProductIds.add(item.product_id); // Mark product_id as processed

                      let subtotalPrice = 0;
                      if (findImeis.length > 0) {
                        // Calculate total price for IMEIs
                        const qty = findImeis.length;
                        totalItemsPrint += qty;
                        subtotalPrice = findImeis.reduce(
                          (acc, prod) => acc + prod.sale_price,
                          0
                        );
                        totalPricePrint += subtotalPrice;
                      } else {
                        // For items without IMEIs
                        subtotalPrice = formatPrice(item.qty * item.price);
                        totalQty += Number(item.qty);
                        totalItemsPrint += 1;
                        totalPricePrint += Number(subtotalPrice);
                      }

                      // Render IMEI rows for products with IMEIs, otherwise render the standard product row
                      return findImeis.length > 0 ? (
                        findImeis.map((imei, index) => (
                          <tr
                            key={imei.id}
                            className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative pt-3 pb-3"
                          >
                            <td
                              className="text-left"
                              style={{ padding: "10px" }}
                            >
                              {++rowCounter}
                            </td>
                            <td className="" style={{ padding: "10px" }}>
                              {imei.product_name} {imei.optional_name}
                              <br />
                              <span className="text-[10px]">{imei.imei}</span>
                            </td>
                            <td
                              className="text-center "
                              style={{ padding: "10px" }}
                            >
                              {invoice.defaultwarranties &&
                                invoice.defaultwarranties.map(
                                  (warrantyItem) =>
                                    warrantyItem.invoice_id ===
                                      invoice.invoice_id &&
                                    warrantyItem.product_id ===
                                      item.product_id &&
                                    (warrantyItem.warranty_id == null ? (
                                      <h1 className="">
                                        {warrantyItem.default_warranties_count ||
                                          ""}{" "}
                                        {item.product_info.warrenty || ""}
                                      </h1>
                                    ) : (
                                      <h1 className="">
                                        {warrantyItem.warranty
                                          ?.warranties_count || ""}{" "}
                                        {warrantyItem.warranty?.name || ""}
                                      </h1>
                                    ))
                                )}
                            </td>
                            <td
                              className="text-center "
                              style={{ padding: "10px" }}
                            >
                              1
                            </td>
                            <td
                              className="text-right"
                              style={{ padding: "10px" }}
                            >
                              {imei.sale_price}
                            </td>
                            <td
                              className="text-right font-semibold md:font-semibold "
                              style={{
                                padding: "6px 5px",
                                paddingTop: "5px",
                                paddingBottom: "5px",
                              }}
                            >
                              {imei.sale_price}
                              {invoiceSetting?.currency_info?.code || " BDT"}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr
                          key={item.id}
                          className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative"
                        >
                          <td className="text-left" style={{ padding: "10px" }}>
                            {++rowCounter}
                          </td>
                          <td className="" style={{ padding: "10px" }}>
                            {item.product_info.name}
                            <br />
                            <span className="text-[10px]">
                              {item.product_info.serial}
                            </span>
                          </td>
                          <td
                            className="text-center "
                            style={{ padding: "10px" }}
                          >
                            {invoice.defaultwarranties &&
                              invoice.defaultwarranties.map(
                                (warrantyItem) =>
                                  warrantyItem.invoice_id ===
                                    invoice.invoice_id &&
                                  warrantyItem.product_id === item.product_id &&
                                  (warrantyItem.warranty_id == null ? (
                                    <h1 className="">
                                      {warrantyItem.default_warranties_count ||
                                        ""}{" "}
                                      {item.product_info.warrenty || ""}
                                    </h1>
                                  ) : (
                                    <h1 className="">
                                      {warrantyItem.warranty
                                        ?.warranties_count || ""}{" "}
                                      {warrantyItem.warranty?.name || ""}
                                    </h1>
                                  ))
                              )}
                          </td>
                          <td
                            className="text-center "
                            style={{ padding: "10px" }}
                          >
                            {item.qty}
                          </td>
                          <td
                            className="text-right "
                            style={{ padding: "10px" }}
                          >
                            {item.price}
                          </td>
                          <td
                            className="text-right font-semibold md:font-semibold "
                            style={{ padding: "10px" }}
                          >
                            {subtotalPrice}
                            {invoiceSetting?.currency_info?.code || " BDT"}
                          </td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </table>
              <div className="border-b-4 border-blue-300 mt-3 mb-3"></div>
            </div>
            <div className="w-full flex justify-between pt-0 ml-auto ">
              {/* disclaimer */}
              {/* payment method */}
              <div style={styles.paymentMethodPrintDiv}>
                {/* <div>
                  {invoice?.multiple_payment?.length > 0 ? (
                    <span className="" style={{ letterSpacing: "0.08em" }}>
                      Paid by{" "}
                      {invoice.multiple_payment
                        .map((payment) => payment.payment_type.type_name)
                        .join(", ")
                        .replace(/, ([^,]*)$/, " and $1")}
                    </span>
                  ) : (
                    <span>No payment information available</span>
                  )}
                </div> */}

                  {/* Here added the code by Saif */}
                <div>
                  {invoice?.multiple_payment?.length > 0 ? (
                    <>
                      {(() => {
                        const grandTotal = parseFloat(
                          (
                            Number(totalPrice) +
                            Number(invoice?.vat || 0) +
                            Number(invoice?.tax || 0) +
                            Number(invoice?.delivery_fee || 0)
                          ).toFixed(2)
                        );
                        const discount = Number(invoice?.discount || 0);
                        const paidAmount = Number(
                          invoice.paid_amount && invoice.paid_amount !== 0
                            ? invoice.paid_amount
                            : 0
                        );
                        const dueAmount = grandTotal - paidAmount;

                        // Debugging values
                        console.log("grandTotal:", grandTotal);
                        console.log("discount:", discount);
                        console.log("paidAmount:", paidAmount);
                        console.log("dueAmount:", dueAmount);

                        const remainingAmount = dueAmount - paidAmount;
                        console.log("remainingAmount:", remainingAmount);

                        const isFullyPaid = dueAmount === 0;
                        const isFullyDue = paidAmount === 0;
                        const isPartiallyPaid = paidAmount > 0;
                        console.log("isFullyPaid:", isFullyPaid);
                        console.log("isFullyDue:", isFullyDue);
                        console.log("isPartiallyPaid:", isPartiallyPaid);

                        return (
                          <>
                            {isFullyDue ? (
                              <span style={{ letterSpacing: "0.08em" }}>
                                The amount is fully due
                              </span>
                            ) : (
                              <>
                                {isFullyPaid ? (
                                  <span style={{ letterSpacing: "0.08em" }}>
                                    Paid by{" "}
                                    {invoice?.multiple_payment
                                      ?.map(
                                        (payment) =>
                                          payment.payment_type.type_name
                                      )
                                      .join(", ")
                                      .replace(/, ([^,]*)$/, " and $1")}{" "}
                                  </span>
                                ) : (
                                  <span style={{ letterSpacing: "0.08em" }}>
                                    Paid by{" "}
                                    {invoice?.multiple_payment
                                      ?.map(
                                        (payment) =>
                                          payment.payment_type.type_name
                                      )
                                      .join(", ")
                                      .replace(/, ([^,]*)$/, " and $1")}{" "}
                                    {isPartiallyPaid ? "Partially" : ""}
                                  </span>
                                )}
                              </>
                            )}
                          </>
                        );
                      })()}
                    </>
                  ) : (
                    <span>No payment information available</span>
                  )}
                </div>
              </div>
              {/* invoice sumary */}
              <div className="w-1/3 text-xs font-semibold pl-12">
                <div className="space-y-[2px] mb-2">
                  {/* <p className="flex justify-between">
                  <span>Total Items</span>
                  <span>{totalItemsPrint}</span>
                </p> */}
                  <p className="flex justify-between">
                    <span>Sub Total</span>
                    <span>
                      {formatPrice(totalPricePrint)}
                      {/* {totalPrice} */}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Discount</span>
                    <span>
                      {formatPrice(invoice.discount ? invoice.discount : 0)}
                      {/* {invoice.discount ? invoice.discount : 0} */}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  {/* <p className="flex justify-between">
                  <span>Vat</span>
                  <span>
                    {invoice.vat ? formatPrice(invoice.vat): 0.0000}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p> */}
                  {/* <p className="flex justify-between">
                  <span>Service Charge </span>
                  <span>
                    {invoice.tax ? formatPrice(invoice.tax) : 0.0}

                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p> */}
                </div>
                {/* <hr></hr> */}
                <div
                  style={{ borderTop: "0.5px solid #D3D3D3", margin: "10px 0" }}
                ></div>

                <div className="my-2">
                  <p className="flex justify-between">
                    <span>Grand Total</span>
                    <span>
                      {/* {formatPrice(invoiceCalculate(invoice).grandTotal)} */}
                      {
                        // Ensure values are valid numbers and calculate before toFixed
                        Number.parseFloat(
                          (
                            (Number(totalPricePrint) || 0) +
                            (Number(invoice?.vat) || 0) +
                            (Number(invoice?.tax) || 0) +
                            (Number(invoice?.delivery_fee) || 0) -
                            (Number(invoice?.discount) || 0)
                          ).toFixed(2)
                        ).toFixed(2)
                      }
                      {/* {invoice.sub_total} */}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                  <p className="flex justify-between">
                    <span>Paid Amount</span>
                    <span>
                      {/* {formatPrice(invoice.paid_amount)} */}
                      {formatPrice(invoice.paid_amount)}
                      {invoiceSetting?.currency_info?.code
                        ? invoiceSetting?.currency_info?.code
                        : " BDT"}
                    </span>
                  </p>
                </div>
                {/* <hr></hr> */}
                <div
                  style={{ borderTop: "0.5px solid #D3D3D3", margin: "10px 0" }}
                ></div>
                <p className="flex justify-between mt-2">
                  <span>Due Amount</span>
                  <span>
                    {/* {Number.parseFloat(invoiceCalculate(invoice).dueAmount).toFixed(2)} */}
                    {Number.parseFloat(
                      Number(totalPricePrint) +
                        Number(invoice?.vat) +
                        Number(invoice?.tax) +
                        Number(invoice?.delivery_fee) -
                        Number(invoice?.discount) -
                        Number(
                          invoice.paid_amount && invoice.paid_amount != ""
                            ? formatPrice(invoice.paid_amount)
                            : formatPrice(0)
                        )
                    ).toFixed(2)}
                    {/* {invoice.sub_total - invoice.paid_amount} */}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p>
              </div>
            </div>
            {/* amount in words */}
            <p className="text-gray-900 mt-4 font-nunito text-xs  lg:text-base font-semibold leading-normal text-center">
              <span style={{ color: "#A0AEC0" }}>In Word:</span>{" "}
              {numberToWords(formatPrice(invoiceCalculate(invoice).grandTotal))}{" "}
              taka only
            </p>
            <div style={{ ...styles.div }}>
              <p className="text-white font-nunito text-xs font-bold">
                You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
                {/* You saved {invoice.discount ? invoice.discount : 0} */}
                {invoiceSetting?.currency_info?.code
                  ? invoiceSetting?.currency_info?.code
                  : " BDT"}{" "}
                in this purchase{" "}
              </p>
            </div>
            {/* web address qr code */}
            <QRCode
              className="w-[50px] object-contain p-0 mt-5"
              value={
                invoice?.data?.user_info?.invoice_settings?.web_address || ""
              }
              size={50}
            />
            {/* signature */}
            {/* <div className="text-xs md:text-sm lg:text-base">
            <div className="flex justify-between">
              {invoiceSetting?.sign_authority && (
              <div className="w-[100px] h-[30px] rounded-lg">
              </div>
            )}
              <div className="w-[100px] h-[30px] rounded-lg">
              </div>
            </div>
            <div className="flex justify-between m-2">
              <p>
                <hr></hr> Customer Signature
              </p>
              <p>
                <hr></hr> Seller Signature{" "}
              </p>
            </div>
          </div> */}
            {/* <div className="text-xs md:text-sm lg:text-base">
            <div className="flex justify-end">
              {invoiceSetting?.sign_authority && (
                <div className="w-[100px] h-[30px] rounded-lg"></div>
              )}
              <div className="w-[100px] h-[30px] rounded-lg"></div>
            </div>
            <div className="flex justify-end items-center m-2">
              <div className="flex flex-col items-center border-t border-gray-300">
                <hr className="w-full" />
                <span>Authorized Signature</span>
              </div>
            </div>
          </div> */}
            {/* terms and conditions */}
            {/* <div class="w-1/2 p-4">
            <h3 class="text-xs font-semibold text-gray-800 mb-2">Terms and Conditions</h3>
            <ul class="list-disc list-inside text-gray-700 text-xs">
                <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                <li>Nulla facilisi. Suspendisse potenti.</li>
                <li>Mauris ut metus non nisl pulvinar cursus.</li>
            </ul>
          </div> */}
            {/* Terms and Conditions Section */} {/* Signature Section */}
            <div className="flex space-x-5 text-[10px] lg:text-sm mt-2">
              <div className=" pt-2 w-1/2 ">
                <h3 className="text-[10px] font-semibold text-gray-800 mb-2">
                  Terms and Conditions
                </h3>
                <ul className="list-disc list-inside text-gray-700 text-[10px]">
                  {/* <li>All prices include taxes. Payment must be made in full at the time of purchase via accepted payment methods.</li>
                  <li>Disputes will be resolved under the laws of Bangladesh.</li>
                  <li>Defective or incorrect items will be replaced or refunded at no additional cost.</li> */}
                  {inputs.map((item, index) => (
                    <li key={item.id || index}>{item.description}</li>
                  ))}
                </ul>
              </div>
              {/* signature */}
              <div className="p-4 text-xs flex flex-col items-end w-1/2 ">
                {/* <div className="flex justify-end">
                {invoiceSetting?.sign_authority && (
                  <div className="w-[100px] h-[30px] rounded-lg"></div>
                )}
                <div className="w-[100px] h-[30px] rounded-lg"></div>
              </div> */}
                <div className="flex justify-end items-center">
                  <div className="flex flex-col items-center border-t border-gray-300">
                    <hr className="w-full" />
                    <span>Authorized Signature</span>
                  </div>
                </div>
              </div>
            </div>
            {/* footer */}
            <div className="text-center font-nunito mt-20 text-xs md:text-sm lg:text-base">
              <h2 className="text-orange-500  text-xs font-semibold ">
                Thanks for purchase
              </h2>
              <p className="text-black mt-2 text-xs">
                A Product of SQUAD INNOVATORS{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  div: {
    backgroundColor: "#407BFF",
    color: "white",
    WebkitPrintColorAdjust: "exact",
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    margin: "13px 0px 10px 0px",
  },
  paymentMethodDiv: {
    transform: "rotate(-45deg)",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: '#407BFF',
    color: "black",
    // WebkitPrintColorAdjust: 'exact',
    margin: "30px 30px 20px 180px",
    maxWidth: "120px",
    wordWrap: "break-word",
    overflow: "hidden",
    fontSize: "13px",

    // border: '2px solid #407BFF',
    // borderRadius: '50%',
    // width: '180px',
    // height: '120px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
  paymentMethodPrintDiv: {
    transform: "rotate(-45deg)",
    padding: "10px",
    textAlign: "center",
    // backgroundColor: '#407BFF',
    color: "black",
    // WebkitPrintColorAdjust: 'exact',
    margin: "30px 30px 20px 180px",
    maxWidth: "120px",
    wordWrap: "break-word",
    overflow: "hidden",
    fontSize: "10px",

    // border: '2px solid #407BFF',
    // borderRadius: '50%',
    // width: '120px',
    // height: '120px',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
  },
};

export default PdfHtmlContent;
