import { PiNotepad } from "react-icons/pi";
import { IoClose } from "react-icons/io5";
import { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function NotepadIcon() {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const emp = JSON.parse(localStorage.getItem("employee"));
  const [open, setOpen] = useState(false);
  const [note, setNote] = useState("");

  // Load saved note from localStorage when the component mounts or opens
  useEffect(() => {
    if (open) {
      const savedNote = localStorage.getItem("note");
      if (savedNote) {
        setNote(savedNote);
      }else{
        const payload = {
          employee_id: emp?.id ? emp?.id : ''
        };
        axios.post(
          `${process.env.REACT_APP_API_URL}get-note`, payload, {
            headers: {
              authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        ).then((response) => {

          if(response?.data?.success){
            setNote(response?.data?.data[0]?.note);
          }
        })
        .catch((error) => {
          navigate('/login');
          console.log(error);
        });
      }
    }
  }, [open]);

  // Save the note to localStorage and show toast notification
  const handleSave = () => {
    localStorage.setItem("note", note);
    const payload = {
      employee_id: emp?.id ? emp?.id : '',
      note: note ? note : ''
    };
    axios.post(
      `${process.env.REACT_APP_API_URL}save-note`, payload, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if(response?.data?.success){
        setNote(response?.data?.data?.note);
      }
      toast.success("Your note has been saved successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });

  };

  // Clear the note from both state and localStorage
  const handleClear = () => {
    setNote("");
    localStorage.removeItem("note");
    const payload = {
      employee_id: emp?.id ? emp?.id : '',
      note: ''
    };
    axios.post(
      `${process.env.REACT_APP_API_URL}save-note`, payload, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      if(response?.data?.success){
        setNote(response?.data?.data?.note);
      }
      toast.info("Note cleared", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });

  };

  return (
    <>
      <ToastContainer />
      <button
        onClick={() => setOpen(!open)}
        aria-label="Open Notepad"
        title="Open Notepad"
        className="fixed bottom-1/2 right-3 transform bg-blue-500 rounded-full p-2 cursor-pointer z-[9999] hover:bg-black"
      >
        <PiNotepad className="text-white font-extrabold text-5xl" size={30} />
      </button>
      {open && (
        <div className="modal-overlay fixed inset-4 sm:inset-auto sm:bottom-36 sm:right-[4rem] w-[80%] sm:max-w-[450px] p-4 rounded-2xl flex flex-col bg-white text-black shadow-lg z-[99999999] sm:w-[90%] md:w-[450px] space-y-2">
          <div className="flex justify-between items-center">
            <h3 className="font-medium text-lg">Smart Notepad</h3>
            <div className="bg-[#dc2626] p-1 rounded-full">
              <IoClose
                className="cursor-pointer text-white"
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
          <hr />
          <div className="flex-grow flex flex-col pt-2 h-[60vh] sm:h-60">
            <ReactQuill
              value={note}
              onChange={setNote}
              placeholder="Write your notes here..."
              className="resize-none h-full overflow-auto flex-grow"
              theme="snow"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
            />
          </div>
          <div className="flex justify-end space-x-2 mt-3">
            <button
              onClick={handleClear}
              className="bg-gray-300 hover:bg-gray-400 text-black py-2 px-4 rounded-md"
            >
              Clear
            </button>
            <button
              onClick={handleSave}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-md"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default NotepadIcon;
