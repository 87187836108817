import React from 'react';
import { formatPhoneNumber, numberToWords, formatPrice, invoiceCalculate } from '../../../utils/Common';
import Watermark from '../../../components/ReturnDetail/Watermark/Watermark';
import './PdfHtmlContent.css';



import QRCode from 'react-qr-code';

const PdfHtmlContent = props => {
  const { invoice, invoiceSetting,type, inputs } = props;
  const BASE_URL = process.env.REACT_APP_ROOT_URL;
  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
  const day = String(invoiceDate.getDate()).padStart(2, '0');
  const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
  const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
  const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';

  const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;;
  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
  let totalPrice = 0;
  let totalItems = 0;

  const item = type === 'salesReturn' ? invoice?.sales_details : invoice?.purchase_details;
  const totalQty = Number(item?.return_qty);
  const subtotalPrice = totalQty * Number(item?.return_unit_price);

  return (
    <div id="pdfConentAreas" className="pdfConentAreas">
      <div id="invoice-detail-page" className="invoice-detail-page font-nunito">
        {/* <div className="invoice-info">
          <h1>Invoice</h1>
          <p>{invoice.invoice_id}</p>
          <p>{formattedDate}</p>
        </div> */}
        {/* <div className="invoice-header">
          <div className="invoice-logo font-nunito font-semibold mx-auto w-[100px] h-[90px] md:w-[551px] md:h-[175px] text-center text-18">
            <img
              src={shop_logo}
              alt={invoice.invoice_id}
               className="mx-auto p-3" />
            <div className='my-2'>
            <p>{invoice?.user_info?.outlet_name} <br />
                {invoice?.user_info?.address}<br/>
                {formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}
                </p>
            </div>
          </div>
        </div> */}
        {/* <DashedLine /> */}

        {/* invoice header */}
        <div className="flex justify-between items-center pt-4 border-b-4 border-blue-300">

          {/* Shop Logo Section */}
          <div className="w-1/3 flex flex-col justify-start items-start">
            <img
              src={invoiceSetting.shop_logo}
              alt="Shop Logo"
              className="w-[40px] sm:w-[60px] md:w-[80px] object-contain"
            />
            <p className="mt-2"> {/* Adds some spacing between image and text */}
              <span className="font-semibold text-sm" style={{ letterSpacing: "0.08em" }}>Sale Invoice</span>
            </p>
          </div>

          {/* QR Code Section */}
          <div className="w-1/3 flex justify-center pb-2">
            <div className="w-[45px] sm:w-[65px] md:w-[100px]">
              <QRCode
                className="w-full object-contain"
                value={BASE_URL + '/invoice/' + invoice?.data?.invoice_id}
                size={100}
              />
            </div>
          </div>

          {/* Shop Info Section */}
          <div className="w-1/3 flex flex-col justify-end items-start">
            <span className="font-semibold text-lg sm:text-xl md:text-2xl" style={{ letterSpacing: "0.08em" }}>
              {invoiceSetting.shop_name}
            </span>
            <span className="text-xs sm:text-md text-gray-600">
              <strong>Phone:</strong> {invoice.user_info.phone}
            </span>
            <span className="text-xs sm:text-md text-gray-600">
              <strong>Email:</strong> {invoice.user_info.email}
            </span>
            <span className="text-xs sm:text-md text-gray-600">
              <strong>Address:</strong> {invoiceSetting.shop_address}
            </span>
          </div>
        </div>

        {/* customer info and QR */}
        <div className="flex items-center mt-[10px] justify-between">
          <div className="text-black space-y-[2px] md:space-y-[6px] font-nunito text-xs  lg:text-base">
            {type === 'salesReturn' ?
              // <p className='mb-2'> <span className='font-semibold'>Customer Name</span>  :  {invoice?.sales?.customer_name} </p>
              <p> <span className='font-semibold'>Bill to,</span> <br /> <span className='font-semibold'>Customer Name </span> : {invoice?.sales?.customer_name} </p>
            :
              <p className='mb-2'> <span className='font-semibold'>Vendor Name</span>  :  {invoice?.purchase?.vendor_name} </p>
            }
            {type === 'salesReturn' ?
              <p className='mb-2'><span className='font-semibold'>Customer Phone</span> :  {invoice?.sales?.customers?.mobile_number ? invoice?.sales?.customers?.mobile_number : invoice?.sales?.customer_phone}</p>
            :
              <p className='mb-2'><span className='font-semibold'>Vendor Phone</span> :  {invoice?.purchase?.vendor?.country_code}{invoice?.sales?.vendor?.mobile_number}</p>
            }
            {type === 'salesReturn' ?
              <p className='mb-2'><span className='font-semibold'> Customer ID </span> : #{invoice.customer_id}</p>
            :
              <p className='mb-2'><span className='font-semibold'> Vendor ID </span> : #{invoice.vendor_id}</p>
            }
            {/* <p className='mb-2'><span className='font-semibold'> Invoice Date </span>: {formattedDateTime} </p> */}
          </div>
          <div className="flex flex-col justify-start items-start text-black space-y-[2px] md:space-y-[4px] font-nunito text-xs  lg:text-base">
            {/* <QRCode className="w-full object-contain" value={BASE_URL + '/invoice/' + invoice.invoice_id} size={100} /> */}
            <p> <span className='font-semibold'>Return ID</span> : {invoice.return_id} </p>
            <p><span className='font-semibold'>Invoice Date</span> :  {formattedDateTime}</p>
          </div>
          {/* <div className="w-[100px]">
            {type === 'salesReturn' ?
              <QRCode className="w-full object-contain" value={BASE_URL + '/sales-return/' + invoice.return_id} size={100} />
            :
              <QRCode className="w-full object-contain" value={BASE_URL + '/purchase-return/' + invoice.return_id} size={100} />
            }
          </div> */}
        </div>

        {/* <div style={{ width: '100%', backgroundColor: '#3056FA', padding: '10px', textAlign: 'center', margin: '15px auto' }}>
          <p className="text-white font-nunito text-14 font-bold uppercase">Invoice No: {invoice.return_id}</p>
        </div> */}

        {/* <div
          className="w-full bg-[#3056FA] px-[14px] pb-[17px] flex justify-between items-center text-xs md:text-base lg:text-lg"
        >
          <p className="text-white font-nunito font-bold uppercase">
            RETURN INFO
          </p>
          <p className="text-white font-nunito font-bold uppercase">
            #{invoice.return_id}
          </p>
        </div> */}

        {/* <div className={`invoice-items`}> */}
        <div className="mx-auto md:w-full md:max-w-full overflow-x-auto mb-0 mt-3">
          {/* <table className={`table ${'h-[600px]' ? 'overflow-x-scroll' : 'overflow-x-hidden'}`}> */}
          <table className=" table border-b-4 border-blue-300">
            <thead id="pdfContentTHead" className='pb-3'>
              <tr className="leading-normal text-sm lg:text-base font-nunito tracking-wide border-b-4 border-t-4 border-blue-300 ">
                {/* <th><span className="hidden md:inline-block">Return</span> Date</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Purchased Amount</th>
                <th className='text-right'>Return Amount</th> */}
                <th className='font-nunito text-left ' style={{ padding: '3px 5px' }}>#</th>
                <th className='font-nunito text-left ' style={{ padding: '3px 5px' }}>
                  <span className="hidden md:inline-block">Return</span> Date
                </th>
                <th className='font-nunito text-start ' style={{ padding: '3px 5px' }}>Product Name</th>
                <th className='font-nunito text-center ' style={{ padding: '3px 5px' }}>Quantity</th>
                <th className='font-nunito text-center ' style={{ padding: '3px 5px' }}>Purchased Amount</th>
                <th className='font-nunito text-right ' style={{ padding: '3px 5px' }}>Return Amount</th>
              </tr>
            </thead>
            <tbody className='mt-2'>
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark>
              { item && (
                  <tr key={item.id} className="text-blue-gray-500 font-nunito text-[16px] relative">
                    <td>{formattedDate}</td>
                    <td>{item?.product_info?.name}</td>
                    <td>{item.return_qty}</td>
                    <td>{formatPrice(subtotalPrice)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</td>
                    <td className='text-gray-900 font-nunito  font-bold text-right'>{formatPrice(item.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</td>
                  </tr>
              )} */}
              {(() => {
                let rowCounter = 0;

                return invoice?.sales_details?.map((item) => {

                    let subtotalPrice = 0;
                    subtotalPrice = formatPrice(item.quantity * item.purchase_price); // Calculate subtotal price for the product
                    // totalQty += Number(item.quantity); // Add the quantity of the product
                    totalItems += item.quantity; // Count this product as a single item
                    totalPrice += Number(subtotalPrice); // Add to the total price
                    return (
                      <tr key={item.id} className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative">
                        <td className="text-left " style={{ padding: '10px' }}>{++rowCounter}</td> {/* SL # */}
                        <td className="text-left " style={{padding: '10px' }} >{formattedDate}</td>
                        <td className="" style={{ padding: '10px' }}>{item.product_info?.name}</td>
                        <td className="text-center text-sm" style={{  padding: '10px' }}>{item.product_info?.quantity}</td>
                        <td className="text-center text-sm" style={{ padding: '10px' }}>{item.product_info?.purchase_price}</td>
                        <td className="text-right font-semibold md:font-semibold text-sm" style={{  padding: '10px' }}>
                          {subtotalPrice}{invoiceSetting?.currency_info?.code || ' BDT'}
                        </td>
                      </tr>
                    );
                });
              })()}
            </tbody>
          </table>
        </div>
        {/* <DashedLine2 /> */}
        {/* <br /> */}
        {/* <div className='flex justify-between gap-[10px] border-t-[1px] border-solid border-[#000] mt-[30px]'>
          <div className="terms-condition font-semibold text-[18px]">
            <p className="font-bold text-[20px]">Return Description:</p>
            <p className='font-[400] text-[20px]'>{invoice?.return_description}</p>
          </div>
          <div className="invoice-summary ">

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>TOTAL ITEMS</span>
              <span>{totalQty}</span>
            </p>

            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>SUB TOTAL</span>
              <span>{formatPrice(subtotalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>
            <p className='flex mt-2 justify-between text-navy-800 font-nunito font-semibold gap-3'>
              <span>Grand Total</span>
              <span>{formatPrice(subtotalPrice)}{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Paid Amount</span>
              <span>
              {type === "salesReturn" ? formatPrice(invoice?.sales?.paid_amount) : formatPrice(invoice?.purchase?.paid_amount) }
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Return Amount</span>
              <span>
               {item?.return_amount}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-3 justify-between font-nunito font-[600] text-[#24px] border-t-[1px] border-solid border-[#E7EBF4]">
              <span>Due Amount</span>
              <span>{formatPrice(item?.return_amount-subtotalPrice)}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>
          </div>
        </div> */}

        {/* summary */}
        <div className="w-full flex justify-between pt-0 ml-auto ">
            {/* disclaimer */}
            {/* payment method */}
            <div style={styles.paymentMethodPrintDiv}>
              <div>
                {invoice?.multiple_payment?.length > 0 ? (
                  <span className='' style={{ letterSpacing: "0.08em" }}>
                    Paid by {invoice.multiple_payment.map(payment => payment.payment_type.type_name).join(', ').replace(/, ([^,]*)$/, ' and $1')}
                  </span>
                ) : (
                  <span>No payment information available</span>
                )}
              </div>
            </div>
            {/* invoice sumary */}
            <div className="w-1/3 text-xs font-semibold pl-12">
              <div className="space-y-[2px] mb-2">
                {/* <p className="flex justify-between">
                  <span>Total Items</span>
                  <span>{totalItemsPrint}</span>
                </p> */}
                <p className="flex justify-between">
                  <span >Sub Total</span>
                  <span>{invoice?.sales?.sub_total}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
                </p>
                {/* <p className="flex justify-between">
                  <span>Discount</span>
                  <span>
                    {formatPrice(invoice.discount ? invoice.discount : 0)}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p> */}
                {/* <p className="flex justify-between">
                  <span>Vat</span>
                  <span>
                    {invoice.vat ? formatPrice(invoice.vat): 0.0000}
                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p> */}
                {/* <p className="flex justify-between">
                  <span>Service Charge </span>
                  <span>
                    {invoice.tax ? formatPrice(invoice.tax) : 0.0}

                    {invoiceSetting?.currency_info?.code
                      ? invoiceSetting?.currency_info?.code
                      : " BDT"}
                  </span>
                </p> */}
              </div>
              {/* <hr></hr> */}
              <div style={{ borderTop: "0.5px solid #D3D3D3", margin: "10px 0" }}></div>

              <div className="my-2">
                <p className="flex justify-between">
                  <span>Grand Total</span>
                  <span>{formatPrice(invoiceCalculate(invoice).grandTotal) }{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
                </p>
                <p className="flex justify-between">
                  <span>Paid Amount</span>
                  {type === "salesReturn" ? formatPrice(invoice?.sales?.paid_amount) : formatPrice(invoice?.purchase?.paid_amount) }
                    {invoice?.user_info?.invoiceSetting?.currency_info?.code
                        ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                        : " BDT"
                    }
                </p>
              </div>
              {/* <hr></hr> */}
              <div style={{ borderTop: "0.5px solid #D3D3D3", margin: "10px 0" }}></div>
              <p className="flex justify-between mt-2">
                <span >Return Amount</span>
                <span>
               {invoice?.return_amount}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
                </span>
              </p>
              <p className="flex justify-between mt-2">
                <span >Due Amount</span>
                <span>{formatPrice(item?.return_amount-subtotalPrice)}
                {invoice?.user_info?.invoiceSetting?.currency_info?.code
                    ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                    : " BDT"}</span>
              </p>
            </div>
        </div>

        {/* amount in words */}
        <p className="text-gray-900 mt-4 font-nunito text-xs  lg:text-base font-semibold leading-normal text-center">
            <span  style={{ color: '#A0AEC0' }}>In Word:</span>{" "}
            {numberToWords(subtotalPrice)} taka only
        </p>
        <div
            style={{...styles.div }}
          >
            <p className="text-white font-nunito text-xs font-bold">
              You saved {formatPrice(invoice.discount ? invoice.discount : 0)}
              {/* You saved {invoice.discount ? invoice.discount : 0} */}
              {invoiceSetting?.currency_info?.code
                ? invoiceSetting?.currency_info?.code
                : " BDT"}{" "}
              in this purchase{" "}
            </p>
        </div>
        {/* web address qr code */}
        <QRCode
                    className="w-[50px] object-contain  p-0 mt-5"
                    value={invoice?.data?.user_info?.invoice_settings?.web_address || ''}
                    size={50}
        />

        {/* <div>
          <div className="flex justify-between">
            <p ><hr></hr> Customer Signature</p>
            <p ><hr></hr> Seller Signature </p>
          </div>
        </div> */}

        {/* Terms and Conditions Section */} {/* Signature Section */}
        <div className="flex space-x-5 text-[10px] lg:text-sm mt-2">
            <div className=" pt-2 w-1/2 ">
                <h3 className="text-[10px] font-semibold text-gray-800 mb-2">Terms and Conditions</h3>
                <ul className="list-disc list-inside text-gray-700 text-[10px]">
                  {/* <li>All prices include taxes. Payment must be made in full at the time of purchase via accepted payment methods.</li>
                  <li>Disputes will be resolved under the laws of Bangladesh.</li>
                  <li>Defective or incorrect items will be replaced or refunded at no additional cost.</li> */}
                  {inputs?.map((item, index) => (
                    <li key={item.id || index}>{item.description}</li>
                  ))}
                </ul>
            </div>
            {/* signature */}
            <div className="p-4 text-xs flex flex-col items-end w-1/2 ">
              <div className="flex justify-end items-center">
                <div className="flex flex-col items-center border-t border-gray-300">
                  <hr className="w-full" />
                  <span>Authorized Signature</span>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center font-nunito mt-20 text-xs md:text-sm lg:text-base">
            <h2 className="text-orange-500  text-xs font-semibold ">
              Thanks for purchase
            </h2>
            <p className="text-black mt-2 text-xs">
              A Product of SQUAD INNOVATORS{" "}
            </p>
          </div>

      </div>
    </div>
  );
};

const styles = {
  div: {
    backgroundColor: '#407BFF',
    color: 'white',
    WebkitPrintColorAdjust: 'exact',
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    margin: '13px 0px 10px 0px',
  },
  paymentMethodDiv: {
    transform: 'rotate(-45deg)',
    padding: '10px',
    textAlign: 'center',
    // backgroundColor: '#407BFF',
    color: 'black',
    // WebkitPrintColorAdjust: 'exact',
    margin: '30px 30px 20px 180px',
    maxWidth: '120px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: '13px'
  },
  paymentMethodPrintDiv: {
    transform: 'rotate(-45deg)',
    padding: '10px',
    textAlign: 'center',
    // backgroundColor: '#407BFF',
    color: 'black',
    // WebkitPrintColorAdjust: 'exact',
    margin: '30px 30px 20px 180px',
    maxWidth: '120px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: '10px'
  },
};

export default PdfHtmlContent;
