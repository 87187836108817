import Item from "antd/es/list/Item";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ItemSelectBox from "./ItemSelectBox";
import ItemSelectBox2 from "./ItemSelectBox2";
import Swal from "sweetalert2";

const PaymentTypeForm = (props) => {
  const {
    loadingCallback,
    setSeller,
    setWarningMessage,
    state,
    setShowModal,
    isOpen,
    formId,
    paymentList,
    paymentAccountList,
    filteredAccountList,
    setFilteredAccountList,
    setSelectedAccountList,
    setPaidAmount,
    paymentData,
    formData,
    setFormData,
    total,
    grandTotalAfterDiscount,
    is129TotalPrice,
    setData,
    filteredSeller,
    setFilteredSeller,
    postAccountTxn,
    disabledForSetShowModal,
    paidAmount,
    autoSubmitOnAmountChange = false,
  } = props;

  const user = JSON.parse(localStorage.getItem('user'));

  const [uniqueMap, setUniqueMap] = useState([]);
  const [totalGrandAmount, setTotalGrandAmount] = useState(
    grandTotalAfterDiscount
  );
  const [changeToBeReturnedAmount, setChangeToBeReturnedAmount] = useState(0);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault(); // Prevent the default form submission if there's an event
    }
    if (!disabledForSetShowModal) {
      setShowModal(!isOpen);
    }

    const uniqueNamesMap = new Set();

    const uniqueMap = paymentAccountAmount.reduce((acc, t) => {
      const key = `${t.payment_type_category_id}_${t.payment_type_id}`;

      if (!uniqueNamesMap.has(key) && Number(t.Amount) >= 0) {
        uniqueNamesMap.add(key);

        const total = paymentAccountAmount.reduce((innerAcc, item) => {
          if (
            t.payment_type_category_id === item.payment_type_category_id &&
            t.payment_type_id === item.payment_type_id &&
            Number(item.Amount) > 0
          ) {
            return innerAcc + Number(item.Amount);
          } else {
            return innerAcc;
          }
        }, 0);

        console.log("total Amount:", total);
        acc.push({ ...t, Amount: total });
      }

      return acc;
    }, []);

    const totalAmount = uniqueMap.reduce((acc, item) => {
      return acc + Number(item.Amount);
    }, 0);
    setPaidAmount(totalAmount);

    setSelectedAccountList(uniqueMap);

    if (
      (!!postAccountTxn && location.pathname === "/journal-list") ||
      location.pathname === "/expense-list"
    ) {
      postAccountTxn(uniqueMap);
      return;
    }
  };

  const navigate = useNavigate();
  const [AccountList, setAccountList] = useState([]);

  const amountData = {
    methodName: "cash",
    AccountName: "",
    account_number: "",
    payment_type_id: "",
    payment_type_category_id: "",
    Amount: '',
  }
  const [paymentAccountAmount, setPaymentAccountAmount] = useState([{
    methodName: paymentData ? paymentData[0]?.type_name : null,
    AccountName: paymentData ? paymentData[0]?.payment_type_category[0]?.payment_category_name: null,
    account_number: paymentData ? paymentData[0]?.payment_type_category[0]?.account_number : null,
    payment_type_id: paymentData ? paymentData[0]?.id : null,
    payment_type_category_id: paymentData ? paymentData[0]?.payment_type_category[0]?.id : null,
    Amount: total && Number.parseFloat(total).toFixed(2) || totalGrandAmount &&  Number.parseFloat(totalGrandAmount).toFixed(2),
  //   product129Price:
  // typeof product129Price === 'number' && !isNaN(product129Price)
  //   ? product129Price.toFixed(2)
  //   : null
  }]);

  console.log(paymentAccountAmount);
  // useEffect(()=>{
  //   const total = paymentAccountAmount.reduce((s, item)=>{
  //       return s += Number(item.Amount);
  //   }, 0);
  //   if (total == NaN) {
  //     total = 0;
  //   }
  //   setTotalGrandAmount(
  //     Number.parseFloat(
  //       Number(grandTotalAfterDiscount) - Number(total)
  //     ).toFixed(2)
  //   );
  //   setChangeToBeReturnedAmount(
  //     Number.parseFloat(
  //       Number(total).toFixed(2) - Number(grandTotalAfterDiscount)
  //     ).toFixed(2)
  //   );
  // }, [paymentAccountAmount]);
  // const [filteredAccountList, setFilteredAccountList] = useState([])

  useEffect(() => {
    let total = paymentAccountAmount.reduce((sum, item) => {
      return sum + Number(item.Amount || 0);
    }, 0);

    if (isNaN(total)) total = 0;

    const calculatedGrandAmount = Number.parseFloat(grandTotalAfterDiscount - total).toFixed(2);

    const calculatedChangeAmount = total - grandTotalAfterDiscount;

    setTotalGrandAmount(Number.parseFloat(calculatedGrandAmount).toFixed(2));
    setChangeToBeReturnedAmount(
      Number.parseFloat(calculatedChangeAmount).toFixed(2)
    );
  }, [paymentAccountAmount, is129TotalPrice, grandTotalAfterDiscount, user?.id]);


  const [filteredAccountDetails, setFilteredAccountDetails] = useState({});
  const [methodIndex, setMethodIndex] = useState(null);

  const handleChangeSelect = (name, option) => {
    const { value, label } = option;
    // setFormData({
    //   ...formData,
    //   [name]: value,
    // });
    console.log("Option", name, option);
    console.log("PaymentList", paymentAccountList, paymentList);
    if (name === "payment_id") {
      if (label != "Select Payment" && value) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          method_id: "",
          account_number: "",
          payment_id: value,
          type_name: label,
        }));
        const selectedMethodObject = paymentList.find(
          (method) => method.id === value
        );
        if (selectedMethodObject) {
          setFilteredAccountList(
            selectedMethodObject.payment_type_category || []
          );
        } else {
          setFilteredAccountList([]);
        }
        const newArray = [...paymentData];
        newArray[methodIndex] = selectedMethodObject;
        setData(newArray);

        const newArray2 = [...paymentAccountAmount];
        newArray2[methodIndex] = {
          ...amountData,
          payment_type_id: value,
          methodName: label,
        };
        setPaymentAccountAmount(newArray2);
      }
      if (label == "Select Payment") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          account_id: "",
          account_number: "",
        }));
      }
    } else if (name == "account_id") {
      if (label == "Select Account") {
        setFormData((prevFormData) => ({
          ...prevFormData,
          account_id: "",
          account_number: "",
        }));
        const newArray = [...paymentAccountAmount];
        newArray[methodIndex] = {
          ...amountData,
          account_number: "",
          AccountName: "",
          payment_type_category_id: "",
        };
        setPaymentAccountAmount(newArray);
        setFilteredAccountDetails({});
      } else {
        const selectedAccount = paymentAccountList.find(
          (method) => method.id === value
        );

        console.log("account", selectedAccount, methodIndex);
        if (selectedAccount) {
          const newArray = [...paymentAccountAmount];
          newArray[methodIndex] = {
            ...newArray[methodIndex],
            AccountName: label,
            account_number: selectedAccount?.account_number,
            payment_type_category_id: value,
          };
          setPaymentAccountAmount(newArray);
          setFilteredAccountDetails({})
        }
        else{
          const selectedAccount = paymentAccountList.find(
            (method) => method.id === value
          );

          console.log("account",selectedAccount, methodIndex);
          if(selectedAccount){
            const newArray = [...paymentAccountAmount];
            newArray[methodIndex] = {...newArray[methodIndex],
              AccountName:selectedAccount?.payment_category_name,
              account_number: selectedAccount?.account_number,
              payment_type_category_id: value
            }
            setPaymentAccountAmount(newArray);
            setFilteredAccountDetails(selectedAccount)
          }
        }
      }
    }
  };

  // const handleInputChange = (e, index) => {
  //   const value = e.target.value;
  //   // Regex to match numbers with optional decimal points
  //   const regex = /^\d*\.?\d*$/;
  //   if (regex.test(value)) {
  //     setPaymentAccountAmount((prevAmount) => [
  //       ...prevAmount.slice(0, index),
  //       { ...prevAmount[index], Amount: value },
  //       ...prevAmount.slice(index + 1),
  //     ]);
  //   }
  // };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedPaymentAccountAmount = [...paymentAccountAmount];

    // if(user?.id === 129){
    //   updatedPaymentAccountAmount[index] = {
    //     ...updatedPaymentAccountAmount[index],
    //     product129Price: value,
    //   };
    // }

    updatedPaymentAccountAmount[index] = {
      ...updatedPaymentAccountAmount[index],
      Amount: value,
    };



    setPaymentAccountAmount(updatedPaymentAccountAmount);

    // Conditionally trigger form submission
    if (autoSubmitOnAmountChange) {
      handleSubmit(); // Automatically submit the form
    }
  };

  //  useEffect(()=>{
  //   const selectedAccount = paymentList.find(
  //     (method) => method.type_name === "cash"
  //   );
  //   setFormData({...formData, payment_id: selectedAccount.id})
  //  },[])

  const handleAddMethod = async () => {
    if (paymentData.length < paymentList.length) {
      const selectedAccount = paymentList.find(
        (method) => method.type_name.toLowerCase() === "cash"
      );
      if(!selectedAccount){
        selectedAccount = paymentList[0];
      }
      console.log('paymentList ',paymentList)
      console.log('paymentData ',paymentData);

      setData([...paymentData, selectedAccount])
      const newArray = [...paymentAccountAmount];
      console.log("selected ",selectedAccount);
      let data = {...amountData,
        methodName: selectedAccount.type_name,
        payment_type_id: selectedAccount.id,
      };
      setPaymentAccountAmount([...paymentAccountAmount, data]);
    }
  };

  const handleCancelMethod = (id, par) => {
    if (paymentData.length > 1) {
      const indexToRemove = paymentData.findIndex(
        (item, index) => index === par
      );
      if (indexToRemove !== -1) {
        const newData = [...paymentData];
        newData.splice(indexToRemove, 1);
        setData(newData);
      }
      const indexToRemove2 = paymentAccountAmount.findIndex(
        (item, index) => index === par
      );
      if (indexToRemove2 !== -1) {
        const newData2 = [...paymentAccountAmount];
        newData2.splice(indexToRemove2, 1);
        setPaymentAccountAmount(newData2);
      }
    }
  };



  return (
    <div className='duration-500'>
      {console.log('paymentdata ', paymentData)}
      <div className='flex flex-row items-center justify-between py-3'>
        <p className='text-md font-bold'>Total Amount</p>
        <p className='text-md font-bold'>{total && Number.parseFloat(total).toFixed(2) || totalGrandAmount &&  Number.parseFloat(totalGrandAmount).toFixed(2)}</p>
      </div>
      {
        paymentData ? paymentData.map((item, index)=>(
          <div key={index} className=''>
            <div className='flex flex-row justify-between items-center py-3 px-1'>
              <h1 className='text-md font-semibold font-lato pb-1 px-1'>{item.type_name}</h1>
              {index!=0 && <div
                className='bg-red-600 text-white rounded-md cursor-pointer'
                onClick={()=>handleCancelMethod(item.id, index)}
              >
                <p className='text-sm px-[4px] py-[2px]'>Cancel</p>
              </div>}
            </div>
{/*
            <div className='flex items-center justify-center py-3 gap-3' onClick={()=>setMethodIndex(index)}>
            {console.log(formData.payment_id)}
              <ItemSelectBox2
                name="payment_id"
                options={paymentList}
                value={formData.payment_id}
                onChange={handleChangeSelect.bind(this, 'payment_id')}
                label={"Method Name"}
                placeholder={item.type_name}
                style={{ maxHeight: '300px', overflowY: 'scroll' }}
              />
            </div> */}


              <div className='flex items-center justify-center py-3 gap-3' onClick={()=>setMethodIndex(index)}>
           {/*  {console.log(formData.account_id)} */}
                <ItemSelectBox
                  name="account_id"
                  options={filteredAccountList}
                  value={formData.account_id}
                  label={"Account Name"}
                  onChange={handleChangeSelect.bind(this, 'account_id')}
                  placeholder={item?.payment_type_category[index]?.payment_category_name ? item?.payment_type_category[index]?.payment_category_name : 'Select Account Name' }
                />
              </div>

              <div
                className="flex items-center justify-center py-3 gap-3"
                onClick={() => setMethodIndex(index)}
              >
                <ItemSelectBox2
                  name="payment_id"
                  options={paymentList}
                  value={formData.payment_id}
                  onChange={handleChangeSelect.bind(this, "payment_id")}
                  label={"Method Name"}
                  placeholder={item.type_name}
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                />
              </div>

              {item.type_name.toLowerCase() != "cash" && (
                <>
                  <div
                    className="flex items-center justify-center py-3 gap-3"
                    onClick={() => setMethodIndex(index)}
                  >
                    <ItemSelectBox
                      name="account_id"
                      options={filteredAccountList}
                      value={formData.account_id}
                      label={"Account Name"}
                      onChange={handleChangeSelect.bind(this, "account_id")}
                      placeholder="Select Account"
                    />
                  </div>

                  <div className="flex items-center justify-center py-3 gap-3">
                    <div className="w-full relative">
                      <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                        Account Number
                      </label>
                      <input
                        type="text"
                        name="name"
                        disabled
                        value={paymentAccountAmount[index]?.account_number}
                        className="input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white"
                      />
                    </div>
                  </div>
                </>
              )}

              {/* <div className='flex items-center justify-center py-2 gap-3'>
              <div className='w-full relative'>
                <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[14px] left-[12px] bg-white px-1 font-semibold">
                Amount</label>
                <input
                  type='text'
                  name="name"
                  disabled={ item.type_name=='cash'? false : paymentAccountAmount[index]?.account_number ? false:true}
                  value={paymentAccountAmount[index]?.Amount}
                  onChange={(e) => handleInputChange(e, index)}
                  className='input input-bordered w-full mb-[10px] rounded-xl bg-white'
                />
              </div>
            </div> */}

              <div className="flex items-center justify-center py-2 gap-3">
                <div className="w-full relative">
                  <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[14px] left-[12px] bg-white px-1 font-semibold">
                    Amount
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="000.0"
                    disabled={
                      item.type_name == "cash"
                        ? false
                        : paymentAccountAmount[index]?.account_number
                        ? false
                        : true
                    }
                    value={paymentAccountAmount[index]?.Amount}

                    onChange={(e) => handleInputChange(e, index)} // Amount change handler
                    className="input input-bordered w-full mb-[10px] rounded-xl bg-white"
                  />
                </div>
              </div>

              {paymentData.length > 1 && <hr />}
            </div>
          ))
        : null}
      <div className="flex flex-row justify-between items-center py-3 px-1">
        <div>
          <h1 className="text-md font-semibold font-lato">
            Pay with more Method
          </h1>
        </div>
        <div>
          <div
            onClick={handleAddMethod}
            className="flex justify-center items-center border-2 text-[#000] text-[18px] font-nunito font-bold py-[10px] px-[10px] cursor-pointer rounded-lg capitalize"
          >
            <i className="fa-solid fa-plus"></i>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-between items-center py-3 px-1">
        <div className="flex flex-col justify-start items-start">
          <h3 className="text-[#737373] text-xs font-medium">Due Amount</h3>
          <h1 className="text-[#0A0A0A] text-2xl font-bold">
            { totalGrandAmount < 0 ? "00" : totalGrandAmount}
            {/* { totalGrandAmount < 0 ? "00" : totalGrandAmount} */}
          </h1>
        </div>
        <div className="flex flex-col justify-end items-end">
          <h3 className="text-[#737373] text-xs font-medium">
            Change to be Returned
          </h3>
          <h1 className="text-[#0A0A0A] text-2xl font-bold">
            {changeToBeReturnedAmount < 0 ? "00" : changeToBeReturnedAmount}
          </h1>
        </div>
      </div>

      {!autoSubmitOnAmountChange && (
        <div>
          <button
            htmlFor="my_modal_payment"
            onClick={handleSubmit}
            className="cursor-pointer w-full bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentTypeForm;
