import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LuSettings } from "react-icons/lu";
import { BeatLoader } from "react-spinners";
import { uploadFile, uploadFileMultipleInput } from "../../../utils/Common";
import ImageUploader2 from "../../../forms/ImageUploader2";
import { useFlashMessage } from "../../../context/FlashMessageContext";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AppContext } from "../../../context/context";
import { get, post } from "../../../services/apiService";

import "./ShopSettingsV2.scss";
import { FlashMessage } from "../../../utils/FlashMessage";
import ShopSettingFormV2 from "../../../forms/ShopSettingFormV2";
import { Helmet } from "react-helmet-async";
const BASE_URL = process.env.REACT_APP_ROOT_URL;

const ShopSettingsV2 = () => {
  const {
    state,
    setCurrentUser,
    currentUser,
    updateInvoiceSetting,
    setShopCategoryList,
    shopCategoryList
  } = useContext(AppContext);

  // console.log(currentUser);

  const {
    getFlashMessageStyle,
    setSuccessFlashMessage,
    flashMessage,
    setErrorFlashMessage,
  } = useFlashMessage(); // Retrieve the dispatch function from your state management library

  console.log(state?.currentUser);

  const [currency, setCurrency] = useState([]);

  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState(null);

  // console.log(profile);
  const {
    profile_pic,
    owner_name,
    outlet_name,
    cover_pic,
    address,
    city_info,
    invoice_settings,
    email,
    phone,
    web_address,
    zipcode,
    otp_verified_ind,
    type
  } = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null;

  const loadingCallback = (loading) => {
    setLoading(loading);
  };

  const notify = (type, msg) => {
    if (type == "success") {
      toast.success(msg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
    if (type == "error") {
      toast.error(msg, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        // transition: Bounce,
      });
    }
  };

  const shopSettingData = profile;

  const shopSetting = shopSettingData
    ? shopSettingData
    : {
        outlet_name: "",
        address: "",
        country: "",
        district: "",
        city: "",
        type: ""
      };

  const [logoFile, setLogoFile] = useState();
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [uploadedFilesWatermark, setUploadedFilesWatermark] = useState({});
  const [uploadDetails, setUploadDetails] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const [warningMessage, setWarningMessage] = useState(false);
  const getCurrency = () => {
    get("currency", token)
      .then((response) => {
        if (response.success) {
          setCurrency(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const [shopType, setShopType] = useState(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const getShopCategory = () => {
    get("shop-category", token)
      .then((response) => {
        if (response.success) {
          setShopCategoryList(response.data);
          setShopType(response.data)
          console.log("shop category list", response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getProfile = () => {
    get("profile", token)
      .then((response) => {
        if (response.success) {
          setProfile(response.data);
          console.log("profile data", response.data)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getInvoiceSetting = () => {
    loadingCallback(true);
    get("get-invoice-settings", token)
      .then((response) => {
        if (response.success) {
          updateInvoiceSetting(response.data);
          loadingCallback(false);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (state?.currencyList?.length === 0) {
      getCurrency();
    }
    if (state?.shopCategoryList?.length === 0) {
      getShopCategory();
    }

    getProfile();
  }, []);
  const shopSettingDataSave = (values, shopSetting) => {
    const { ...data } = values;
    console.log(data);

    axios
      .post(`${process.env.REACT_APP_API_URL}outlet-update`, data, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        loadingCallback(true);
        if (response.data.success) {
          console.log(response.data);

          // Retrieve the existing user data from localStorage
          const currentUserData = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : null;

          if (currentUserData) {
            // Merge the existing user data with the updated data
            const updatedUserData = {
              ...currentUserData,
              ...data, // This will override only the updated fields
            };

            // Save the updated user data back to localStorage
            localStorage.setItem("user", JSON.stringify(updatedUserData));
          }
          getProfile();
          getInvoiceSetting();
          //setLogoFile(data.shop_logo);
          // setSuccessFlashMessage('Saved successfully');
          // notify('success', "Successfully Updated!");
          FlashMessage("Successfully Updated!", "success");
          //setShowModal(false);
        }
        loadingCallback(false);
      })
      .catch((error) => {
        console.log(error);
        loadingCallback(false);
        notify("error", "Someting went wrong!");
        // setWarningMessage(true);
        // const timer = setTimeout(() => {
        //   setWarningMessage(false);
        // }, 3000);
        if (error?.response?.status === 401) {
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);

          navigate("/login");
        }
      });
  };
  const [country, setCountry] = useState("");
  const [district, setDistrict] = useState("");
  const [city, setCity] = useState("");
  const [countryList, setCountryList] = useState(state?.countryList);
  const [districtList, setDistrictList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const getDistrictList = (country) => {
    post("get-district-by-country", { country_id: country }, token)
      .then((response) => {
        if (response.success) {
          setDistrictList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCityList = (district) => {
    setDistrict(district);
    post("get-cities-by-district", { district_id: district }, token)
      .then((response) => {
        if (response.success) {
          setCityList(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (shopSetting && shopSetting.country) {
      setCountry(shopSetting.country);
      getDistrictList(shopSetting.country);
    }
    if (shopSetting && shopSetting.district) {
      setDistrict(shopSetting.district);
      getCityList(shopSetting.district);
    } else {
      getDistrictList(country);
    }
  }, [country, shopSetting]);

  // Function to handle changes in the input fields
  const handleChangeSelect = (name, option) => {
    // prevents user from selecting initial dummy option
    //    if(!option?.value){
    //     console.log("you haven't selected anything")
    //     return;
    //     }
    const { value, label } = option;
    setProfile({
      ...profile,
      [name]: value,
    });
    if (name === "country") {
      setCountry(value);
    }
    if (name === "district") {
      setDistrict(value);
    }
  };

  function handleSubmitShopSetting(values, shopSetting) {
    let imageFileData = uploadDetails;

    // uploadFileMultipleInput(
    //     imageFileData,
    //     (shopLogo) => {
    const updatedFormData = { ...values };

    // shopLogo.forEach((item) => {
    //     updatedFormData[item.input_name] = item.path;
    // });
    // Success callback
    shopSettingDataSave(updatedFormData, shopSetting);
    // },
    // (error) => {
    //     // Error callback
    //     console.log(error);
    //     loadingCallback(false);
    // },
    // (progressDetails) => {
    //     // Progress callback, update uploadDetails here
    //     setUploadDetails(progressDetails);
    // }
    //);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };
  const handleNavigate = () => {
    navigate("/settings/branch-list");
  };

  const handleDropdownChange = (e, input) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...profile,
      [name]: value,
    }));
    // input.onChange(e);
  };


  const [isEditing, setIsEditing] = useState(false);

  return (
    <div className="rounded-[20px] bg-[#FAFAFA] p-3 font-nunito">
      <Helmet>
        <title>Outlet Expense | Settings | Shop Settings</title>
      </Helmet>
      <ToastContainer />

      {/* Header Section */}
      <div className="flex justify-start gap-3 items-center w-full  mb-3">
        <h2 className="text-xl font-semibold text-[#0A0A0A]">
          Account Settings
        </h2>
        <button
          onClick={() => setIsEditing(!isEditing)}
          className="text-[#407BFF] font-nunito font-semibold text-xs hover:underline  transition-all"
        >
          {isEditing ? "Cancel Edit" : "Edit Profile"}
        </button>
      </div>

      {/* Flash or Warning Messages */}
      {warningMessage && (
        <div className="text-red-500 font-bold mb-3">Something wrong!</div>
      )}
      {flashMessage && (
        <div
          className={`font-bold text-2xl text-center font-nunito mb-3 ${getFlashMessageStyle(
            flashMessage.type
          )}`}
        >
          {flashMessage.text}
        </div>
      )}

      {/* Loading State */}
      {loading && (
        <div className="custom-loader-logo flex justify-center">
          <img
            src={`${process.env.REACT_APP_ROOT_URL + "/smallLogo.png"}`}
            alt="logo"
            className="w-10 h-10 animate-spin"
          />
        </div>
      )}

      {/* Conditional Render: Edit Mode or View Mode */}
      {!isEditing ? (
        // View Mode
        <div className=" rounded-xl">
          <div className="space-y-4">
            {/* Owner Name */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Owner Name
              </span>
              <span className="text-[#0A0A0A] font-normal text-sm w-[80%]">
                {owner_name}
              </span>
            </div>

            {/* Shop Name */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Shop Name
              </span>
              <span className="text-[#0A0A0A] font-normal text-sm w-[80%]">
                {outlet_name}
              </span>
            </div>

            {/* Email Address */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Email Address
              </span>
              <div className="flex items-center gap-2 w-[80%]">
                <span className="text-[#0A0A0A] font-normal text-sm ">
                  {email}
                </span>
                <span className="bg-[#DEF8EE] text-green-600 px-2 py-1 rounded-[4px] font-medium text-[8px]">
                  Verified
                </span>
              </div>
            </div>

            {/* Mobile Number */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Mobile Number
              </span>
              <div className="flex items-center gap-2 w-[80%]">
                <span className="text-[#0A0A0A] font-normal text-sm ">
                  {phone}
                </span>
                <span
                  className={`${
                    otp_verified_ind === 1
                      ? "bg-[#DEF8EE] text-green-600"
                      : "bg-red-100 text-red-600"
                  }  px-2 py-1 rounded-[4px] font-medium text-[8px]`}
                >
                  {otp_verified_ind == 1 ? "Verified" : "Not Verified"}
                </span>
              </div>
            </div>

            {/* Company Name */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Company Name
              </span>
              <span className="text-[#0A0A0A] font-normal text-sm w-[80%]">
                {outlet_name}
              </span>
            </div>

            {/* Company Site */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Company Site
              </span>
              <span
                className={`${
                  web_address
                    ? "text-blue-500 hover:underline"
                    : "text-[#0A0A0A] font-normal"
                }  text-sm  w-[80%]`}
              >
                {web_address ? web_address : "Not Available"}
              </span>
            </div>

            {/* Address */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Address
              </span>
              <span className="text-[#0A0A0A] font-normal text-sm w-[80%]">
                {address
                  ?.split(" ")
                  ?.map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}
                ,{" "}
                {city_info?.name
                  .split(" ")
                  .map(
                    (word) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ")}
              </span>
            </div>

            {/* Postal Code */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Postal Code
              </span>
              <span className="text-[#0A0A0A] font-normal text-sm w-[80%]">
                {zipcode}
              </span>
            </div>
            {/* Shop Type */}
            <div className="flex justify-between gap-3 items-center w-full">
              <span className="text-[#A3A3A3] font-normal text-xs w-[20%]">
                Shop Category
              </span>
              <span className="text-[#0A0A0A] font-normal text-sm w-[80%]">
                {shopType && shopType.find(item => item.id === parseInt(user?.type))?.name || "Not Available"}
              </span>
            </div>
          </div>
        </div>
      ) : (
        <ShopSettingFormV2
          initialValues={shopSetting}
          onSubmit={(values) => {
            handleSubmitShopSetting(values, shopSetting);
          }}
          state={state}
          countryList={countryList}
          districtList={districtList}
          cityList={cityList}
          transparentBorder={true}
          handleChangeSelect={handleChangeSelect}
          handleChange={handleChange}
          file={logoFile}
          uploadedFilesWatermark={uploadedFilesWatermark}
          currency={currency}
          setUploadedFiles={setUploadedFiles}
          setUploadedFilesWatermark={setUploadedFilesWatermark}
          uploadDetails={uploadDetails}
          setUploadDetails={setUploadDetails}
          setIsEditing={setIsEditing}
          setShopCategoryList={setShopCategoryList}
          shopCategoryList={shopCategoryList}
          handleDropdownChange={handleDropdownChange}
        />
      )}
    </div>
  );
};

export default ShopSettingsV2;
