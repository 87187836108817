import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import QRCode from "react-qr-code";
import { formatPhoneNumber, numberToWords, formatPrice,invoiceCalculate } from "../../utils/Common";
import PdfHtmlContent from "./PdfHtmlContent/PdfHtmlContent";
import Watermark from "./Watermark/Watermark";
import { IoPrint } from "react-icons/io5";
import { FaChevronDown, FaCloudDownloadAlt } from "react-icons/fa";
import PdfHtmlContent3mm from "./PdfHtmlContent3mm/PdfHtmlContent3mm";
import PdfHtmlContent2mm from "./PdfHtmlContent2mm/PdfHtmlContent2mm";

const BASE_URL = process.env.REACT_APP_ROOT_URL;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ReturnDetail = (props) => {
  const [pageSelect, setpageSelect] = useState(null);
  const [dropdownPdf, setDropdownPdf] = useState(false);
  const [dropdownPrint, setDropdownPrint] = useState(false);
  const originalContents = document.body.innerHTML;

  const { invoice, invoiceSetting, invoiceCondition, paperSize, type, inputs } = props;

  const invoiceDate = new Date(invoice.created_at);
  const year = invoiceDate.getFullYear();
  const month = String(invoiceDate.getMonth() + 1).padStart(2, "0");
  const monthName = invoiceDate.toLocaleString("en-US", { month: "long" });
  const day = String(invoiceDate.getDate()).padStart(2, "0");
  const hours = String(invoiceDate.getHours() % 12).padStart(2, "0");
  const minutes = String(invoiceDate.getMinutes()).padStart(2, "0");
  const ampm = invoiceDate.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${year}-${month}-${day}`;
  const formattedDateTime = `${day} ${monthName} ${year} ( ${hours}:${minutes}${ampm} )`;

  const processedProductIds = new Set();

  let totalItems = 0;
  let totalPrice = 0;

  //const shop_logo = invoiceSetting?.shop_logo ? invoiceSetting?.shop_logo : `${BASE_URL}/layoutlogo.svg`;
  const shop_logo = invoiceSetting?.shop_logo
    ? invoiceSetting?.shop_logo
    : `${BASE_URL}/layoutlogo.svg`;

  const handlePrint = (elementId) => {
    const printContents = document.getElementById(elementId).innerHTML;
    document.body.innerHTML = printContents;
    window.print();
  };

  window.onafterprint = () => {
    document.body.innerHTML = originalContents;
    // Restore original content
    window.location.reload();
  };

  const handleGeneratePdf = async (pageSize, pageSizeNumber) => {
    const element = document.getElementById("pdfConentAreas");
    const element2 = document.getElementById("pdfConentAreas3");
    const element3 = document.getElementById("pdfConentAreas2");

    if (pageSizeNumber === 1) {
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
    } else if (pageSizeNumber === 2) {
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
    } else if (pageSizeNumber === 3) {
      console.log("page size", pageSizeNumber);
      setpageSelect(pageSizeNumber);
    }

    const formatOptions = {
      a4: "a4",
      "3mm": [80, 297], // Custom size for '3mm' format
      "2mm": [56, 297], // Custom size for '2mm' format
    };
    const shopLogoUrlWithProxy = `<span class="math-inline">${API_BASE_URL}</span>${shop_logo}`; // Prepend proxy URL

    const opt = {
      margin: 0.1,
      filename: "myPdf.pdf",
      image: { type: "jpeg", quality: 1 },
      //image: { type: 'jpeg', quality: 0.98, baseURL: 'https://api-outlet-expense.squadinnovators.com' },

      html2canvas: {
        // scale: 1,
        scale: 900 / 96,
        proxy: shopLogoUrlWithProxy,
        useCORS: true,
        allowTaint: false,
        logging: false,
      },
      jsPDF: {
        unit: "mm",
        format: formatOptions[pageSize], // 'a4', '3mm', or '2mm'
        orientation: "portrait",
        marginLeft: 10,
        marginTop: 10,
        marginRight: 10,
        marginBottom: 10,
        image: { type: "jpeg", quality: 1 },
        fontFaces: [{ family: "Arial", src: "path/to/arial.ttf" }],
      },
    };

    //const cssUrl = window.location.origin + '/static/pdfstyles.module.css';
    const head = document.getElementsByTagName("head")[0];
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    //link.href = cssUrl;
    head.appendChild(link);
    const images = element?.querySelectorAll("img");
    // const images2 = element2.querySelectorAll('img');
    // const images3 = element3.querySelectorAll('img');
    let loadedImages = 0;

    // const checkImagesLoaded = () => {
    //   loadedImages++;

    const generatePdf = () => {
      html2pdf()
        .set(opt)
        .from(element)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    const generatePdf2 = () => {
      html2pdf()
        .set(opt)
        .from(element2)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };
    const generatePdf3 = () => {
      html2pdf()
        .set(opt)
        .from(element3)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          pdf.save(`${invoice.invoice_id}_invoice_${pageSize}.pdf`);
          link.remove();
        });
    };

    //   if (loadedImages === images?.length) {
    // All images are loaded, proceed to generate the PDF
    if (pageSizeNumber === 1) {
      generatePdf();
    } else if (pageSizeNumber === 2) {
      generatePdf2();
    } else if (pageSizeNumber === 3) {
      generatePdf3();
    }

    //}
    // if (loadedImages === images2.length) {
    //   // All images are loaded, proceed to generate the PDF
    //   generatePdf2();
    // }
    // if (loadedImages === images3.length) {
    //   // All images are loaded, proceed to generate the PDF
    //   generatePdf3();
    // }

    //};

    // Add event listener to each image to check when they are loaded
    // if(images !=null){
    // images.forEach(img => {
    //   img.addEventListener('load', checkImagesLoaded);
    // })};

    // // Start the PDF generation process
    // if(images !=null){
    // images.forEach(img => {
    //   if (img.complete) {
    //     // If the image is already cached, trigger the load event manually
    //     checkImagesLoaded();
    //   }
    // })};
  };
  const item =
    type === "salesReturn" ? invoice?.sales_details : invoice?.purchase_details;
  const totalQty = Number(item?.return_qty);
  const subtotalPrice = totalQty * Number(item?.return_unit_price);
  // let discount = 0;
  // if (item?.product_info?.discount_type === "percentage" || item?.product_info?.discount_type === 2 || item?.product_info?.discount_type === '2') {
  //   if (type === "salesReturn") {
  //     discount = (item?.product_info?.retails_price * item?.product_info?.discount) / 100;
  //   } else {
  //     discount = (item?.product_info?.purchase_price * item?.product_info?.discount) / 100;
  //   }

  // } else {
  //   discount = item?.product_info?.discount;
  // }
  // //const subtotalPrice = type === "salesReturn" ? Number(item?.product_info?.retails_price - discount) : Number(item?.product_info?.purchase_price - discount);
  // const subtotalPrice = type === "salesReturn" ? Number(invoice?.sales?.sub_total) : Number(invoice?.purchase?.sub_total);
  return (
    <div>
      <div
        id="invoice-detail-page"
        className={`invoice-detail-page font-nunito `}
      >
        {/* <div className="invoice-header">
          <div className="invoice-logo font-nunito font-semibold mx-auto w-[100px] md:w-[551px] text-center text-18">
            <img src={shop_logo} alt={invoice.invoice_id} className="mx-auto" />
            <div>
              <p className="text-[20px]">
                <p>
                  {invoice?.user_info?.outlet_name} <br />
                  {invoice?.user_info?.address}
                  <br />
                  {formatPhoneNumber(invoice?.user_info?.phone, invoice?.user_info?.country_code)}
                </p>
              </p>
            </div>
          </div>
        </div> */}

        {/* invoice header */}
        <div className="flex justify-between items-center pt-4 pb-1 border-b-4 border-blue-300">

          {/* Shop Logo Section */}
          <div className="w-1/3 flex flex-col justify-start items-start">
            <img
              src={invoiceSetting.shop_logo}
              alt="Shop Logo"
              className="w-[40px] sm:w-[60px] md:w-[80px] object-contain"
            />
            <p className="mt-2"> {/* Adds some spacing between image and text */}
              <span className="font-semibold text-lg" style={{ letterSpacing: "0.08em" }}>Sale Invoice</span>
            </p>
          </div>

          {/* QR Code Section */}
          <div className="w-1/3 flex justify-center pb-2">
            <div className="w-[45px] sm:w-[65px] md:w-[100px]">
              {/* <QRCode
                className="w-full object-contain"
                value={BASE_URL + '/invoice/' + invoice?.data?.invoice_id}
                size={100}
              /> */}
              {type === "salesReturn" ? (
                <QRCode
                  className="w-full object-contain"
                  value={BASE_URL + "/sales-return/" + invoice.return_id}
                  size={100}
                />
                ) : (
                  <QRCode
                    className="w-full object-contain"
                    value={BASE_URL + "/purchase-return/" + invoice.return_id}
                    size={100}
                  />
                )
              }
            </div>
          </div>

          {/* Shop Info Section */}
          <div className="w-1/3 flex flex-col justify-end items-start">
            <span className="font-semibold text-lg sm:text-xl md:text-2xl mb-2" style={{ letterSpacing: "0.08em" }}>
              {invoiceSetting.shop_name}
            </span>
            <span className="text-sm sm:text-md text-gray-600">
              <strong>Phone:</strong> {invoice.user_info.phone}
            </span>
            <span className="text-sm sm:text-md text-gray-600">
              <strong>Email:</strong> {invoice.user_info.email}
            </span>
            <span className="text-sm sm:text-md text-gray-600">
              <strong>Address:</strong> {invoiceSetting.shop_address}
            </span>
          </div>
        </div>

        {/* customer info and QR */}
        <div className="invoice-customer flex justify-between  items-center mt-[10px]">
          <div className="text-black font-nunito text-sm leading-140 space-y-[2px] md:space-y-[6px] lg:text-base">
            {type === "salesReturn" ? (
              // <p className="mb-2">
              //   {" "}
              //   <span className="font-semibold">Customer Name</span> :{" "}
              //   {invoice?.sales?.customer_name}{" "}
              // </p>
              <p> <span className='font-semibold'>Bill to,</span> <br /> <span className='font-semibold'>Customer Name </span> : {invoice?.sales?.customer_name} </p>
            ) : (
              <p className="mb-2">
                {" "}
                <span className="font-semibold">Vendor Name</span> :{" "}
                {invoice?.purchase?.vendor_name}{" "}
              </p>
            )}
            {type === "salesReturn" ? (
              <p className="">
                <span className="font-semibold">Customer Phone</span> :{" "}
                {invoice?.sales?.customers?.mobile_number ? invoice?.sales?.customers?.mobile_number : invoice?.sales?.customer_phone}
              </p>
            ) : (
              <p className="mb-2">
                <span className="font-semibold">Vendor Phone</span> :{" "}
                {invoice?.sales?.vendor?.mobile_number}
              </p>
            )}
            {type === "salesReturn" ? (
              <p className="">
                <span className="font-semibold"> Customer ID </span> : #
                {invoice?.customer_id}
              </p>
            ) : (
              <p className="mb-2">
                <span className="font-semibold"> Vendor ID </span> : #
                {invoice?.vendor_id}
              </p>
            )}
            {/* <p className="mb-2">
              <span className="font-semibold"> Invoice Date </span>:{" "}
              {formattedDateTime}{" "}
            </p> */}
          </div>
          <div className=" flex flex-col justify-start items-start text-black space-y-[2px] md:space-y-[4px] font-nunito text-xs  lg:text-base">
            <p> <span className='font-semibold'>Return ID</span> : {invoice.return_id} </p>
            <p><span className='font-semibold'>Invoice Date</span> :  {formattedDateTime}</p>
          </div>
          {/* <div className="w-[100px]">
            {type === "salesReturn" ? (
              <QRCode
                className="w-full object-contain"
                value={BASE_URL + "/sales-return/" + invoice.return_id}
                size={100}
              />
            ) : (
              <QRCode
                className="w-full object-contain"
                value={BASE_URL + "/purchase-return/" + invoice.return_id}
                size={100}
              />
            )}
          </div> */}
        </div>

        {/* <div
          className="w-full bg-[#3056FA] px-[14px] py-[14px] ml-auto mr-auto mt-[15px] mb-[20px] flex justify-between items-center text-xs md:text-base lg:text-lg"
        >
          <p className="text-white font-nunito font-bold uppercase">
            RETURN INFO
          </p>
          <p className="text-white font-nunito font-bold uppercase">
            #{invoice.return_id}
          </p>
        </div> */}

        {/* <div className={`invoice-items max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto lg:w-full lg:max-w-full overflow-x-auto`}>
          <table className={`table ${'h-[600px]' ? 'overflow-x-scroll' : 'overflow-x-hidden'}`}> */}
          {/* table */}
        <div
          // className={`invoice-items max-w-[calc(100vw-28vw)] w-[calc(100vw-28vw)] mx-auto lg:w-full lg:max-w-full overflow-x-auto`}
          className=' mx-auto md:w-full md:max-w-full overflow-x-auto mt-4 mb-0 '
        >
          <table className=" table border-b-4 border-blue-300">
            <thead classname="">
              <tr className="leading-normal text-xs  lg:text-base font-nunito tracking-wide border-b-4 border-t-4 border-blue-300">
                <th className='font-nunito text-left' style={{ padding: '3px 5px' }}>#</th>
                <th className='font-nunito text-left' style={{ padding: '3px 5px' }}>
                  <span className="hidden md:inline-block">Return</span> Date
                </th>
                <th className='font-nunito text-start' style={{ padding: '3px 5px' }}>Product Name</th>
                <th className='font-nunito text-center' style={{ padding: '3px 5px' }}>Quantity</th>
                <th className='font-nunito text-center' style={{ padding: '3px 5px' }}>Purchased Amount</th>
                <th className='font-nunito text-right ' style={{ padding: '3px 5px' }}>Return Amount</th>
              </tr>
            </thead>
            <tbody>
              {/* <Watermark watermark_text={invoiceSetting?.watermark_text} watermark_type={invoiceSetting?.watermark_type}></Watermark> */}
              {/* {item && (
                <tr
                  key={item.id}
                  // className="text-blue-gray-500 font-nunito text-[16px] relative"
                  className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative"
                >
                  <td className="text-left " style={{padding: '10px' }} >{formattedDate}</td>
                  <td className="" style={{ padding: '10px' }}>
                    {invoice?.sales_details?.[0]?.product_info?.name}
                    <br />
                    <span className="text-[13px]">
                      {item?.product_info?.serial}
                    </span>
                  </td>
                  <td className="text-center " style={{ padding: '10px' }}>{item.return_qty}</td>
                  <td className="text-center " style={{ padding: '10px' }}>{formatPrice(subtotalPrice)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</td>
                  <td className="text-right font-semibold md:font-semibold text-sm" style={{padding: '10px' }}>
                    {formatPrice(invoice.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
                  </td>
                </tr>
              )} */}
              {(() => {
                let rowCounter = 0;

                return invoice?.sales_details?.map((item) => {
                  const productId = item.product_id;

                  // Skip if the product_id has already been processed
                  if (processedProductIds.has(productId)) {
                    return null;
                  }

                    let subtotalPrice = 0;
                    subtotalPrice = formatPrice(item.quantity * item.purchase_price); // Calculate subtotal price for the product
                    // totalQty += Number(item.quantity); // Add the quantity of the product
                    totalItems += item.quantity; // Count this product as a single item
                    totalPrice += Number(subtotalPrice); // Add to the total price
                  //}

                  // Render rows for IMEIs or the regular item row
                  // return imeisDetails?.length
                  //   ? imeisDetails?.map((imei) => {
                  //       rowCounter += 1; // Increment for each IMEI row

                  //       return (
                  //         <tr key={imei.id} className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative">
                  //           <td className="text-left " style={{padding: '10px' }} >{rowCounter}</td> {/* SL # */}
                  //           <td className="text-left " style={{padding: '10px' }} >{formattedDate}</td>
                  //           <td className="" style={{ padding: '10px' }}>{imei?.product_name} {imei?.optional_name}<br /><span className="text-[13px]">{imei?.imei}</span></td>
                  //           <td className="text-center text-sm" style={{padding: '10px' }}>{"1"}</td>
                  //           <td className="text-right text-sm" style={{ padding: '10px' }}>{imei.sale_price}</td>
                  //           <td className="text-right font-semibold md:font-semibold text-sm" style={{padding: '10px' }}>
                  //             {imei.sale_price}{invoiceSetting?.currency_info?.code || ' BDT'}
                  //           </td>
                  //         </tr>
                  //       );
                  //     })
                    return (
                      <tr key={item.id} className="font-nunito border-[#E7EBF4] font-normal text-sm lg:text-base relative">
                        <td className="text-left " style={{ padding: '10px' }}>{++rowCounter}</td> {/* SL # */}
                        <td className="text-left " style={{padding: '10px' }} >{formattedDate}</td>
                        <td className="" style={{ padding: '10px' }}>{item.product_info?.name}</td>
                        <td className="text-center text-sm" style={{  padding: '10px' }}>{item.product_info?.quantity}</td>
                        <td className="text-center text-sm" style={{ padding: '10px' }}>{item.product_info?.purchase_price}</td>
                        <td className="text-right font-semibold md:font-semibold text-sm" style={{  padding: '10px' }}>
                          {subtotalPrice}{invoiceSetting?.currency_info?.code || ' BDT'}
                        </td>
                      </tr>
                    );
                });
              })()}
            </tbody>
          </table>
        </div>

        {/* <br /> */}
        {/* disclaimer & summary */}
        <div className="w-full flex justify-between  pt-0 ml-auto ">
          {/* <div className="terms-condition text-[18px] flex-1 font-nunito">
            <p className="font-bold text-[20px]">Return Description:</p>
            <p className="font-[400] text-[20px]">{invoice?.return_description}</p>
          </div> */}
          {/* payment method */}
          <div style={styles.paymentMethodDiv}>
              <div>
                {invoice?.multiple_payment?.length > 0 ? (
                  <span>
                    Paid by {invoice.multiple_payment.map(payment => payment.payment_type.type_name).join(', ').replace(/, ([^,]*)$/, ' and $1')}
                  </span>
                ) : (
                  <span>No payment information available</span>
                )}
              </div>
          </div>
          {/* invoice sumary */}
          <div className="w-1/3 text-sm font-semibold  pl-12">
            <div className='space-y-[2px] mb-2'>
              {/* <p className='flex justify-between'>
                <span>Total Items</span>
                <span>{totalItems}</span>
              </p> */}
              <p className='flex justify-between'>
                <span className='pl-5'>Sub Total</span>
                <span>{invoice?.sales?.sub_total}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
              </p>
              {/* <p className='flex justify-between'>
                <span className='pl-5'>Discount</span>
                <span>{formatPrice(invoice.discount ?invoice.discount : 0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
              </p> */}
              {/* <p className='flex justify-between' >
                <span>Vat {''}</span>
                <span>{invoice.vat ? formatPrice(invoice.vat) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
              </p>
              <p className='flex justify-between'>
                <span>Service Charge {''}</span>
                <span>{invoice.tax ? formatPrice(invoice.tax) : 0.00}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
              </p> */}
            </div>
            <hr></hr>
            <div className='my-2'>
            <p className='flex justify-between'>
              <span className='pl-5'>Grand Total</span>
              <span>{formatPrice(invoiceCalculate(invoice).grandTotal) }{invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
              {/* <span>{Number.parseFloat(Number(totalPrice) + Number(invoice?.vat) + Number(invoice?.tax) + Number(invoice?.delivery_fee)) - Number(invoice?.discount).toFixed(2)}
              {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
            </p>
            <p className='flex justify-between'>
              <span className='pl-5'>Paid Amount</span>
              {/* <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(totalPrice)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
              <span>{invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              {type === "salesReturn" ? formatPrice(invoice?.sales?.paid_amount) : formatPrice(invoice?.purchase?.paid_amount) }
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
            </p>
            </div>
            <hr></hr>
            <p className='flex justify-between mt-2'>
              <span className='pl-5'>Return Amount</span>
              {/* <span>{formatPrice(invoice.sub_total - invoice.paid_amount)}{invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span>
              <span>{formatPrice(invoiceCalculate(invoice).dueAmount) }</span>
              <span>{ Number.parseFloat( ((Number(totalPrice) + Number(invoice?.vat) + Number(invoice?.tax) + Number(invoice?.delivery_fee)) - Number(invoice?.discount))-(Number(invoice.paid_amount && invoice.paid_amount!='' ? formatPrice(invoice.paid_amount): formatPrice(0)))).toFixed(2)}
              {invoiceSetting?.currency_info?.code ? invoiceSetting?.currency_info?.code : ' BDT'}</span> */}
              <span>
               {invoice?.return_amount}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>
            <p className='flex justify-between mt-2'>
              <span className='pl-5'>Due Amount</span>
              <span>
                <span>{formatPrice(item?.return_amount-subtotalPrice)}
                {invoice?.user_info?.invoiceSetting?.currency_info?.code
                    ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                    : " BDT"}</span>
              </span>
            </p>
          </div>
        </div>

          {/* <div className="invoice-summary flex-1 ">

            <p className="flex mt-2 justify-between font-nunito font-[600] text-[#24px] border-b-[1px] border-solid border-[#E7EBF4] py-[7px] mb-[7pxs]">
              <span>Total Items</span>
              <span>{totalQty}</span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>SUB TOTAL</span>
              <span>
                {formatPrice(subtotalPrice)}
                {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>
            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Grand Total</span>
              <span>
                {formatPrice(subtotalPrice)}
                {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Paid Amount</span>
              <span>
              {type === "salesReturn" ? formatPrice(invoice?.sales?.paid_amount) : formatPrice(invoice?.purchase?.paid_amount) }
              {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-2 justify-between text-navy-800 font-nunito font-semibold ">
              <span>Return Amount</span>
              <span>
               {formatPrice(item?.return_amount)}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
              </span>
            </p>

            <p className="flex mt-2 justify-between font-nunito font-[600] text-[#24px] border-t-[1px] border-solid border-[#E7EBF4] py-[7px] mb-[7pxs]">
              <span>Due Amount</span>
              <span>{formatPrice(item?.return_amount-subtotalPrice)}
               {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}</span>
            </p>

          </div> */}

        <p className="text-[#102048] mt-5 font-nunito text-sm md:text-base lg:text-lg font-[600] text-center mb-[25px]">
          <span className="text-[#7282A0]">In word:</span> {numberToWords(subtotalPrice)} taka only
        </p>

        {/* <div className="w-full bg-[#3056FA] px-[10px] py-[10px] ml-auto mr-auto mt-[15px] mb-[20px] text-xs md:text-base lg:text-lg text-white text-center font-bold font-nunito">
          You have returned {formatPrice(item?.return_amount)} {invoice?.user_info?.invoiceSetting?.currency_info?.code
                  ? invoice?.user_info?.invoiceSetting?.currency_info?.code
                  : " BDT"}
        </div> */}
        <div
            style={{...styles.div }}
          >
            <p className="text-white font-nunito text-sm font-bold">
              You saved {formatPrice(invoice?.discount ? invoice?.discount : 0)}
              {/* You saved {invoice.discount ? invoice.discount : 0} */}
              {invoiceSetting?.currency_info?.code
                ? invoiceSetting?.currency_info?.code
                : " BDT"}{" "}
              in this purchase{" "}
            </p>
        </div>
        {/* web address qr code */}
        <QRCode
                    className="w-[70px] object-contain  p-0"
                    value={invoice?.data?.user_info?.invoice_settings?.web_address || ''}
                    size={70}
        />

        {/* <div>
          <div className="flex justify-between">
            <p className="invisible">.</p>
            {invoiceSetting?.sign_authority && (
              <div className="w-[120px] h-[120px] rounded-lg">
              </div>
            )}
          </div>
          <div className="flex justify-between m-2 font-nunito font-[600] text-[20px]">
            <p>
              <hr></hr> Customer Signature
            </p>
            <p>
              <hr></hr> Seller Signature{" "}
            </p>
          </div>
        </div> */}
        {/* Terms and Conditions Section */} {/* Signature Section */}
        <div className="flex space-x-4 text-xs md:text-sm lg:text-base">
          <div className=" pt-2 w-1/2">
              <h3 className="text-sm font-semibold text-gray-800 mb-2">Terms and Conditions</h3>
              <ul className="list-disc list-inside text-gray-700 text-xs">
                  {/* <li>All prices include taxes. Payment must be made in full at the time of purchase via accepted payment methods.</li>
                  <li>Disputes will be resolved under the laws of Bangladesh.</li>
                  <li>Defective or incorrect items will be replaced or refunded at no additional cost.</li> */}
                  {inputs?.map((item, index) => (
                    <li key={item.id || index}>{item.description}</li>
                  ))}
              </ul>
          </div>
          <div className=" p-4 flex flex-col items-end w-1/2">
              <div className="flex justify-end mt-2">
                  <p><hr /> Authorized Signature </p>
              </div>
          </div>
        </div>

        <div className="text-center font-nunito mb-[70px]">
          <h2 className="text-[#FF6C02] text-xl font-nunito font-[600]">
            Thanks for purchase
          </h2>
          <p className="text-black mt-1 font-nunito text-xs ">
            A Product of SQUAD INNOVATORS{" "}
          </p>
        </div>
      </div>


      <div style={{ display: "none" }}>
        <PdfHtmlContent
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoiceSetting}
          type={type}
          inputs={inputs}
        />
      </div>
      <div style={{ display: "none" }}>
        <PdfHtmlContent3mm
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoiceSetting}
          type={type}
        />
      </div>
      <div style={{ display: "none" }}>
        <PdfHtmlContent2mm
          shop_logo={shop_logo}
          invoice={invoice}
          invoiceSetting={invoiceSetting}
          type={type}
        />
      </div>
      {/* old download button */}
      {/* <div className='mx-auto text-center'>
        <button onClick={() => handleGeneratePdf()} className="border text-white bg-purple-500 p-3 m-3">
          Generate Pdf
        </button>

      </div> */}
      {/* new pdf and print buttons */}
      <div className="flex gap-12 justify-between text-xs md:text-sm lg:text-base mt-6">
        {/* pdf buttons */}
        <div
          className={`flex-1 transform duration-300 ${
            dropdownPdf ? "!h-[270px] md:!h-[300px]" : "h-[90px]"
          } space-y-2 relative`}
        >
          {/* pdf drop down button */}
          <button
            onClick={() => setDropdownPdf(!dropdownPdf)}
            className=" flex justify-center gap-1 md:gap-3 items-center bg-[#f1effc] text-[#034AFF] absolute z-10 !px-1 py-3 w-full rounded-xl font-bold shadow-md"
          >
            <span>
              <FaCloudDownloadAlt size={22} />
            </span>{" "}
            Download PDF{" "}
            <div
              className={`transform duration-300 ${
                dropdownPdf ? "-rotate-180" : ""
              }`}
            >
              {" "}
              <FaChevronDown size={16} />
            </div>
          </button>
          {/* child buttons */}
          <button
            onClick={() => handleGeneratePdf("a4", 1)}
            className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3  items-center justify-center ${
              dropdownPdf
                ? "translate-y-[3.7rem] "
                : "-translate-y-[0.6rem] opacity-0"
            } hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
          >
            <FaCloudDownloadAlt size={22} /> <span>Download A4 PDF</span>
          </button>
          <button
            onClick={() => handleGeneratePdf("3mm", 2)}
            className={`text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${
              dropdownPdf
                ? "translate-y-[3.7rem] "
                : "-translate-y-[4.8rem] opacity-0"
            }  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
          >
            <FaCloudDownloadAlt size={22} /> <span>Download 3mm PDF</span>
          </button>
          <button
            onClick={() => handleGeneratePdf("2mm", 3)}
            className={` text-[#034AFF] font-semibold flex gap-1 md:gap-3 items-center justify-center ${
              dropdownPdf
                ? "translate-y-[3.7rem] "
                : "-translate-y-[8.4rem] opacity-0"
            } border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
          >
            <FaCloudDownloadAlt size={22} /> <span>Download 2mm PDF</span>
          </button>
        </div>
        {/* print buttons */}
        <div
          className={`flex-1 space-y-2 transform duration-300 ${
            dropdownPdf || dropdownPrint
              ? "!h-[270px] md:!h-[300px]"
              : "h-[90px]"
          } relative`}
        >
          {/* print drop down button */}
          <button
            onClick={() => setDropdownPrint(!dropdownPrint)}
            className=" bg-[#f1effc] flex justify-center items-center gap-1 md:gap-3 text-[#034AFF] font-bold shadow-md absolute z-10 !px-1 py-3 w-full rounded-xl"
          >
            <span>
              <IoPrint size={22} />
            </span>{" "}
            Print{" "}
            <div
              className={`transform duration-300 ${
                dropdownPrint ? "-rotate-180" : ""
              }`}
            >
              {" "}
              <FaChevronDown className="text-base md:text-lg" />
            </div>
          </button>
          {/* child buttons */}

          <button
            onClick={() => {
              handlePrint("pdfConentAreas");
            }}
            className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
              dropdownPrint
                ? "translate-y-[3.7rem] "
                : "-translate-y-[0.6rem] opacity-0"
            } hover:text-[#eaeffb] hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300  py-3 w-full  rounded-xl`}
          >
            <IoPrint size={22} /> <span>Print A4</span>
          </button>

          <button
            onClick={() => {
              handlePrint("pdfConentAreas3");
            }}
            className={`flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
              dropdownPrint
                ? "translate-y-[3.7rem] "
                : "-translate-y-[4.8rem] opacity-0"
            }  hover:bg-[#034AFF] border hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
          >
            <IoPrint size={22} /> <span>Print 3 inch</span>
          </button>

          <button
            onClick={() => {
              handlePrint("pdfConentAreas2");
            }}
            className={` flex gap-1 md:gap-3 items-center justify-center text-[#034AFF] font-semibold  ${
              dropdownPrint
                ? "translate-y-[3.7rem] "
                : "-translate-y-[8.4rem] opacity-0"
            } border hover:bg-[#034AFF] hover:border-[1px_solid_#D2CDFF] transform duration-300 hover:text-[#eaeffb] py-3 w-full  rounded-xl`}
          >
            <IoPrint size={22} /> <span>Print 2 inch</span>
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  div: {
    backgroundColor: '#407BFF',
    color: 'white',
    WebkitPrintColorAdjust: 'exact',
    padding: "10px 10px 10px 10px",
    textAlign: "center",
    margin: '13px 0px 10px 0px',
  },
  paymentMethodDiv: {
    transform: 'rotate(-45deg)',
    padding: '10px',
    textAlign: 'center',
    // backgroundColor: '#407BFF',
    color: 'black',
    // WebkitPrintColorAdjust: 'exact',
    margin: '30px 30px 20px 180px',
    maxWidth: '120px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: '13px'
  },
  paymentMethodPrintDiv: {
    transform: 'rotate(-45deg)',
    padding: '10px',
    textAlign: 'center',
    // backgroundColor: '#407BFF',
    color: 'black',
    // WebkitPrintColorAdjust: 'exact',
    margin: '30px 30px 20px 180px',
    maxWidth: '120px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    fontSize: '10px'
  },
};

export default ReturnDetail;
