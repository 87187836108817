import axios from "axios";
import { useState } from "react";
import DialCodes from '../data/dialCodes.json';


export function timeDiff(givenDate) {
  const currentDate = new Date();
  const timeDiff = currentDate - givenDate;
  const seconds = Math.floor(timeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (seconds === 1) {
    return `${seconds} second ago`;
  } else if (seconds > 1 && seconds < 60) {
    return `${seconds} seconds ago`;
  } else if (minutes === 1) {
    return `${minutes} minute ago`;
  } else if (minutes > 1 && minutes < 60) {
    return `${minutes} minutes ago`;
  } else if (hours === 1) {
    return `${hours} hour ago`;
  } else if (hours > 1 && hours < 24) {
    return `${hours} hours ago`;
  } else if (days === 1) {
    return `${days} day ago`;
  } else if (days > 1 && days < 30) {
    return `${days} days ago`;
  } else {
    return givenDate.toLocaleDateString();
  }
}
export function formatPhoneNumber(phone, countryCode) {
  if (countryCode && countryCode !== 'null' && countryCode !=='undefined' && countryCode !=='') {
    return phone.startsWith('+880') ? phone : countryCode + phone;
  } else {
    return phone;
  }


}
export function formatDate(date) {
  if (!date) return '';
  const dateObj = new Date(date);
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const month = monthNames[dateObj.getMonth()];
  const day = String(dateObj.getDate()).padStart(2, '0');
  const year = dateObj.getFullYear();

  return `${day} ${month}, ${year}`;
}



export const uploadFile = (
  files,
  successCallback,
  errorCallback,
  progressCallback
) => {

  if (!files || files.length === 0) {
    // No files to upload, call the success callback directly with an empty array
    successCallback([]);
    return;
  }

  const tempFileArray = []; // Now an array of objects
  const tempUploadDetails = [];

  const uploadFileAtIndex = (index) => {
    if (index === files.length) {
      // All files have been uploaded
      successCallback(tempFileArray);
      return;
    }
    if (!files[index] || files[index].length === 0) {
      // No files to upload, call the success callback directly with an empty array
      successCallback([]);
      return;
    }

    const data = new FormData();
    data.append('file_name', files[index]);
    const token = localStorage.getItem("token");
    // tempFileArray.push({
    //   input_name : input_name,
    //   path : 'https://api-outlet-expense.squadinnovators.com/uploads/29-tahmim/1699369076.png'
    // });
    //uploadFileAtIndex(index + 1);
    axios
      .post(process.env.REACT_APP_API_URL + 'file-upload', data, {
        headers: {
          authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          tempUploadDetails.push({
            name: files[index].name,
            size: `${(files[index].size / 1024).toFixed(2)}KB`,
            percentage: percent,
          });
          progressCallback(tempUploadDetails);
        },
      })
      .then(function (response) {
        console.log('executing single');

        if (response.data.status === 200) {
          tempFileArray.push(response.data.path);
        }
        uploadFileAtIndex(index + 1); // Upload the next file
      })
      .catch(function (error) {
        errorCallback(error);
      });
  };

  uploadFileAtIndex(0); // Start uploading the first file
};



export const uploadFileMultipleInput = (fileData, successCallback, errorCallback, progressCallback) => {
  if (fileData.length === 0) {
    // No files to upload, call the success callback directly
    successCallback([]);
    return;
  }

  const tempFileArray = []; // Now an array of objects
  const tempUploadDetails = [];

  const uploadFileAtIndex = (index) => {
    if (index === fileData.length) {
      // All files have been uploaded
      successCallback(tempFileArray);
      return;
    }

    const { file, input_name } = fileData[index];
    const data = new FormData();
    data.append('file_name', file);
    const token = localStorage.getItem("token");
    // tempFileArray.push({
    //   input_name : input_name,
    //   path : 'https://api-outlet-expense.squadinnovators.com/uploads/29-tahmim/1699369076.png'
    // });
    //uploadFileAtIndex(index + 1);
    axios
      .post(process.env.REACT_APP_API_URL + 'file-upload', data, {
        headers: {
          authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          tempUploadDetails.push({
            name: file.name,
            size: `${(file.size / 1024).toFixed(2)}KB`,
            percentage: percent,
            input_name: input_name, // Associate with input_name
          });
          progressCallback(tempUploadDetails);
        },
      })
      .then(function (response) {
        console.log('executing multi');
        if (response.data.status === 200) {
          tempFileArray.push({
            path: response.data.path,
            input_name: input_name, // Associate with input_name
          });
        }
        uploadFileAtIndex(index + 1); // Upload the next file
      })
      .catch(function (error) {
        errorCallback(error);
      });
  };

  uploadFileAtIndex(0); // Start uploading the first file
};


export const uploadFileMultipleInputVariants = async (fileData,name) => {
  let tempFileArray ={};
  // tempFileArray ={
  //   path: 'https://www.outletexpense.xyz/uploads/3-Evan-Khan/1706026910.jpg',
  //   input_name: name, // Associate with input_name
  // };
  // return tempFileArray;
  if (fileData.length === 0) {
    // No files to upload, call the success callback directly
    //successCallback([]);
    return;
  }

  //tempFileArray = {}; // Now an array of objects
  const tempUploadDetails = [];

    const existingData = fileData.find(
      (variant) => variant.input_name === name
    );

    const { file, input_name } = existingData ? existingData : fileData[0];
    const data = new FormData();
    data.append('file_name', file);
    const token = localStorage.getItem("token");
    //return tempFileArray;
    return new Promise((resolve, reject) => {
      axios
        .post(process.env.REACT_APP_API_URL + 'file-upload', data, {
          headers: {
            authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(function (response) {
          if (response.data.status === 200) {
            resolve({
              path: response.data.path,
              input_name: name,
            });
          } else {
            reject(new Error('Upload failed'));
          }
        })
        .catch(function (error) {
          reject(error);
        });
    });
      // Return null or any default value if the API call fails or the response doesn't match the desired conditions
     // return null;

  //return uploadFileAtIndex(0); // Start uploading the first file
};


export const numberToWords = inputNumber => {
    var str = new String(Math.round(inputNumber))
    var splt = str.split("");
    var rev = splt.reverse();
    var once = ['Zero', ' One', ' Two', ' Three', ' Four', ' Five', ' Six', ' Seven', ' Eight', ' Nine'];
    var twos = ['Ten', ' Eleven', ' Twelve', ' Thirteen', ' Fourteen', ' Fifteen', ' Sixteen', ' Seventeen', ' Eighteen', ' Nineteen'];
    var tens = ['', 'Ten', ' Twenty', ' Thirty', ' Forty', ' Fifty', ' Sixty', ' Seventy', ' Eighty', ' Ninety'];
    let i;
    const numLength = rev.length;
    var word = new Array();
    var j = 0;

    for (i = 0; i < numLength; i++) {
        switch (i) {

            case 0:
                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                    word[j] = '';
                }
                else {
                    word[j] = '' + once[rev[i]];
                }
                word[j] = word[j];
                break;

            case 1:
                aboveTens();
                break;

            case 2:
                if (rev[i] == 0) {
                    word[j] = '';
                }
                else if ((rev[i - 1] == 0) || (rev[i - 2] == 0)) {
                    word[j] = once[rev[i]] + " Hundred ";
                }
                else {
                    word[j] = once[rev[i]] + " Hundred and";
                }
                break;

            case 3:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = '';
                }
                else {
                    word[j] = once[rev[i]];
                }
                if ((rev[i + 1] != 0) || (rev[i] > 0)) {
                    word[j] = word[j] + " Thousand";
                }
                break;


            case 4:
                aboveTens();
                break;

            case 5:
                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                    word[j] = '';
                }
                else {
                    word[j] = once[rev[i]];
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + " Lakh";
                }

                break;

            case 6:
                aboveTens();
                break;

            case 7:
                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
                    word[j] = '';
                }
                else {
                    word[j] = once[rev[i]];
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + " Crore";
                }
                break;

            case 8:
                aboveTens();
                break;

            //            This is optional.

            //            case 9:
            //                if ((rev[i] == 0) || (rev[i + 1] == 1)) {
            //                    word[j] = '';
            //                }
            //                else {
            //                    word[j] = once[rev[i]];
            //                }
            //                if (rev[i + 1] !== '0' || rev[i] > '0') {
            //                    word[j] = word[j] + " Arab";
            //                }
            //                break;

            //            case 10:
            //                aboveTens();
            //                break;

            default: break;
        }
        j++;
    }

    function aboveTens() {
        if (rev[i] == 0) { word[j] = ''; }
        else if (rev[i] == 1) { word[j] = twos[rev[i - 1]]; }
        else { word[j] = tens[rev[i]]; }
    }

    word.reverse();
    var finalOutput = '';
    for (i = 0; i < numLength; i++) {
        finalOutput = finalOutput + word[i];
    }
    return finalOutput;
    //document.getElementById(outputControl).innerHTML = finalOutput;
}


export const formatNumber = (number) => {
  if (!number) return '';
  number = Number(number);
  return number.toLocaleString('en-IN', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export const validateData = (value, fieldName='Shop name',required=true) => {
  if (required && !value) {
    return 'This field cannot be empty.';
  }

  // Check if the value contains only numbers
  if (value && /^\d+$/.test(value)) {
    return fieldName +' cannot contain only numbers.';
  }

  // Check if the value contains only emojis
  if (value && value.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/) !== null) {
    return fieldName +' cannot contain only emojis.';
  }

  // Check if the value contains only special characters
  if (value && /^[!@#$%^&*(),.?":{}|<>]+$/.test(value)) {
    return fieldName +' cannot contain only special characters.';
  }

  // Validation passed
  return undefined;
};

export const eclipse = (str, length) => {
  return str && str.length > 0 &&  str.length > length ? str.substring(0, length) + "..." : str;
}

export function formatPrice(price) {
  // Check if the price is a valid number
  if (typeof price !== 'number' || isNaN(price)) {
    return '0';
  }
  //price = -23134;

  // Get the absolute value of the price
  //const absolutePrice = Math.abs(price);

  // Format the absolute price with two decimal places
  const formattedPrice = Number.parseFloat(price).toFixed(2);

  return formattedPrice;
}


export const getDialCodeByCountryCode = (code) => {
  const country = DialCodes.find((item) => item.code === code);
  return country ? country.dial_code : '';
};

export const useStateArray = (initialState) => {
  const [state, setState] = useState(initialState);
  //set, push, removeAt, clear, updateAt, update, remove, add
  const set = (value) => {
    setState(value);
  };
  const push = (value) => {
    setState([...state, value]);
  };
  const removeAt = (index) => {
    const temp = [...state];
    temp.splice(index, 1);
    setState(temp);
  };
  const clear = () => {
    setState([]);
  };
  const updateAt = (index, value) => {
    const temp = [...state];
    temp[index] = value;
    setState(temp);
  };
  const update = (oldValue, newValue) => {
    const temp = [...state];
    const index = temp.indexOf(oldValue);
    temp[index] = newValue;
    setState(temp);
  };
  const remove = (value) => {
    const temp = [...state];
    const index = temp.indexOf(value);
    temp.splice(index, 1);
    setState(temp);
  };
  const add = (value) => {
    setState([...state, value]);
  };
  return [state, { set, push, removeAt, clear, updateAt, update, remove, add }];
}

export const scrollUp = ()=>{
  window.scrollTo(
      {
          top:0,
          behavior:"smooth"
      }
  )
}
export const subTotalCalculate = (data) => {
  let total = 0;
  let totalQty = 0;
  data.map((item) => {
    totalQty += Number(item.qty);
    const subtotalPrice = formatPrice(item.qty * item.price);
    total += Number(subtotalPrice);
  });
  return  {
    'sub_total': total,
    'subtotalQty': totalQty
  }
}

export const invoiceCalculate = (data,type='sales') => {
  let subTotal = 0;
  let totalQty = 0;
  let totalItems = 0;
  const items = type === 'sales' ? data?.sales_details : data?.purchase_details;


  items?.map((item) => {
    totalQty += Number(item?.qty);
    totalItems += 1;
    const subtotalPrice = formatPrice(item?.qty * item.price);
    subTotal += Number(subtotalPrice);
  });
  const vat = data?.vat || 0;
  const tax = data?.tax || 0;
  const discount = data?.discount || 0;
  const deliveryFee = data?.delivery_fee || 0;
  const paidAmount = data?.paid_amount || 0;
  const grandTotalWithoutDiscount = Number(subTotal) + Number(vat) + Number(tax) + Number(deliveryFee);

  const grandTotal = Number(subTotal) + Number(vat) + Number(tax) + Number(deliveryFee) - Number(discount);
  const dueAmount = grandTotal - Number(paidAmount);
  return  {
    'totalQty': totalQty,
    'totalItems': totalItems,
    'subTotal': subTotal,
    'vat': Number(vat),
    'tax': Number(tax),
    'grandTotalWithoutDiscount': grandTotalWithoutDiscount,
    'discount': Number(discount),
    'grandTotal': grandTotal,
    'paidAmount': Number(paidAmount),
    'dueAmount': dueAmount,
    'deliveryFee': Number(deliveryFee)
  }
}

export const invoiceCalculateWithCurrentVatTax = (data, invoiceSetting, type='sales') => {
  console.log("common Function Folder", data, invoiceSetting);
  let subTotal = 0;
  let totalQty = 0;
  let totalItems = 0;
  const items = type === 'sales' ? data?.sales_details : data?.purchase_details;


  items.map((item) => {
    totalQty += Number(item?.remaining_qty !== null && item?.remaining_qty !== undefined ? item?.remaining_qty : item?.qty);
    totalItems += 1;
    const subtotalPrice = formatPrice(item?.remaining_qty !== null && item?.remaining_qty !== undefined ? item?.remaining_qty : item?.qty * item.price);
    subTotal += Number(subtotalPrice);
  });

  const vat = invoiceSetting?.vat && invoiceSetting?.vat > 0 ? (invoiceSetting?.vat / 100) * subTotal : 0;
  const tax = invoiceSetting?.tax && invoiceSetting?.tax > 0 ? (invoiceSetting?.tax / 100) * subTotal : 0;
  const discount = data?.discount || 0;
  const deliveryFee = data?.delivery_fee || 0;
  const paidAmount = data?.paid_amount || 0;
  const grandTotalWithoutDiscount = Number(subTotal) + Number(vat) + Number(tax) + Number(deliveryFee);

  const grandTotal = Number(subTotal) + Number(vat) + Number(tax) + Number(deliveryFee) - Number(discount);
  const dueAmount = grandTotal - Number(paidAmount);
  console.log("price", subTotal, vat, tax);
  return  {
    'totalQty': totalQty,
    'totalItems': totalItems,
    'subTotal': subTotal,
    'vat': Number(vat),
    'tax': Number(tax),
    'grandTotalWithoutDiscount': grandTotalWithoutDiscount,
    'discount': Number(discount),
    'grandTotal': grandTotal,
    'paidAmount': Number(paidAmount),
    'dueAmount': dueAmount,
    'deliveryFee': Number(deliveryFee)
  }
}
