// packages
import React, { createContext, useReducer } from "react";
// Configs and store setup
import config from '../config';
// assets and components
export const AppContext = createContext();

const initialState = {
  token: '',
  searchKeyword: '',
  isLoading: false,
  categoryList: [],
  shopCategoryList: [],
  singleCategoryList: [],
  subCategoryList: [],
  brandList: [],
  branchList:[],
  unitList: [],
  paymentList: [],
  deliveryList: [],
  paymentAccountList: [],
  deliveryAccountList: [],
  warrantyList: [],
  invoiceList: [],
  holdInvoiceList: [],
  wholesaleHoldInvoiceList:[],
  wholesaleInvoiceList:[],
  salesReturnList: [],
  purchaseReturnList: [],
  singleInvoiceList: [],
  purchaseInvoiceList: [],
  advanceBookingList: [],
  currentUser: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
  invoiceSetting:localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).invoice_settings : null,
  countryList: [],
  branchList:[],
  bloodGroups: [],
  nextPage: null,
  currentPage: null,
  customerLists:[],
  wholesalerLists:[],
  vendorLists:[],
  exporterOrderLists:[],
  exporterLists:[],
  carrierLists:[],
  productList: [],
  paperSize: 'A4',
  customerList: [{ id: '', name: 'Select' }],
  exporterList: [{ id: '', name: 'Select' }],
  vendorList: [{ id: '', name: 'Select' }],
  wholeSalerList:[{ id: '', name: 'Select' }],
  activeMenu:"",
  customerDueInvoiceList: [],
  saleInvoiceIdForDue: "",
  vendorDueInvoiceList: [],
  purchaseInvoiceIdForDue: "",
  exporterDueList: [],
  exporterInvoiceIdForDue: "",
  carrierDueInvoiceList: [],
  carrierInvoiceIdForDue: "",
  wholesellerDueInvoiceList: [],
  wholesellerInvoiceIdForDue: "",
  duePaymentCustomDate: ""
};

const createActions = (dispatch) => ({
  loading: (data) => {
    dispatch({ type: "LOADING", loadingState: data });
  },
  updateSearchKeyword: (data) => {
    dispatch({ type: "UPDATE_SEARCH_KEYWORD", newData: data });
  },
  updateCategoryList: (data) => {
    dispatch({ type: "UPDATE_CATEGORY_LIST", newCategoryData: data });
  },
  setShopCategoryList: (data) => {
    dispatch({ type: "UPDATE_SHOP_CATEGORY_LIST", newData: data });
  },
  updateSingleCategoryList: (data) => {
    dispatch({ type: "UPDATE_SINGLE_CATEGORY_LIST", newCategoryData: data });
  },
  updateFilteredCustomer: (data) => {
    dispatch({ type: "UPDATE_CUSTOMER_LIST", newCustomerData: data });
  },
  updateFilteredExporter: (data) => {
    dispatch({ type: "UPDATE_EXPORTER_LIST", newData: data });
  },
  updateFilteredProduct: (data) => {
    dispatch({ type: "UPDATE_PRODUCT_LIST", newData: data });
  },
  updateFilteredWholeSaler: (data) => {
    dispatch({ type: "UPDATE_WHOLESALER_LIST", newData: data });
  },
  updateFilteredVendor: (data) => {
    dispatch({ type: "UPDATE_VENDOR_LIST", newVendorData: data });
  },
  updateSubCategoryList: (data) => {
    dispatch({ type: "UPDATE_SUB_CATEGORY_LIST", newSubCategoryData: data });
  },
  updateBrandList: (data) => {
    dispatch({ type: "UPDATE_BRAND_LIST", newBrandData: data });
  },
  updateBranchList: (data) => {
    dispatch({ type: "UPDATE_BRANCH_LIST", newBranchData: data });
  },
  updateUnitList: (data) => {
    dispatch({ type: "UPDATE_UNIT_LIST", newData: data });
  },
  updatePaymentList: (data) => {
    dispatch({ type: "UPDATE_PAYMENT_LIST", newData: data });
  },
  updatePaymentAccountList: (data) => {
    dispatch({ type: "UPDATE_PAYMENT_ACCOUNT_LIST", newData: data });
  },
  updateDeliveryList: (data) => {
    dispatch({ type: "UPDATE_DELIVERY_LIST", newData: data });
  },
  updateWarrantyList: (data) => {
    dispatch({ type: "UPDATE_WARRANTY_LIST", newData: data });
  },
  updateDeliveryAccountList: (data) => {
    dispatch({ type: "UPDATE_DELIVERY_ACCOUNT_LIST", newData: data });
  },
  updateInvoiceList: (data) => {
    dispatch({ type: "UPDATE_INVOICE_LIST", newData: data });
  },
  updateHoldInvoiceList: (data) => {
    dispatch({ type: "UPDATE_HOLD_INVOICE_LIST", newData: data });
  },
  updateWholesaleInvoiceList: (data) => {
    dispatch({ type: "UPDATE_WHOLESALE_INVOICE_LIST", newData: data });
  },
  updateWholesaleHoldInvoiceList: (data) => {
    dispatch({ type: "UPDATE_WHOLESALE_HOLD_INVOICE_LIST", newData: data });
  },
  updateCustomerList: (data) => {
    dispatch({ type: "UPDATE_CUSTOMER_LISTS", newData: data });
  },
  updateProductList: (data) => {
    dispatch({ type: "UPDATE_PRODUCT_LISTS", newData: data });
  },
  updateEmployeeList: (data) => {
    dispatch({ type: "UPDATE_EMPLOYEE_LISTS", newData: data });
  },
  updateDepartmentList: (data) => {
    dispatch({ type: "UPDATE_DEPARTMENT_LISTS", newData: data });
  },
  updateDesignationList: (data) => {
    dispatch({ type: "UPDATE_DESIGNATION_LISTS", newData: data });
  },
  updateRoleList: (data) => {
    dispatch({ type: "UPDATE_ROLE_LISTS", newData: data });
  },
  updateWholesalerLists: (data) => {
    dispatch({ type: "UPDATE_WHOLESALER_LISTS", newData: data });
  },
  updateVendorLists: (data) => {
    dispatch({ type: "UPDATE_VENDOR_INVOICE_LIST", newData: data });
  },
  updateCarrierLists: (data) => {
    dispatch({ type: "UPDATE_CARRIER_LISTS", newData: data });
  },
  updateExporterLists: data => {
    dispatch({ type: 'UPDATE_EXPORTER_LISTS', newData: data });
  },
  updateSalesReturnList: data => {
    dispatch({ type: 'UPDATE_SALES_RETURN_LIST', newData: data });
  },
  updateExporterOrderLists: (data) => {
    dispatch({ type: "UPDATE_EXPORTER_ORDER_LIST", newData: data });
  },
  updateSalesReturnList: (data) => {
    dispatch({ type: "UPDATE_SALES_RETURN_LIST", newData: data });
  },
  updatePurchaseReturnList: (data) => {
    dispatch({ type: "UPDATE_PURCHASE_RETURN_LIST", newData: data });
  },
  updateSingleInvoiceList: (data) => {
    dispatch({ type: "UPDATE_SINGLE_INVOICE_LIST", newData: data });
  },
  updatePurchaseInvoiceList: (data) => {
    dispatch({ type: "UPDATE_PURCHASE_INVOICE_LIST", newData: data });
  },
  updateAdvanceBookingList: (data) => {
    dispatch({ type: "UPDATE_ADVANCE_BOOKING_LIST", newData: data });
  },
  setCurrentUser: (data) => {
    dispatch({ type: "CURRENT_USER_SET", payload: data });
  },
  updateInvoiceSetting: (data) => {
    // Retrieve the user object from localStorage
    const user = JSON.parse(localStorage.getItem("user"));

    // Update the invoice_settings property with the new data
    user.invoice_settings = data;

    // Save the updated user object back to localStorage
    localStorage.setItem("user", JSON.stringify(user));

    // Dispatch an action to update the state with the new data
    dispatch({ type: "INVOICE_SETTING_UPDATE", payload: data });
  },
  updateCountryList: (data) => {
    dispatch({ type: "UPDATE_COUNTRY_LIST", payload: data });
  },
  updateBranchList: (data) => {
    dispatch({ type: "UPDATE_BRANCH_LIST", payload: data });
  },
  updateBloodGroups: (data) => {
    dispatch({ type: "UPDATE_BLOOD_GROUP_LIST", payload: data });
  },
  updateNextPage: (data) => {
    dispatch({ type: "UPDATE_NEXT_PAGE", payload: data });
  },
  updateCurrentPage: (data) => {
    dispatch({ type: "UPDATE_CURRENT_PAGE", payload: data });
  },
  setPaperSize: (data) => {
    dispatch({ type: "SET_PAPER_SIZE", payload: data });
  },
  setDeliveryMode: (data) => {
    dispatch({ type: "SET_DELIVERY_MODE", payload: data });
  },
  updateActiveMenu: (data) => {
    dispatch({ type: "UPDATE_ACTIVE_MENU", payload: data });
  },
  setCustomerDueInvoiceList: (data) => {
    dispatch({ type: "SET_CUSTOMER_DUE_INVOICE_LIST", payload: data});
  },
  setSaleInvoiceIdForDue: (data) => {
    dispatch({ type: "SET_SALE_INVOICE_ID_FOR_DUE", payload: data});
  },
  setVendorDueInvoiceList: (data) => {
    dispatch({type: "SET_VENDOR_DUE_INVOICE_LIST", payload: data});
  },
  setPurchaseInvoiceIdForDue: (data) => {
    dispatch({type: "SET_PURCHASE_INVOICE_ID_FOR_DUE", payload: data});
  },
  setExporterDueInvoiceList: (data) => {
    dispatch({type: "SET_EXPORTER_DUE_INVOICE_LIST", payload: data});
  },
  setExporterInvoiceIdForDue: (data) => {
    dispatch({type: "SET_EXPORTER_INVOICE_ID_FOR_DUE", payload: data});
  },
  setCarrierDueInvoiceList: (data) => {
    dispatch({type: "SET_CARRIER_DUE_INVOICE_LIST", payload: data});
  },
  setCarrierInvoiceIdForDue: (data) => {
    dispatch({type: "SET_CARRIER_INVOICE_ID_FOR_DUE", payload: data});
  },
  setWholesellerDueInvoiceList: (data) => {
    dispatch({type: "SET_WHOLESELLER_DUE_INVOICE_LIST", payload: data});
  },
  setWholesellerInvoiceIdForDue: (data) => {
    dispatch({type: "SET_WHOLESELLER_INVOICE_ID_FOR_DUE", payload: data});
  },
  setDuePaymentCustomDate: (data) => {
    dispatch({type: "SET_DUE_PAYMENT_CUSTOM_DATE", payload: data});
  },
});


const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        isLoading: action.loadingState,
      };
    case "UPDATE_SEARCH_KEYWORD":
      return {
        ...state,
        searchKeyword: action.newData,
      };
    case "UPDATE_CATEGORY_LIST":
      return {
        ...state,
        categoryList: action.newCategoryData,
      };
    case "UPDATE_SHOP_CATEGORY_LIST":
      return {
        ...state,
        shopCategoryList: action.newData,
      };
    case "UPDATE_SINGLE_CATEGORY_LIST":
      return {
        ...state,
        singleCategoryList: action.newCategoryData,
      };
    case "UPDATE_CUSTOMER_LIST":
      return {
        ...state,
        customerList: action.newCustomerData,
      };
    case "UPDATE_EXPORTER_LIST":
      return {
        ...state,
        exporterList: action.newData,
      };
    case "UPDATE_PRODUCT_LIST":
      return {
        ...state,
        productList: action.newData,
      };
    case "UPDATE_WHOLESALER_LIST":
      return {
        ...state,
        wholeSalerList: action.newData,
      };
    case "UPDATE_VENDOR_LIST":
      return {
        ...state,
        vendorList: action.newVendorData,
      };
    case "UPDATE_EXPORTER_ORDER_LIST":
      return {
        ...state,
        exporterOrderLists: action.newData
      };
    case 'UPDATE_EXPORTER_LISTS':
      return {
        ...state,
        exporterLists: action.newData
      };
    case 'UPDATE_CARRIER_LISTS':
      return {
        ...state,
        carrierLists: action.newData
      };
    case 'UPDATE_SUB_CATEGORY_LIST':
      return {
        ...state,
        exporterOrderList: action.newData,
      };
    case "UPDATE_VENDOR_INVOICE_LIST":
      return {
        ...state,
        vendorLists: action.newData,
      };
    case "UPDATE_SUB_CATEGORY_LIST":
      return {
        ...state,
        subCategoryList: action.newSubCategoryData,
      };
    case "UPDATE_BRAND_LIST":
      return {
        ...state,
        brandList: action.newBrandData,
      };
    case "UPDATE_UNIT_LIST":
      return {
        ...state,
        unitList: action.newData,
      };
    case "UPDATE_PAYMENT_LIST":
      return {
        ...state,
        paymentList: action.newData,
      };
    case "UPDATE_PAYMENT_ACCOUNT_LIST":
      return {
        ...state,
        paymentAccountList: action.newData,
      };
    case "UPDATE_DELIVERY_LIST":
      return {
        ...state,
        deliveryList: action.newData,
      };
    case "UPDATE_DELIVERY_ACCOUNT_LIST":
      return {
        ...state,
        deliveryAccountList: action.newData,
      };
    case "UPDATE_WARRANTY_LIST":
      return {
        ...state,
        warrantyList: action.newData,
      };
    case "UPDATE_INVOICE_LIST":
      return {
        ...state,
        invoiceList: action.newData,
      };
    case "UPDATE_CUSTOMER_LISTS":
      return {
        ...state,
        customerLists: action.newData,
      };
    case "UPDATE_WHOLESALER_LISTS":
      return {
        ...state,
        wholesalerLists: action.newData,
      };
    case "UPDATE_HOLD_INVOICE_LIST":
      return {
        ...state,
        holdInvoiceList: action.newData,
      };
    case "UPDATE_WHOLESALE_INVOICE_LIST":
      return {
        ...state,
        wholesaleInvoiceList: action.newData,
      };
    case "UPDATE_WHOLESALE_HOLD_INVOICE_LIST":
      return {
        ...state,
        wholesaleHoldInvoiceList: action.newData,
      };
    case "UPDATE_SALES_RETURN_LIST":
      return {
        ...state,
        salesReturnList: action.newData,
      };
    case "UPDATE_PURCHASE_RETURN_LIST":
      return {
        ...state,
        purchaseReturnList: action.newData,
      };
    case "UPDATE_SINGLE_INVOICE_LIST":
      return {
        ...state,
        singleInvoiceList: action.newData,
      };
    case "UPDATE_PURCHASE_INVOICE_LIST":
      return {
        ...state,
        purchaseInvoiceList: action.newData,
      };
    case "UPDATE_ADVANCE_BOOKING_LIST":
      return {
        ...state,
        advanceBookingList: action.newData,
      };
    case "CURRENT_USER_SET":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "INVOICE_SETTING_UPDATE":
      return {
        ...state,
        invoiceSetting: action.payload,
      };
    case "UPDATE_COUNTRY_LIST":
      return {
        ...state,
        countryList: action.payload,
      };
    case "UPDATE_BRANCH_LIST":
      return {
        ...state,
        branchList: action.payload,
      };
    case "UPDATE_BLOOD_GROUP_LIST":
      return {
        ...state,
        bloodGroups: action.payload,
      };
    case "UPDATE_NEXT_PAGE":
      return {
        ...state,
        nextPage: action.payload,
      };
    case "UPDATE_CURRENT_PAGE":
      return {
        ...state,
        currentPage: action.payload,
      };
    case "SET_PAPER_SIZE":
      return {
        ...state,
        paperSize: action.payload,
      };
    case "SET_DELIVERY_MODE":
      return {
        ...state,
        deliveryMode: action.payload,
      };
    case "UPDATE_ACTIVE_MENU":
      return {
        ...state,
        activeMenu: action.payload,
      };
    case "SET_CUSTOMER_DUE_INVOICE_LIST":
      return {
        ...state,
        customerDueInvoiceList: action.payload,
      };
    case "SET_SALE_INVOICE_ID_FOR_DUE":
      return {
        ...state,
        saleInvoiceIdForDue: action.payload,
      };
    case "SET_VENDOR_DUE_INVOICE_LIST":
      return {
        ...state,
        vendorDueInvoiceList: action.payload,
      };
    case "SET_PURCHASE_INVOICE_ID_FOR_DUE":
      return {
        ...state,
        purchaseInvoiceIdForDue: action.payload,
      };
    case "SET_EXPORTER_DUE_INVOICE_LIST":
        return {
          ...state,
          exporterDueInvoiceList: action.payload,
        };
    case "SET_EXPORTER_INVOICE_ID_FOR_DUE":
        return {
          ...state,
          exporterInvoiceIdForDue: action.payload,
        };
    case "SET_CARRIER_DUE_INVOICE_LIST":
        return {
          ...state,
          carrierDueInvoiceList: action.payload,
        };
    case "SET_CARRIER_INVOICE_ID_FOR_DUE":
        return {
          ...state,
          carrierInvoiceIdForDue: action.payload,
        };
    case "SET_WHOLESELLER_DUE_INVOICE_LIST":
          return {
            ...state,
            wholesellerDueInvoiceList: action.payload,
          };
    case "SET_WHOLESELLER_INVOICE_ID_FOR_DUE":
          return {
            ...state,
            wholesellerInvoiceIdForDue: action.payload,
          };
    case "SET_DUE_PAYMENT_CUSTOM_DATE":
          return {
            ...state,
            duePaymentCustomDate: action.payload,
          }

    default:
      return {
        ...state,
        token: "",
        searchKeyword: "",
        currentUser: localStorage.getItem("user")
          ? JSON.parse(localStorage.getItem("user"))
          : null,
        isLoading: false,
        showFooter: false,
        categoryList: [],
        shopCategoryList: [],
        singleCategoryList: [],
        subCategoryList: [],
        brandList: [],
        unitList: [],
        paymentList: [],
        paymentAccountList: [],
        deliveryList: [],
        deliveryAccountList: [],
        warrantyList: [],
        invoiceList: [],
        holdInvoiceList: [],
        wholesaleHoldInvoiceList: [],
        wholesaleInvoiceList: [],
        salesReturnList: [],
        purchaseReturnList: [],
        singleInvoiceList: [],
        purchaseInvoiceList: [],
        advanceBookingList: [],
        customerLists: [],
        wholesalerLists: [],
        invoiceSetting: null,
        countryList: [],
        branchList: [],
        bloodGroups: [],
        vendorLists: [],
        exporterOrderLists: [],
        exporterLists:[],
        carrierLists:[],
        productList: [],
        paperSize: "A4",
        customerList: [{ id: "", name: "Select" }],
        exporterList: [{ id: "", name: "Select" }],
        vendorList: [{ id: "", name: "Select" }],
        wholeSalerList: [{ id: "", name: "Select" }],
        activeMenu: "",
        customerDueInvoiceList: [],
        saleInvoiceIdForDue: "",
        vendorDueInvoiceList: [],
        purchaseInvoiceIdForDue: "",
        exporterDueInvoiceList: [],
        exporterInvoiceIdForDue: "",
        carrierDueInvoiceList: [],
        carrierInvoiceIdForDue: "",
        wholesellerDueInvoiceList: [],
        wholesellerInvoiceIdForDue: "",
        duePaymentCustomDate: ""
      };
  }
};


const AppProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = createActions(dispatch);

  if (typeof localStorage !== 'undefined') {
    state.token = localStorage.getItem('token');
  }

  return (
    <AppContext.Provider value={{ config, state, ...actions }}>
      {props.children}
    </AppContext.Provider>
  );
}

export default AppProvider;
