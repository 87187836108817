
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
const token = localStorage.getItem("token");

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '10px',
    borderColor: '#C1CFEF',
    width: '100%',
    //boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#C1CFEF' : 'white',
    color: '#102048',
    '&:hover': {
      backgroundColor: '#C1CFEF',
    },
  }),
};

const AddBalance = ({paymentAccountList, selectOption, setSelectOption, handleAddFund}) => {
  const AccountList = paymentAccountList.data;
  console.log("payment account", AccountList);

  const [balanceName, setBalanceName] = useState("");
  const [showError, setShowError] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleChangeSelect = ({lavel, value}) =>{
    setSelectOption({...selectOption, account_id: value});
  }

  const handleTransfer = () =>{
    if(selectOption.account_id=="" || selectOption.amount<1) {
      return;
    }
    handleAddFund();
  }

  return (
    <div className=" rounded-[25px] bg-[#fff] my-3 ">
      <div className='px-5 pt-5'>
        <h1 className='text-2xl font-semibold'>Add Balance</h1>
      </div>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 p-5 justify-center items-center'>
        <div className='w-full relative'>
        <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">Name</label>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={balanceName}
            onChange={(e) => {
              setBalanceName(e.target.value);
              setSelectOption({ ...selectOption, name: e.target.value });
              setShowError(false);
            }}
            className={`input input-bordered border-2 ${showError ? 'border-red-500' : 'border-gray-300'} w-full rounded-xl bg-white`}
          />
        </div>
        <div className='w-full relative'>
          <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
          Amount</label>
          <input
            type='text'
            name="name"
            placeholder='000'
            value={ selectOption.amount }
            onChange={(e)=>{
              setSelectOption({...selectOption, amount: e.target.value}),
              setShowError(false)
            }}
            className={`input input-bordered border-2 ${ showError? 'border-red-500' : 'border-gray-300'}  w-full  rounded-xl bg-white`}
          />
        </div>
        <div
          onClick={handleTransfer}
          className='w-full relative hover:scale-105 duration-200'>
          <button className='bg-[#034AFF] w-full rounded-xl py-[10px] text-[#ffffff]'>Add Balance</button>
        </div>


        <div className="w-full relative lg:col-span-1">
          <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">From</label>
            <Select
              onChange={(event) => {
                handleChangeSelect(event);
              }}
              styles={customStyles}
              className={`select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none px-[0px]  text-md focus:outline-none`}
              onMenuOpen={()=>setIsMenuOpen(true)}
              onMenuClose={()=>setIsMenuOpen(false)}
              options={ AccountList?.map((account) => ({
                value: account?.id,
                label: account.payment_category_name === 'cash'
                ? account.payment_category_name
                : account.payment_category_name + " (" + (account?.account_number ? account?.account_number : "") + ")"
              }))}
            />
        </div>
      </div>
    </div>
  );
};

export default AddBalance;