import React from "react";
import { format } from "date-fns";

const CustomerSummaryPrint = React.forwardRef(({ salesDetails, grandNetTotal, userInfo, invoice, grandNetTotalQuantity, formData, currencyData }, ref) => (

  <div ref={ref} className="p-6">
    {/* Header */}
    <div className="flex justify-between items-start mb-4 border-b-2 border-black pb-2">
        {/* First Column: Title and Date Range */}
        <div>
          <h1 className="font-bold text-sm">Customer Summary Report</h1>
          {/* <p className="text-xs text-gray-600">{`${formData.start_date} to ${formData.end_date}`}</p> */}
          <p className="text-xs text-gray-600">{`${new Date(formData.start_date).toLocaleDateString('en-GB')} to ${new Date(formData.end_date).toLocaleDateString('en-GB')}`}</p>
        </div>

        {/* Second Column: Shop Details */}
        <div className="text-left">
          <p className="text-lg font-bold">{userInfo?.outlet_name}</p>
          <p className="text-xs text-gray-600">{userInfo?.address}</p>
          <p className="text-xs text-gray-600">Mobile- {userInfo?.phone}</p>
        </div>
    </div>
    {/* Table */}
    <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-400">
          {/* Table Head */}
          <thead>
            <tr style={{ backgroundColor: '#d3e3be', border: '2px solid black' }}>
              <th className=" px-2 text-left text-xs">Date</th>
              <th className=" px-2 text-left text-xs">Invoice No.</th>
              <th className=" px-2 text-left text-xs">Products</th>
              <th className=" px-2 text-left text-xs">Qty/Unit</th>
              <th className=" px-2 text-center text-xs">
                <div>
                  <span>Price</span>
                  <br />
                  <span className="text-xs">{`(in ${currencyData})`}</span>
                </div>
              </th>
              <th className=" px-2 text-right text-xs">
                <div>
                  <span>Total Amount</span>
                  <br />
                  <span className="text-xs">{`(in ${currencyData})`}</span>
                </div>
              </th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>

            {Object.entries(
                salesDetails.reduce((acc, sale) => {
                  const key = `${sale.invoice_id}-${sale.created_at.substring(0, 10)}`;
                  if (!acc[key]) {
                    acc[key] = [];
                  }
                  acc[key].push(sale);
                  return acc;
                }, {})
              ).map(([groupKey, salesGroup], groupIndex) => {
                // Find matching invoice for the current group
                const matchingInvoice = invoice.find(
                  (inv) => inv.invoice_id === salesGroup[0]?.invoice_id
                );

                return (
                  <React.Fragment key={groupIndex}>
                    {/* Group Header Row */}
                    <tr className="border border-gray-400">
                      <td
                        colSpan="1"
                        className=" text-left text-xs px-2 font-normal"
                      >
                        {salesGroup[0]?.created_at.substring(0, 10)}
                      </td>
                      <td
                        colSpan="1"
                        className="text-left text-xs font-semibold px-2 font-normal"
                      >
                        {salesGroup[0]?.invoice_id.split('-').pop()}
                      </td>
                      <td
                        colSpan="4"
                        className="text-left text-xs font-semibold px-2 font-normal"
                      >
                      </td>
                    </tr>

                    {/* Group Data Rows */}
                    {salesGroup.map((sale) => (
                      <tr key={sale.id} className="hover:bg-gray-100 border border-gray-400">
                        <td className="text-xs px-2"></td>
                        <td className="text-xs px-2"></td>
                        <td className="text-xs px-2 font-normal">
                          {sale?.product_info?.name || "N/A"}
                        </td>
                        <td className="text-xs text-center px-2 font-normal">{sale?.qty}</td>
                        <td className="text-xs text-center px-2 font-normal">{parseFloat(sale.price).toFixed(2)}</td>
                        <td className="text-xs text-right px-2 font-normal">{parseFloat(sale.total_amount).toFixed(2)}</td>
                      </tr>
                    ))}

                    {/* Subtotal Row */}
                    <tr className="border border-gray-400">
                      <td
                        colSpan="3"
                        className="text-xs font-semibold text-left px-2"
                      >
                        Subtotal:
                      </td>
                      <td colSpan="1" className="text-xs font-semibold text-center px-2">
                        {matchingInvoice?.sub_total_quantity || "N/A"}
                      </td>
                      <td colSpan="2" className="text-xs font-semibold text-right px-2">
                        {parseFloat(matchingInvoice?.sub_total).toFixed(2) || "N/A"}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}

            <tr>
                      <td
                        colSpan="3"
                        className="text-left text-xs font-semibold px-2"
                      >
                        Grand Net Total:
                      </td>
                      <td
                        colSpan="1"
                        className="text-center text-xs font-semibold px-2"
                      >
                        {grandNetTotalQuantity}
                      </td>
                      <td
                        colSpan="2"
                        className="text-right text-xs font-semibold px-2"
                      >
                        {parseFloat(grandNetTotal).toFixed(2)}
                      </td>
            </tr>
          </tbody>
        </table>
    </div>

  </div>
));

export default CustomerSummaryPrint;