
import React, { useRef, useState } from 'react';

const ProductVariationForm = (props) => {
  const {
    state,
    setShowModal,
    isOpen,
    selectedItem,
    orderList,
    setOrderList,
    // variationData,
    // seteVariationData,
    formId
  } = props;

  const variationForm = {
    product_id: "",
    name: "",
    serial: "",
    purchase_price: "",
    wholesale_price: "",
    retails_price: "",
    discount_type: 0,
    quantity: 1,
  }
  const quantity = selectedItem.qty;
  // const initialVariationData = Array.from({ length: selectedItem.qty }, () => ({ ...variationForm }));
  // const [variationData, seteVariationData] = useState(initialVariationData);
  const [variationData, seteVariationData] = useState(selectedItem.variants);

  console.log(variationData);
  const [currentPos, setCurrentPos] = useState(0)
  const [isForEcommerce,setIsForEcommerce] = useState(false);

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(variationData[currentPos].serial=="") {
      setShowError(true);
      return;
    }
    setShowModal(false);
    const existingIndex = orderList.findIndex(
      (orderItem) => orderItem?.id === selectedItem?.id
    );
    const total = variationData.reduce((s, item)=> s += Number.parseFloat(item.purchase_price), 0)
    console.log("total sum", total);
    const updatedOrderList = [...orderList];
    updatedOrderList[existingIndex].variants = variationData;
    updatedOrderList[existingIndex].variantsSum = 1;
    // updatedOrderList[existingIndex].purchase_price = total;
    console.log('update OrderLIst ',updatedOrderList);
    setOrderList(updatedOrderList);
  }

  const handleNext = () =>{
    if(variationData[currentPos].serial=="") {
      setShowError(true);
      return;
    }
    setCurrentPos(currentPos+1)
  }

  const handleBack = () =>{
    if(currentPos<1) return;
    setCurrentPos(currentPos-1);
  }

  const [showError, setShowError] = useState(false);

  return (
    <div  className=''>
      {console.log('selected item ',selectedItem)}
      <div className='flex flex-col justify-between items-start pt-3 px-1'>
        <div >
          <h1 className='text-md text-left font-semibold mb-[8px]'>Variation {currentPos+1}</h1>
        </div>
        <div>
          {/* box 1 */}
        <div className='flex items-center justify-start py-3 gap-3'>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Optional Name</label>
            <input
              type='text'
              name="name"
              placeholder='Product Name'
              value={ variationData[currentPos].name}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, name: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData);}}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            SL/IMEI *</label>
            <input
              type='text'
              name="name"
              placeholder='Ex: 12345'
              value={ variationData[currentPos].serial}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, serial: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData),
                setShowError(false)
              }}
              className={`input input-bordered border-2 ${ showError? 'border-red-500' : 'border-gray-500'}  w-full mb-[10px] rounded-xl bg-white`}
            />
            {/* {showError
            ? <p className="error-message text-red-500 mb-3 pl-1">This field cannot be empty.</p>
            : null} */}
          </div>
        </div>
        {/* box 2 */}
        <div className='flex items-center justify-start py-3 gap-3'>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Purchase Price</label>
            <input
              type='text'
              name="name"
              placeholder='0.00'
              value={ variationData[currentPos].purchase_price}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, purchase_price: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData);}}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Wholesale Price</label>
            <input
              type='text'
              name="name"
              placeholder='0.00'
              value={ variationData[currentPos].wholesale_price}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, wholesale_price: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData);}}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
        </div>
        {/* box 3 */}
        <div className='flex items-center justify-start py-3 gap-3'>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Sale Price</label>
            <input
              type='text'
              name="name"
              placeholder='0.00'
              value={ variationData[currentPos].retails_price}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, retails_price: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData);}}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            QTY</label>
            <input
              type='text'
              name="name"
              // disabled
              placeholder='0.00'
              value={ variationData[currentPos].quantity}
              // onChange={(e)=>setCurrentVarient({...currentVarient, variationQty: e.target.value})}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
        </div>
        {/* box 3 */}
        <div className='flex items-center justify-start py-3 gap-3'>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Ram</label>
            <input
              type='text'
              name="name"
              placeholder='Ram'
              value={ variationData[currentPos].ram}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, ram: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData);}}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
          <div className='w-full relative'>
            <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
            Storage</label>
            <input
              type='text'
              name="name"
              placeholder='Storage'
              value={ variationData[currentPos].storage}
              onChange={(e)=>
                {const updatedVariationData = variationData.map((item, index) => {
                  if (index === currentPos) {
                    return { ...item, storage: e.target.value };
                  }
                  return item;
                });
                seteVariationData(updatedVariationData);}}
              className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
            />
          </div>
        </div>
        {/* box 5*/}
        <div className='flex items-center justify-start py-3 gap-3'>
          <div className='w-full relative'>
              <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
              Battery Life</label>
              <input
                type='text'
                name="name"
                placeholder='Battery Life'
                value={ variationData[currentPos].battery_life}
                onChange={(e)=>
                  {const updatedVariationData = variationData.map((item, index) => {
                    if (index === currentPos) {
                      return { ...item, battery_life: e.target.value };
                    }
                    return item;
                  });
                  seteVariationData(updatedVariationData);}}
                className='input input-bordered border-2 border-gray-500 w-full mb-[10px] rounded-xl bg-white'
              />
          </div>
        </div>

        {/* box-6 */}

        <div className='flex gap-2'>
        <input
          // checked={formData.is_ecommerce} // Check the checkbox if formData.have_variant has a value
          type="checkbox"
          value="1"
          className="rounded-[6px]"
          name="is_ecommerce"
          onChange={(e) => {
            const updatedVariationData = variationData.map((item, index) => {
              if (index === currentPos) {
                return { ...item, is_ecommerce: e.target.checked };
              }
              return item;
            });
            seteVariationData(updatedVariationData)
          }}
          // onChange={handleForEcommerce}
        />
        <p className="text-[#91919F]">Ecommerce</p>
        </div>

        {/* box 6 */}
        {/* <div className='flex items-center justify-start py-1 gap-3'>
          <div className='w-full relative'>
            {
              Number(selectedItem.qty) > 1 &&
              <button
                onClick={handleBack}
                className="cursor-pointer w-full border-2 border-[#034AFF] py-[11px] hover:bg-[#034AFF] hover:text-[#fff] text-center rounded-2xl text-[#034AFF] font-semibold text-lg"
              >Back
              </button>
            }
          </div>
          <div className='w-full relative'>
            { (currentPos+1) !=quantity ?
            <button
                onClick={handleNext}
                className="cursor-pointer w-full bg-[#034AFF] py-[13px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
              >Next
            </button>:
            <label
                htmlFor={formId}
                onClick={handleSubmit}
                className="cursor-pointer w-full bg-[#034AFF] py-[13px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
              >Done
            </label>
            }
          </div>
        </div> */}
        {
          Number(selectedItem.qty) > 1 ?
          <div className='flex items-center justify-start py-1 gap-3'>
            <div className='w-full relative'>
              <button
                onClick={handleBack}
                className="cursor-pointer w-full border-2 border-[#034AFF] py-[11px] hover:bg-[#034AFF] hover:text-[#fff] text-center rounded-2xl text-[#034AFF] font-semibold text-lg"
              >Back
              </button>
            </div>
            <div className='w-full relative'>
              { (currentPos+1) !=quantity ?
              <button
                  onClick={handleNext}
                  className="cursor-pointer w-full bg-[#034AFF] py-[13px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
                >Next
              </button>:
              <label
                  htmlFor={formId}
                  onClick={handleSubmit}
                  className="cursor-pointer w-full bg-[#034AFF] py-[13px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
                >Done
              </label>
              }
            </div>
          </div>
          :
          <div className='flex items-center justify-start py-1 gap-3'>
            <div className='w-full relative'>
              { (currentPos+1) !=quantity ?
              <button
                  onClick={handleNext}
                  className="cursor-pointer w-full bg-[#034AFF] py-[13px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
                >Next
              </button>:
              <label
                  htmlFor={formId}
                  onClick={handleSubmit}
                  className="cursor-pointer w-full bg-[#034AFF] py-[13px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
                >Done
              </label>
              }
            </div>
          </div>
        }
        </div>
      </div>

      {/* <div>
        <label
          htmlFor={formId}
          onClick={handleSubmit}
          className="cursor-pointer w-full bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-semibold text-lg"
        >Save
        </label>
      </div> */}
    </div>
  );
};

export default ProductVariationForm;