import React, { useContext, useEffect, useRef, useState } from "react";

import { formatPrice, formatDate } from "../../../utils/Common";

import manIcon from "../../../assets/images/man.png";
import callenderIcon from "../../../assets/images/calendar.png";
import downloadIcon from "../../../assets/images/download.png";
import callIcon from "../../../assets/images/social/call.png";
import message1Icon from "../../../assets/images/social/message1.png";
import message2Icon from "../../../assets/images/social/message2.png";
import whatsappIcon from "../../../assets/images/social/whatsapp.png";
import { AppContext } from "../../../context/context";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal } from "../../../utils/Modal";
import { GrFormClose } from "react-icons/gr";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import { FlashMessage } from "../../../utils/FlashMessage";

const PaymentInfo = (props) => {
  const {
    hideModal,
    setSuccessFlashMessage,
    dueCollectionData,
    type,
    activeTab,
    loadingCallback,
    fetchedData,
    invoiceData,
    onSubmitPayment,
    handleSubmitAddDue,
    handleSubmitAddPiad,
    handlePrintPdf,
    setFromDate,
    setToDate,
    fromDate,
    toDate,
    reportBtn,
    getCustomerProfileData,
  } = props;
  const { state, updatePaymentList } = useContext(AppContext);

  console.log(dueCollectionData);

  //---------------------
  const location = useLocation();
  const navigate = useNavigate();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");

    if (!employeeApiResponse) {
      setHasPermission(true);
      return; // Exit useEffect early
    }

    if (employeeApiResponse) {
      // Parse the employee data from local storage
      const employeeData = JSON.parse(employeeApiResponse);

      // Get the "Exporter" feature from the employee's role features
      const exporterFeature = employeeData.role.features.find(
        (feature) => feature.name === "Exporter"
      );

      // Check if the "Exporter" feature and "Visible" option are both active
      if (exporterFeature && exporterFeature.status === 1) {
        const exporterVisibleOption = exporterFeature.feature_options.find(
          (option) => option.name === "Visible" && option.status === 1
        );
        // Check if the "Hidden" option is active for /editproduct/:id route
        const exporterHiddenOption = exporterFeature.feature_options.find(
          (option) => option.name === "Hidden" && option.status === 1
        );

        // Grant access based on the route and permissions
        if (
          location.pathname === "/exporter-profile/:id" &&
          exporterVisibleOption
        ) {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
        if (
          location.pathname === "/carrier-profile/:id" &&
          exporterVisibleOption
        ) {
          setHasPermission(true);
          return; // Exit useEffect if permission is granted
        }
      }
      setHasPermission(false);
      // If no permission, navigate to dashboard first
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
    // If not an employee, the useEffect gets skipped
  }, [navigate, location]);
  //---------------------

  const [paidAmount, setPaidAmount] = useState(0.0);

  const [openingAmount, setOpeningAmount] = useState(0);
  const [showDueDiscountModal, setShowDueDiscountModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
  const [showOpeningBalanceModal, setShowOpeningBalanceModal] = useState(false);
  const [paymentData, setPaymentData] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [paymentAccountList, setPaymentAccountList] = useState([]);
  const [filteredAccountList, setFilteredAccountList] = useState([]);
  const [selectedPaymentAccountList, setSelectedPaymentAccountList] = useState(
    []
  );
  const [selectedAddPaymentAccountList, setSelectedAddPaymentAccountList] =
    useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [customerDueInvoiceListState, setCustomerDueInvoiceListState] =
    useState([]);
  const {
    setCustomerDueInvoiceList,
    setVendorDueInvoiceList,
    setExporterDueInvoiceList,
    setCarrierDueInvoiceList,
    setWholesellerDueInvoiceList,
  } = useContext(AppContext);
  const {
    saleInvoiceIdForDue,
    purchaseInvoiceIdForDue,
    exporterInvoiceIdForDue,
    carrierInvoiceIdForDue,
    wholesellerInvoiceIdForDue,
    duePaymentCustomDate,
  } = state;
  const [money, setMoney] = useState("");
  const token = localStorage.getItem("token");
  const { id } = useParams();
  //const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState("");
  const perPage = 1000;

  const [formData, setFormData] = useState({
    name: "",
    method_id: 0,
    account_id: 0,
    account_number: "",
    methodLabel: "",
    accountLabel: "",
  });

  const getWholesellerDueInvoiceList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}wholeseller-due-invoice-list/${id}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setWholesellerDueInvoiceList(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        // navigate('/login');
        console.log(error);
      });
  };

  const getCarrierDueInvoiceList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}carrier-due-invoice-list/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setCarrierDueInvoiceList(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const getExporterDueInvoiceList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}exporter-due-invoice-list/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setExporterDueInvoiceList(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const getVendorDueInvoiceList = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}vendor-due-invoice-list/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setVendorDueInvoiceList(response.data.data);
          console.log(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const getCustomerDueInvoiceList = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}customer-due-invoice-list/${id}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setCustomerDueInvoiceList(response.data.data);
          setCustomerDueInvoiceListState(response.data.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  const getPayment = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          loadingCallback(false);
          setPaymentData([...paymentData, response?.data?.data?.data[0]]);
          updatePaymentList(response?.data?.data);
          setPaymentList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };

  useEffect(() => {
    if (type === "customer" && customerDueInvoiceListState.length < 1) {
      getCustomerDueInvoiceList();
    }
  }, [customerDueInvoiceListState]);

  useEffect(() => {
    setTimeout(() => {
      if (type === "exporter") {
        getExporterDueInvoiceList();
      }

      if (type === "vendor") {
        getVendorDueInvoiceList();
      }

      if (type === "customer") {
        console.log("Called");
        getCustomerDueInvoiceList();
        getCustomerProfileData(activeTab);
      }
      if (type === "carrier") {
        getCarrierDueInvoiceList();
      }
      if (type === "wholesaler") {
        getWholesellerDueInvoiceList();
      }
    }, 2000);
  }, [showDueDiscountModal]);

  useEffect(() => {
    if (hasPermission) {
      getPayment();
      getPaymentAccountList();
    }
  }, [hasPermission]);
  useEffect(() => {
    if (hasPermission) {
      submitDuePayment(selectedPaymentAccountList);
    }
  }, [selectedPaymentAccountList, hasPermission]);
  useEffect(() => {
    setShowPaymentModal(false);
  }, [hideModal]);
  const submitDuePayment = async (selectedPaymentAccountList) => {
    console.log(type);
    if (selectedPaymentAccountList.length > 0) {
      setShowPaymentModal(!showPaymentModal);
      const paymentMap = selectedPaymentAccountList.map((item) => ({
        payment_type_id: item?.payment_type_id,
        payment_type_category_id: item?.payment_type_category_id,
        payment_amount: item?.Amount,
      }));
      const data = {
        previous_due:
          type != "carrier"
            ? fetchedData?.invoice_list_sum_sub_total -
              fetchedData?.invoice_list_sum_paid_amount
            : 0,
        paid_amount: Number(paidAmount),
        payment_method: paymentMap,
        sale_invoice_id: saleInvoiceIdForDue,
        purchase_invoice_id: purchaseInvoiceIdForDue,
        exporter_invoice_id: exporterInvoiceIdForDue,
        carrier_invoice_id: carrierInvoiceIdForDue,
        wholesale_invoice_id: wholesellerInvoiceIdForDue,
        custom_date: duePaymentCustomDate,
      };
      if (type === "vendor") {
        data.vendor_id = fetchedData?.id;
      }
      if (type === "wholesaler") {
        data.wholesaler_id = fetchedData?.id;
      }
      if (type === "customer") {
        data.customer_id = fetchedData?.id;
      }
      if (type === "exporter") {
        data.exporter_id = fetchedData?.id;
      }
      if (type === "carrier") {
        data.carrier_id = fetchedData?.id;
      }
      // console.log("============");
      // console.log(data);

      onSubmitPayment(data);

      setTimeout(() => {
        if (type === "exporter") {
          getExporterDueInvoiceList();
        }

        if (type === "vendor") {
          getVendorDueInvoiceList();
        }

        if (type === "customer") {
          getCustomerDueInvoiceList();
        }
        if (type === "carrier") {
          getCarrierDueInvoiceList();
        }
        if (type === "wholesaler") {
          getWholesellerDueInvoiceList();
        }
      }, 1000);
    }
  };
  const getPaymentAccountList = () => {
    loadingCallback(true);
    axios
      .get(`${process.env.REACT_APP_API_URL}payment-type-category-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response?.data.success) {
          loadingCallback(false);
          setPaymentAccountList(response?.data?.data?.data);
        }
      })
      .catch((error) => {
        navigate("/login");
        console.log(error);
      });
  };
  const handleClicks = (data) => {
    setShowPaymentModal(true);
  };

  const handleDueDiscountClick = () => {
    setShowDueDiscountModal(true);
  };

  const handleOpeningBalanceClicks = () => {
    setShowOpeningBalanceModal(!showOpeningBalanceModal);
  };

  const handleOpeningBalanceSubmit = async (selectedAddPaymentAccountList) => {
    if (selectedAddPaymentAccountList.length > 0) {
      setShowPaymentModal(!showPaymentModal);
      const paymentMap = selectedAddPaymentAccountList.map((item) => ({
        payment_type_id: item?.payment_type_id,
        payment_type_category_id: item?.payment_type_category_id,
        payment_amount: item?.Amount,
      }));
      const data = {
        adv_amount: Number(paidAmount),
        payment_method: paymentMap,
      };
      if (type === "vendor") {
        data.vendor_id = fetchedData?.id;
      }
      if (type === "wholesaler") {
        data.wholesaler_id = fetchedData?.id;
      }
      if (type === "customer") {
        data.customer_id = fetchedData?.id;
      }
      if (type === "exporter") {
        data.exporter_id = fetchedData?.id;
      }
      handleSubmitAddPiad(data);
    }
  };

  const handleOpeningPaidModal = () => {
    // setShowOpeningBalanceModal(true);
    setShowAddPaymentModal(true);
  };

  const handleOpeningDueBalanceSubmit = () => {
    setShowOpeningBalanceModal(!showOpeningBalanceModal);
    const data = {
      adv_amount: Number(openingAmount),
    };
    if (type === "vendor") {
      data.vendor_id = fetchedData?.id;
    }
    if (type === "wholesaler") {
      data.wholesaler_id = fetchedData?.id;
    }
    if (type === "customer") {
      data.customer_id = fetchedData?.id;
    }
    if (type === "exporter") {
      data.exporter_id = fetchedData?.id;
    }
    handleSubmitAddDue(data);
  };

  useEffect(() => {
    handleOpeningBalanceSubmit(selectedAddPaymentAccountList);
    setShowOpeningBalanceModal(false);
    setShowAddPaymentModal(false);
  }, [selectedAddPaymentAccountList]);

  // useEffect(() => {
  //   submitAddDuePayment(selectedPaymentAccountList);
  // }, [selectedPaymentAccountList]);

  useEffect(() => {
    console.log("show opening valanace modal", showOpeningBalanceModal);
  }, [showAddPaymentModal]);

  const [fromDateIsOpen, setFromDateIsOpen] = useState(false);
  const [toDateIsOpen, setToDateIsOpen] = useState(false);

  const handleFromDateChange = (date) => setFromDate(date);
  const handleToDateChange = (date) => setToDate(date);

  const toggleFromDate = () => setFromDateIsOpen(!fromDateIsOpen);
  const toggleToDate = () => setToDateIsOpen(!toDateIsOpen);

  const closeAllPickers = () => {
    setFromDateIsOpen(false);
    setToDateIsOpen(false);
  };

  // Handle document click outside the date pickers (optional)
  //document.addEventListener('click', closeAllPickers, false); // Remove event listener on unmount
  useEffect(() => {
    if (reportBtn) {
      handlePrintPdf("duepayReport");
    }
  }, [reportBtn]);

  const handleReportButton = () => {
    //props.setReportBtn(true);
    props.getDateWiseDueReport(1);
  };

  const [editIndex, setEditIndex] = useState(null);
  const [editedData, setEditedData] = useState({});

  const handleEditClick = (index, initialValue) => {
    setEditIndex(index);
    setEditedData({ ...editedData, [index]: initialValue });
  };

  const handleInputChange = (index, value) => {
    setEditedData({ ...editedData, [index]: value });
  };

  const handleSaveClick = (item) => {
    const updatedValue =
      editedData[editIndex] ||
      (type === "carrier" || type === "exporter"
        ? item?.amount
        : item?.paid_amount);

    const data = {
      id: item.id, // or `item.invoice_id` depending on your API requirement
      paid_amount: updatedValue, // key for the updated amount
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}update-due-collection/${item.id}`,
        data,
        {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        if (response.data.success) {
          FlashMessage("Due collection updated successfully.", "success");
          setEditIndex(null); // Exit edit mode

          // Optionally, refresh the table or update state locally
          window.location.reload();
        } else {
          FlashMessage(
            response.data.message || "Something went wrong!",
            "error"
          );
        }
      })
      .catch((error) => {
        FlashMessage("Failed to update due collection.", "error");
        if (error?.response?.status === 401) {
          localStorage.clear();
          navigate("/login");
        }
      });
  };

  return (
    <>
      <div className="bg-[#F8F9FA] rounded-[16px]">
        <div className="mb-[20px] flex flex-col items-center">
          <div
            className="w-[80px] rounded-[50%] p-[3px]"
            style={{
              boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              src={fetchedData?.image ? fetchedData?.image : manIcon}
              className="w-[100%] rounded-[50%]"
            />
          </div>
          <h2 className="font-lato font-[600] text-[18px] text-[#000232] mt-[20px]">
            {fetchedData?.name}
          </h2>
          <p className="font-nunito font-[700] text-[13px] text-[#7282A0] mt-[10px]">
            {fetchedData?.email}
          </p>
          {type === "vendor" ? (
            <Link
              className="font-nunito font-[600] text-[11px] text-[#1294F2] mt-[10px]"
              to={`/vendor-edit/${fetchedData.id}`}
            >
              Edit Vendor
            </Link>
          ) : type === "wholesaler" ? (
            <Link
              className="font-nunito font-[600] text-[11px] text-[#1294F2] mt-[10px]"
              to={`/wholesaler-edit/${fetchedData.id}`}
            >
              Edit Wholesaler
            </Link>
          ) : type === "customer" ? (
            <Link
              className="font-nunito font-[600] text-[11px] text-[#1294F2] mt-[10px]"
              to={`/customer-edit/${fetchedData.id}`}
            >
              Edit Customer
            </Link>
          ) : type === "exporter" ? (
            <Link
              className="font-nunito font-[600] text-[11px] text-[#1294F2] mt-[10px]"
              to={`/exporter-edit/${fetchedData.id}`}
            >
              Edit Exporter
            </Link>
          ) : type === "carrier" ? (
            <Link
              className="font-nunito font-[600] text-[11px] text-[#1294F2] mt-[10px]"
              to={`/carrier-edit/${fetchedData.id}`}
            >
              Edit Carrier
            </Link>
          ) : (
            ""
          )}
        </div>
        <div className="flex flex-row justify-between gap-1 max-w-[196px] m-auto mb-4">
          <img src={callIcon} width={40} height={40} alt="" />
          <img src={message1Icon} width={40} height={40} alt="" />
          <img src={message2Icon} width={40} height={40} alt="" />
          <img src={whatsappIcon} width={40} height={40} alt="" />
        </div>
        {showPaymentModal ? (
          <Modal
            isOpen={showPaymentModal}
            setOpen={setShowPaymentModal}
            dueAmount={
              type === "carrier" ||
              (type === "exporter" && fetchedData?.total_due)
                ? fetchedData.total_due.toFixed(2)
                : fetchedData?.due
                ? fetchedData.due.toFixed(2)
                : 0
            }
            getData={paymentData}
            listData={paymentList}
            formData={formData}
            setFormData={setFormData}
            paymentAccountList={paymentAccountList}
            setSelectedAccountList={setSelectedPaymentAccountList}
            setData={setPaymentData}
            setPaidAmount={setPaidAmount}
            setWarningMessage={setWarningMessage}
            title="Due Payment Info"
            formId="my_modal_due_payment"
            loadingCallback={loadingCallback}
            navigate={navigate}
            type="due-payment"
            selectType={type}
            setFilteredAccountList={setFilteredAccountList}
            filteredAccountList={filteredAccountList}
          />
        ) : null}

        {showDueDiscountModal ? (
          <Modal
            isOpen={showDueDiscountModal}
            setOpen={setShowDueDiscountModal}
            customerDueInvoiceListState={customerDueInvoiceListState}
            type="due-discount"
            formId="my_modal_due_discount"
            title="Due Discount"
            dueAmount={fetchedData?.due ? fetchedData.due.toFixed(2) : 0}
            dueType={type}
          />
        ) : null}

        {showAddPaymentModal ? (
          <Modal
            isOpen={showAddPaymentModal}
            setOpen={setShowAddPaymentModal}
            dueAmount={
              type === "carrier" && fetchedData?.total_due
                ? fetchedData.total_due.toFixed(2)
                : fetchedData?.due
                ? fetchedData.due.toFixed(2)
                : 0
            }
            getData={paymentData}
            listData={paymentList}
            formData={formData}
            setFormData={setFormData}
            paymentAccountList={paymentAccountList}
            setSelectedAccountList={setSelectedAddPaymentAccountList}
            setData={setPaymentData}
            setPaidAmount={setPaidAmount}
            setWarningMessage={setWarningMessage}
            title="Due Payment Info"
            formId="my_modal_payment"
            loadingCallback={loadingCallback}
            navigate={navigate}
            type="payment"
            selectType={type}
            setFilteredAccountList={setFilteredAccountList}
            filteredAccountList={filteredAccountList}
          />
        ) : null}

        {showOpeningBalanceModal ? (
          <>
            <div>
              <input
                type="checkbox"
                id="my_modal_openingBalance"
                className="modal-toggle"
              />
              <div className="modal">
                <div className="modal-box w-[490px] p-0 bg-white">
                  <div className="flex justify-between px-[18px] pt-[17px] pb-[13px] border-b border-[#D9D9D9]">
                    <h4 className="font-nunito font-semibold text-lg ">
                      Advance Paid
                    </h4>
                    <div className="modal-action">
                      <label
                        onClick={() =>
                          setShowOpeningBalanceModal(!showOpeningBalanceModal)
                        }
                        htmlFor="my_modal_openingBalance"
                        className="cursor-pointer modal-action bg-red-600 text-white rounded-md"
                      >
                        <GrFormClose />
                      </label>
                    </div>
                  </div>
                  <div className="px-5 pt-5 pb-[22px]">
                    {/* <form
                        method="post"
                        className="col-sm-12"
                        data-autosubmit="false"
                        autoComplete="off"
                        encType="multipart/form-data"
                      > */}
                    <div className="w-full relative">
                      <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[10px] left-[15px] bg-white px-1 font-semibold">
                        Amount
                      </label>
                      <input
                        type="text"
                        name="name"
                        onChange={(e) => {
                          setOpeningAmount(e.target.value);
                        }}
                        placeholder="000"
                        className="input input-bordered w-full mb-[15px] rounded-2xl bg-white"
                      />
                    </div>
                    <div className="button-disabled flex flex-row gap-2">
                      <button
                        // htmlFor='my_modal_openingBalance'
                        // type="submit"
                        onClick={handleOpeningDueBalanceSubmit}
                        className="cursor-pointer w-full border-2 border-[#034AFF] py-[12px] text-center rounded-2xl text-[#034AFF] font-lato font-semibold text-lg"
                      >
                        Add Due
                      </button>
                      <label
                        htmlFor="my_modal_payment"
                        // type="submit"
                        onClick={handleOpeningPaidModal}
                        className="cursor-pointer w-full bg-[#034AFF] py-[12px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
                      >
                        Add Paid
                      </label>
                    </div>
                    {/* </form> */}
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}

        <div>
          {type !== "carrier" ? (
            <label
              htmlFor="my_modal_openingBalance"
              onClick={handleOpeningBalanceClicks}
              className="mb-4 w-[250px] cursor-pointer m-auto xl:w-[100%] bg-white mt-[15px] border-1 border-[#034AFF] rounded-[14px] block font-nunito font-[600] text-[18px] text-[#034AFF] px-1 py-2 text-center"
            >
              Opening Balance
            </label>
          ) : null}

          <label
            htmlFor="my_modal_due_payment"
            onClick={() => handleClicks(fetchedData)}
            className="mb-4 w-[250px] cursor-pointer m-auto xl:w-[100%] mt-[15px] border-1 border-[#034AFF] bg-[#034AFF] rounded-[14px] block font-nunito font-[600] text-[18px] text-white px-1 py-2 text-center"
          >
            Pay Due
          </label>

          <label
            htmlFor="my_modal_due_discount"
            onClick={() => handleDueDiscountClick()}
            className="mb-4 w-[250px] cursor-pointer m-auto xl:w-[100%] mt-[15px] border-1 border-[#034AFF] bg-[#034AFF] rounded-[14px] block font-nunito font-[600] text-[18px] text-white px-1 py-2 text-center"
          >
            Due Discount
          </label>
        </div>

        <div className="mt-[15px] border-1 border-[#CBD5E0] rounded-[16px] px-[10px] py-[15px] bg-white">
          <div className="flex justify-between mb-[10px]">
            <p className="font-nunito font-[700] text-[12px] text-[#000232]">
              Payment History
            </p>
            {type == "carrier" || type == "exporter" ? (
              <>
                <img
                  src={callenderIcon}
                  alt="From Date Calendar"
                  onClick={toggleFromDate}
                  className={`w-[20px] cursor-pointer ${
                    fromDateIsOpen ? "active" : ""
                  }`} // Optional styling for active icon
                />
                <span>To:</span>
                <img
                  src={callenderIcon}
                  alt="To Date Calendar"
                  onClick={toggleToDate}
                  className={`w-[20px] cursor-pointer ${
                    toDateIsOpen ? "active" : ""
                  }`} // Optional styling for active icon
                />
                <img
                  src={downloadIcon}
                  alt="To Date Calendar"
                  onClick={handleReportButton}
                  //onClick={() => handlePrintPdf('duepayReport')}
                  className={`w-[20px] cursor-pointer ${
                    toDateIsOpen ? "active" : ""
                  }`} // Optional styling for active icon
                />
              </>
            ) : null}
          </div>
          {fromDateIsOpen || toDateIsOpen ? (
            <div className="grid grid-cols-4 bg-white border-1 border-solid border-[#9B54E1] rounded-[16px] p-[5%]">
              <div className=" flex justify-between w-full col-span-7 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                {fromDateIsOpen && (
                  <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                    <DatePicker
                      selected={fromDate}
                      onChange={handleFromDateChange}
                      dateFormat="yyyy-MM-dd" // Adjust date format as needed
                      // Other DatePicker props (e.g., minDate, maxDate)
                      onClose={closeAllPickers} // Close on date selection (optional)
                      className="w-full px-2 focus:outline-none"
                    />
                    <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                      Start Date
                    </span>
                  </div>
                )}
              </div>
              <div className=" flex justify-between w-full col-span-7 items-center mt-[5px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
                {toDateIsOpen && (
                  <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                    <DatePicker
                      selected={toDate}
                      onChange={handleToDateChange}
                      dateFormat="yyyy-MM-dd" // Adjust date format as needed
                      // Other DatePicker props (e.g., minDate, maxDate)
                      onClose={closeAllPickers} // Close on date selection (optional)
                      className="w-full px-2 focus:outline-none"
                    />
                    <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                      To Date
                    </span>
                  </div>
                )}
              </div>
            </div>
          ) : null}

          <div class="grid grid-cols-5">
            <div class="font-lato font-[600] text-[10px] text-[#000232] py-[8px]">
              Date
            </div>
            <div class="font-nunito font-bold text-[10px] text-[#000232] py-[8px] text-center">
              Amount
            </div>
            <div class="font-nunito font-bold text-[10px] text-[#000232] py-[8px] text-center">
              Paid for
            </div>
            <div class="font-nunito font-bold text-[10px] text-[#000232] py-[8px] text-center">
              PDF
            </div>
            <div class="font-nunito font-bold text-[10px] text-[#000232] py-[8px] text-right">
              Action
            </div>
          </div>

          {/* {dueCollectionData.length > 0 && dueCollectionData.map((item, index) => {

              return (
                <div
                  key={index}
                  class="border-t-[1px] border-solid border-[#E3EDF6] py-[8px] font-nunito font-[500] text-[10px] grid grid-cols-5 items-center"
                >
                  <div className="text-[#000232]">
                    {type == "carrier" || type == "exporter"
                      ? formatDate(item.date)
                      : formatDate(item.created_at)}
                  </div>
                  <div className="text-[#000232] text-center">
                    {type == "carrier" || type == "exporter"
                      ? item?.amount
                      : item?.paid_amount}
                  </div>
                  <div className="text-[#29B170] text-center">
                    {type == "carrier" || type == "exporter"
                      ? item?.paid_for
                      : "Bill Pay"}
                  </div>
                  <div className="text-center">
                    <a
                      href={
                        type === "carrier" &&
                        (item?.paid_for == "bill" || item?.paid_for == null)
                          ? `/carrier-invoice/${item.id}`
                          : type === "carrier" && item?.paid_for == "due"
                          ? `/carrier-due-invoice/${item.id}`
                          : type === "exporter" &&
                            (item?.paid_for == "bill" || item?.paid_for == null)
                          ? `/exporter-invoice/${item.id}`
                          : type === "exporter" && item?.paid_for == "due"
                          ? `/exporter-due-invoice/${item.id}`
                          : `/due-invoice/${item.invoice_id}`
                      }
                      className="text-[#034AFF]"
                    >
                      Invoice
                    </a>
                  </div>
                  <div className="text-right">
                      <button >
                          Edit
                      </button>
                  </div>
                </div>
              );
          })} */}

          {dueCollectionData.length > 0 &&
            dueCollectionData.map((item, index) => {
              const isEditable = editIndex === index;

              return (
                <div
                  key={index}
                  className="border-t-[1px] border-solid border-[#E3EDF6] py-[8px] font-nunito font-[500] text-[10px] grid grid-cols-5 items-center"
                >
                  {/* Date */}
                  <div className="text-[#000232]">
                    {type === "carrier" || type === "exporter"
                      ? formatDate(item.date)
                      : formatDate(item.created_at)}
                  </div>

                  {/* Editable Amount */}
                  <div className="text-[#000232] text-center">
                    {isEditable ? (
                      <input
                        type="text"
                        className="border border-gray-300 rounded px-2 text-[10px]"
                        value={editedData[index] || ""}
                        onChange={(e) =>
                          handleInputChange(index, e.target.value)
                        }
                      />
                    ) : type === "carrier" || type === "exporter" ? (
                      item?.amount
                    ) : (
                      item?.paid_amount
                    )}
                  </div>

                  {/* Paid For */}
                  <div className="text-[#29B170] text-center">
                    {type === "carrier" || type === "exporter"
                      ? item?.paid_for
                      : "Bill Pay"}
                  </div>

                  {/* Invoice Link */}
                  <div className="text-center">
                    <a
                      href={
                        type === "carrier" &&
                        (item?.paid_for === "bill" || item?.paid_for == null)
                          ? `/carrier-invoice/${item.id}`
                          : type === "carrier" && item?.paid_for === "due"
                          ? `/carrier-due-invoice/${item.id}`
                          : type === "exporter" &&
                            (item?.paid_for === "bill" ||
                              item?.paid_for == null)
                          ? `/exporter-invoice/${item.id}`
                          : type === "exporter" && item?.paid_for === "due"
                          ? `/exporter-due-invoice/${item.id}`
                          : `/due-invoice/${item.invoice_id}`
                      }
                      className="text-[#034AFF]"
                    >
                      Invoice
                    </a>
                  </div>

                  {/* Edit Button */}
                  <div className="text-right">
                    {isEditable ? (
                      <button
                        onClick={() => handleSaveClick(item)}
                        className="text-[#034AFF] text-xs"
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          handleEditClick(
                            index,
                            type === "carrier" || type === "exporter"
                              ? item?.amount
                              : item?.paid_amount
                          )
                        }
                        className="text-[#034AFF] text-xs hover:font-bold hover:underline"
                      >
                        Edit
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default PaymentInfo;
