import React, { useContext, useEffect, useState } from "react";
import "./index.css";
import GraphIcon from "./Graph.svg";
import BagIcon from "./Bag.svg";
import PeopleIcon from "./People.svg";
import MonyeIcon from "./Monye.svg";
import { AppContext } from "../../../context/context";
const BASE_URL = process.env.REACT_APP_ROOT_URL;
function TotalCards(porps) {
  const { type, cardData, loadingCallback, invoiceSetting } = porps;
  const logoimage = "./assets/dashboard/totalcardgraph.svg";

  const {
    state,
    setCurrentUser,
    currentUser,
    updateInvoiceSetting,
    setShopCategoryList,
    shopCategoryList,
  } = useContext(AppContext);

  const user = JSON.parse(localStorage.getItem("user"));

  console.log(cardData);

  const data = [
    {
      id: 1,
      color: "#FF7A00",
      totalTaka: cardData.sales.total,
      currency: invoiceSetting?.currency_info?.code
        ? " " + invoiceSetting?.currency_info?.code
        : "Tk",
      title: "Sales",
      percentage: cardData.sales.percentage,
      logoimage: "./assets/dashboard/iconwallet.svg",
    },
    {
      id: 2,
      color: "#9B54E1",
      totalTaka: cardData.expense.total,
      currency: invoiceSetting?.currency_info?.code
        ? " " + invoiceSetting?.currency_info?.code
        : "Tk",
      title: "Expense",
      percentage: cardData.expense.percentage,
      logoimage: "./assets/dashboard/piechart.svg",
    },
    {
      id: 3,
      color: "#29B170",
      totalTaka:
        user?.type == "8"
          ? cardData?.orders?.stock_value
          : cardData?.orders?.total
          ? cardData?.orders?.total
          : 0,
      currency: "",
      title: user?.type == "8" ? "Stock Value" : "Orders",
      percentage: user?.type == "8" ? "" : cardData.orders.percentage,
      logoimage: "./assets/dashboard/shoppingbag.svg",
    },
    {
      id: 4,
      color: "#347AE2",
      totalTaka:
        user?.type == "8" ? cardData.stock.total_product : cardData.stock.total,
      currency: "",
      title: user?.type == "8" ? "Product Stock" : "Stock",
      percentage: cardData.stock.percentage,
      logoimage: "./assets/dashboard/shoppingbag.svg",
    },
  ];

  return (
    // <div className="total-card-container !pb-[75px] md:pb-5 px-4 md:px-0 mt-4 md:mt-0">
    <div className="bg-white px-[30px] py-7 rounded-2xl flex-shrink-0">
      {/* <h1 className="text-pink-700 font-bold text-2xl m-3 md:hidden">
        Transactions
      </h1> */}
      <div className="cards-container grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 rounded-2xl">
        {data.map((item) => {
          return (
            <>
              <div
                className="total-card-container-data"
                style={{ backgroundColor: `${item.color}` }}
                key={item.id}
              >
                <div className="total-card-info">
                  <div className="total-card-desc">
                    <img src={item.logoimage} alt="wallet" />
                    <p>
                      {Number(
                        item?.totalTaka ? item?.totalTaka?.toFixed(2) : 0
                      )}
                      <sub style={{ fontSize: "10px", fontWeight: "300" }}>
                        {item.currency}
                      </sub>
                    </p>
                  </div>
                  <div className="total-card-title">
                    <p>Total {item.title}</p>
                  </div>
                </div>
                <div className="total-card-percantage">
                  <div className="total-card-percantage-info">
                    <p className="flex flex-col">
                      <span>{item.percentage}</span>
                      {
                        (item.id == 1 && user.type == 8 ) && <span><span className="text-[#ffffffab] ">Orders:</span> <span className="text-base">{cardData.orders.total}</span></span>
                      }
                    </p>
                  </div>

                  <div className="total-card-percantage-graph">
                    <img
                      src={`${BASE_URL}/assets/dashboard/totalcardgraph.svg`}
                      alt="logo"
                    />
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default TotalCards;
