import ItemSelectBox from './ItemSelectBox';
import React, { useEffect, useMemo, useState, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../context/context';
import axios from 'axios';
import Swal from "sweetalert2";



const DueDiscountForm = (props) => {
  const {
    isOpen,
    setOpen,
    customerDueInvoiceListState,
    type,
    title,
    dueAmount,
    customerDueInvoiceList,
    vendorDueInvoiceList,
    exporterDueInvoiceList,
    carrierDueInvoiceList,
    wholesellerDueInvoiceList,
    dueType
  } = props


  const token = localStorage.getItem("token");
  const [invoiceSelect, setInvoiceSelect] = useState(false);
  const [invoiceId, setInvoiceId] = useState("");
  const [formError, setFormError] = useState(false);
  const [formAmountError, setFormAmountError] = useState(false);
  const { setSaleInvoiceIdForDue, setPurchaseInvoiceIdForDue, setExporterInvoiceIdForDue, setCarrierInvoiceIdForDue, setWholesellerInvoiceIdForDue, setDuePaymentCustomDate } = useContext(AppContext);
  const [discountAmount, setDiscountAmount] = useState(0);
  const navigate = useNavigate();

  const handleChangeInvoice = (option) => {
    const {value, label} = option;
    console.log("Inside SALE_invoice_id")
    // if(option?.sale_invoice_id != ""){
    //   setSaleInvoiceIdForDue(value);
    // }

    // if(option?.purchase_invoice_id != ""){
    //   setPurchaseInvoiceIdForDue(value);
    // }
    // if(option?.exporter_invoice_id != ""){
    //   setExporterInvoiceIdForDue(value);
    // }
    // if(option?.carrier_invoice_id != ""){
    //   console.log('Inside CArrier DUe ' + value);
    //   setCarrierInvoiceIdForDue(value);
    // }

    // if(option?.wholesale_invoice_id != ""){
    //   setWholesellerInvoiceIdForDue(value);
    // }
    setInvoiceId(value);
    setFormError(false);
    setInvoiceSelect(true);

  }

  const handleSubmit = () => {
    const payload = {
      due: dueType,
      invoice_id: invoiceId,
      discount_amount: discountAmount
    }
    axios.post(
      `${process.env.REACT_APP_API_URL}save-due-discount`, payload, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {

      if(response?.data?.success){
        if(dueType === "customer"){
          console.log('CAllled');
          setTimeout(() => {
            Swal.fire({
              icon: "success",
              title: "Congrats",
              text: "Discount Added Successfully.",
            });
            setOpen(false);
          }, 1000);
        }


      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  }



  return (
    <div className="duration-500 h-[400px]">
      {console.log('customer due invoice list ',customerDueInvoiceListState)}
      <div className="flex flex-row justify-between items-center py-3 px-1">
        <h1 className="text-md font-semibold font-lato pb-1 px-1">Total Due</h1>
        <div className="">
          <p className="text-sm px-[4px] py-[2px]">{dueAmount}</p>
        </div>
      </div>

      <div className="flex items-center justify-center py-3 gap-3">
        {console.log('Customer ',customerDueInvoiceList)}
        {console.log('Vendor ',vendorDueInvoiceList)}
        {console.log('Exporter ',exporterDueInvoiceList)}
        {console.log('Carrier ',carrierDueInvoiceList)}
        {console.log('Wholesaler ',wholesellerDueInvoiceList)}
        <ItemSelectBox
              name="payment_id"
              options ={ customerDueInvoiceList?.length > 0
                          ? customerDueInvoiceList
                          : vendorDueInvoiceList?.length > 0
                          ? vendorDueInvoiceList
                          : exporterDueInvoiceList?.length > 0
                          ? exporterDueInvoiceList
                          : wholesellerDueInvoiceList.length > 0
                          ? wholesellerDueInvoiceList
                          : carrierDueInvoiceList  || ''}
              value={""}
              onChange={handleChangeInvoice.bind(this)}
              label={"Invoice ID"}
              placeholder={'Select Invoice ID'}
              style={{ maxHeight: "300px", overflowY: "scroll" }}
              setFormErrors={formError}
              error={"Please!! Select Invoice ID."}
        />

      </div>

        <div className="flex items-center justify-center py-2 gap-3">
          {console.log('discount Amo ',discountAmount)}
            <div className="w-full relative">
              <label className="absolute z-10 font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                Amount
              </label>
              <input
                type="text"
                name="name"
                placeholder="000.0"
                value={discountAmount}
                onChange={(e) => {
                  setDiscountAmount(e.target.value)
                }}
                className={`input input-bordered w-full mb-[10px] rounded-lg bg-white ${formAmountError ? "border-1 border-red-500" : ""}`}
              />
              {formAmountError && <div className="text-red-500 text-sm mt-1">{"Please! Enter Positive Discount Amount"}</div>}
            </div>
        </div>

      <div>
        <button
          htmlFor="my_modal_due_discount"
          onClick={(e) => {
            if (invoiceSelect && discountAmount > -1) {
              handleSubmit();
            }else{
              if(discountAmount < 0){
                setFormAmountError(true)
              }
              if(invoiceSelect === false){
                setFormError(true);
              }

            }
          }}
          className="cursor-pointer w-full bg-[#3559b3] hover:bg-[#034AFF] py-[17px] text-center rounded-2xl text-[#fff] font-lato font-semibold text-lg"
        >
          Save
        </button>
      </div>

    </div>
  )
}

export default DueDiscountForm;