import React from "react";

const CustomerWiseDuePrint = React.forwardRef(({ userInfo, totalDue, reports }, ref) => (
  <div ref={ref} className="p-6">
    {/* Header */}
    <div className="flex justify-between items-start mb-4 border-b-2 border-black pb-2">
        {/* First Column: Title and Date Range */}
        <div>
          <h1 className="font-bold text-sm">Customer Wise Due Report</h1>
        </div>

        {/* Second Column: Shop Details */}
        <div className="text-left">
          <p className="text-lg font-bold">{userInfo?.outlet_name}</p>
          <p className="text-xs text-gray-600">{userInfo?.address}</p>
          <p className="text-xs text-gray-600">Mobile- {userInfo?.phone}</p>
        </div>
    </div>
    <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-400">
          {/* Table Head */}
          <thead>
            <tr style={{ backgroundColor: '#d3e3be', border: '2px solid black' }}>
              <th className=" px-2 text-left text-xs">SL</th>
              <th className=" px-2 text-left text-xs">Name</th>
              <th className=" px-2 text-center text-xs">
                <div>
                  <span>Paid Amount</span>
                  <br />
                  <span className="text-xs">{`(in ${userInfo?.invoice_settings?.currency_info?.code})`}</span>
                </div>
              </th>
              <th className=" px-2 text-right text-xs">
                <div>
                  <span>Total Amount</span>
                  <br />
                  <span className="text-xs">{`(in ${userInfo?.invoice_settings?.currency_info?.code})`}</span>
                </div>
              </th>
              <th className=" px-2 text-right text-xs">
                <div>
                  <span>Due Amount</span>
                  <br />
                  <span className="text-xs">{`(in ${userInfo?.invoice_settings?.currency_info?.code})`}</span>
                </div>
              </th>
              <th className=" px-2 text-right text-xs">Invoice ID</th>
            </tr>
          </thead>
          {/* Table Body */}
          <tbody>
            {reports?.map((report, index) => (
              <tr key={report.id} className="border-b">
                <td className="px-2 text-left text-xs font-normal">{index + 1}</td>
                <td className="px-2 text-left text-xs font-normal">{report.name}</td>
                <td className="px-2 text-center text-xs font-normal">
                  {report.paid_amount?.toFixed(2)} {/* Format number */}
                </td>
                <td className="px-2 text-center text-xs font-normal">
                  {report.total_amount?.toFixed(2)}
                </td>
                <td className="px-2 text-center text-xs font-normal">
                  {report.remaining?.toFixed(2)}
                </td>
                <td className="px-2 text-right text-xs font-normal">{report.invoice_id}</td>
              </tr>
            ))}
            <tr>
                      <td
                        colSpan="1"
                        className="text-left text-xs font-semibold px-2"
                      ></td>
                      <td
                        colSpan="2"
                        className="text-left text-xs font-semibold px-2"
                      >
                        Total Due:
                      </td>
                      <td
                        colSpan="1"
                        className="text-left text-xs font-semibold px-2"
                      ></td>
                      <td
                        colSpan="1"
                        className="text-center text-xs font-semibold px-2"
                      >
                        {parseFloat(totalDue).toFixed(2)}
                      </td>
                      <td
                        colSpan="1"
                        className="text-left text-xs font-semibold px-2"
                      ></td>
            </tr>
          </tbody>
        </table>
    </div>

  </div>
));

export default CustomerWiseDuePrint;