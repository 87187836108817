// import React from "react";
// import LogoAlignLeft from './bigLogo.png';
// import { formatDate, formatPrice } from "../../utils/Common";
// import ProductStockReportItem from "./ProductStockReportItem";
// const ProductStockDetails = (props) => {

//   const {activeTab,detailsData,fullApiData} = props;
//   let createdDate,updatedDate;
//   detailsData.map(el =>{
//     createdDate = el.created_at;
//     updatedDate = el.updated_at;
//    } )
//    let sumStock = detailsData.reduce((total, cData) => {
//     return cData?.completed ? total + cData?.current_stock : total;
//   }, 0);
//    const storedUserData = JSON.parse(localStorage.getItem('user'));
//    const todayDate = new Date().toLocaleDateString();
//   return (
//     <div
//       id="productStockHistory"

//     >
//       {console.log('Fullapidata ', fullApiData)}
//       {/* <div className="flex justify-between items-center">
//         {outlet logo}
//         <div  className="w-20 cursor-pointer -pb-[4px]">
//           <img src={LogoAlignLeft} className="w-full h-full object-contain" />
//         </div>
//         {title and date}
//         <div className="text-[#000232] text-right">
//           <h2 className="text-sm font-semibold">Product Stock Report</h2>
//           <p className="text-xs">{formatDate(createdDate)} - {formatDate(updatedDate)}</p>
//         </div>
//       </div> */ }
//       <table className="w-[99%]">
//       <tbody>
//           <tr>
//             <td colSpan="3" className="border text-center m-0 p-1 text-xs">Product Stock Report</td>
//           </tr>
//           <tr>
//             <td className="border text-left align-top p-2 text-xs">
//               <div>Shop Name: {storedUserData?.outlet_name}</div>
//               <div>Email: {storedUserData?.email}</div>
//               <div>Address: {storedUserData?.address}</div>
//             </td>
//             <td className="border text-left align-top p-2 text-xs">
//               <div>Date: {todayDate}</div>
//               <div>Mobile: {storedUserData?.phone}</div>
//             </td>
//             <td className="border text-left align-top p-2 text-xs">
//               {storedUserData?.invoice_settings.shop_logo && (
//                 <img src={storedUserData?.invoice_settings.shop_logo} alt="Shop Logo" className="w-24 h-auto" />
//               )}
//             </td>
//           </tr>
//          {/*  <tr>
//             <td colSpan="3" className="border text-center p-2 text-xs">
//               From: {formatDate(createdDate)} to {formatDate(updatedDate)}
//             </td>
//           </tr> */}
//         </tbody>
//       </table>
//       {/* table head */}
//       <div className="grid text-center mt-4 pt-[4px] items-center text-md font-bold bg-[#41a9e0] text-white grid-cols-5 border-1 border-black" style={{ WebkitPrintColorAdjust: "exact" }}>
//         <div className="text-center col-span-1 border-l border-black">SL No.</div>
//         <div className="col-span-2 border-l border-black">Product Name</div>
//         <div className="col-span-1 border-l border-black">Purchase Price</div>
//         <div className="col-span-1 border-l border-black text-center">Current Stock</div>
//       </div>
//       {/* table body component */}
//       {detailsData.map((cData, index) =>
//          (cData?.completed &&
//           (
//           <ProductStockReportItem
//           categoryData={cData}
//             tabActive={activeTab}
//             key={index}
//             index={index}
//           />  )

//       )

//       )}

//       <hr className="border-[#000232] border-[1px] pt-20" />
//       <div className="w-[38%] ml-auto text-xs font-[500]">
//         {/* <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Total Product</p>
//           <p>{fullApiData?.total_product}</p>
//         </div>
//         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Sold Product Unit</p>
//           <p>{fullApiData?.total_sales_sum_qty}</p>
//         </div>
//         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Purchase Product Unit</p>
//           <p>{fullApiData?.total_purchase_sum_qty}</p>
//         </div>
//         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Total Customer</p>
//           <p>{fullApiData?.total_customer}</p>
//         </div> */}
//         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Total Sold Amount </p>
//           <p>{formatPrice(fullApiData?.total_sales_amount)}</p>
//         </div>
//         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Total Purchase Amount </p>
//           <p>{formatPrice(fullApiData?.total_purchase_amount)}</p>
//         </div>
//         <div className="border-b-[1px] border-solid border-[#E7EBF4] -pt-[4px] pb-[13px] flex justify-between items-center">
//           <p>Total Stock</p>
//           <p>{formatPrice(sumStock)}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductStockDetails;

// import React from "react";
// import LogoAlignLeft from './bigLogo.png';
// import { formatDate, formatPrice } from "../../utils/Common";
// import ProductStockReportItem from "./ProductStockReportItem";

// const ProductStockDetails = (props) => {
//   const { activeTab, detailsData, fullApiData } = props;

//   let createdDate, updatedDate;
//   detailsData.forEach(el => {
//     createdDate = el.created_at;
//     updatedDate = el.updated_at;
//   });

//   let sumStock = detailsData.reduce((total, cData) => {
//     return cData?.completed ? total + cData?.current_stock : total;
//   }, 0);

//   const storedUserData = JSON.parse(localStorage.getItem('user'));
//   const todayDate = new Date().toLocaleDateString();

//   return (
//     <div id="productStockHistory" className="p-4">
//       {/* Report Header */}
//       <div className="flex justify-between items-center mb-6">
//         <div className="w-20">
//           <img src={LogoAlignLeft} className="w-full h-auto object-contain" alt="Logo" />
//         </div>
//         <div className="text-right">
//           <h2 className="text-lg font-semibold">Product Stock Report</h2>
//           <p className="text-sm">{`${formatDate(createdDate)} - ${formatDate(updatedDate)}`}</p>
//         </div>
//       </div>

//       {/* Shop Details */}
//       <table className="w-full mb-6 border border-collapse border-gray-300">
//         <tbody>
//           <tr>
//             <td className="border p-2 text-sm align-top">
//               <div><strong>Shop Name:</strong> {storedUserData?.outlet_name}</div>
//               <div><strong>Email:</strong> {storedUserData?.email}</div>
//               <div><strong>Address:</strong> {storedUserData?.address}</div>
//             </td>
//             <td className="border p-2 text-sm align-top">
//               <div><strong>Date:</strong> {todayDate}</div>
//               <div><strong>Mobile:</strong> {storedUserData?.phone}</div>
//             </td>
//             <td className="border p-2 text-sm align-top text-center">
//               {storedUserData?.invoice_settings.shop_logo && (
//                 <img src={storedUserData?.invoice_settings.shop_logo} alt="Shop Logo" className="w-24 h-auto mx-auto" />
//               )}
//             </td>
//           </tr>
//         </tbody>
//       </table>

//       {/* Table Head */}
//       <div className="grid grid-cols-5 text-center bg-blue-600 text-white font-semibold py-2">
//         <div className="border border-gray-300 col-span-1">SL No.</div>
//         <div className="border border-gray-300 col-span-2">Product Name</div>
//         <div className="border border-gray-300 col-span-1">Purchase Price</div>
//         <div className="border border-gray-300 col-span-1">Current Stock</div>
//       </div>

//       {/* Table Body */}
//       {detailsData.map((cData, index) =>
//         cData?.completed && (
//           <ProductStockReportItem
//             categoryData={cData}
//             tabActive={activeTab}
//             key={index}
//             index={index}
//           />
//         )
//       )}

//       {/* Summary */}
//       <div className="border-t border-gray-300 mt-6 pt-4 text-sm">
//         <div className="flex justify-between items-center border-b pb-2">
//           <p>Total Sold Amount</p>
//           <p>{formatPrice(fullApiData?.total_sales_amount)}</p>
//         </div>
//         <div className="flex justify-between items-center border-b py-2">
//           <p>Total Purchase Amount</p>
//           <p>{formatPrice(fullApiData?.total_purchase_amount)}</p>
//         </div>
//         <div className="flex justify-between items-center border-b py-2">
//           <p>Total Stock</p>
//           <p>{formatPrice(sumStock)}</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProductStockDetails;

import React from "react";
import LogoAlignLeft from "./bigLogo.png";
import { formatDate, formatPrice } from "../../utils/Common";

const ProductStockDetails = ({
  activeTab,
  detailsData,
  fullApiData,
  startDate,
  endDate,
}) => {
  const storedUserData = JSON.parse(localStorage.getItem("user"));
  const todayDate = new Date().toLocaleDateString();
  const sumStock = detailsData.reduce(
    (total, cData) => (cData?.completed ? total + cData?.current_stock : total),
    0
  );


  return (
    <div id="productStockHistory" className="p-6">
      {/* Header */}
      {/* <div className="flex justify-between items-center mb-6 border-b-2 border-gray-700 pb-4">
        <div className="w-1/4">
          {storedUserData?.invoice_settings?.shop_logo && (
            <img
              src={storedUserData.invoice_settings.shop_logo}
              alt="Shop Logo"
              className="w-28 h-auto object-contain"
            />
          )}
        </div>
        <div className="text-right">
          <h2 className="text-xl font-bold text-gray-900">Product Stock Report</h2>
          <p className="text-sm text-gray-600">{todayDate}</p>
        </div>
      </div> */}

      {/* Shop Details */}
      {/* <table className="table-auto w-full text-sm border-collapse mb-4">
        <tbody>
          <tr className="border-b">
            <td className="py-2">
              <div>Shop Name: {storedUserData?.outlet_name}</div>
              <div>Email: {storedUserData?.email}</div>
              <div>Address: {storedUserData?.address}</div>
            </td>
            <td className="py-2">
              <div>Date: {todayDate}</div>
              <div>Mobile: {storedUserData?.phone}</div>
            </td>
          </tr>
        </tbody>
      </table> */}

      <div className="flex justify-between items-start mb-4 border-b-2 border-black pb-2">
        {/* First Column: Title and Date Range */}
        <div>
          <h1 className="font-bold text-sm">Product Stock Report</h1>
          {/* <p className="text-xs text-gray-600">{`${formData.start_date} to ${formData.end_date}`}</p> */}
          <p className="text-xs text-gray-600">
            {startDate && endDate ? (
              <span>
                {new Date(startDate).toLocaleDateString("en-GB")} -{" "}
                {new Date(endDate).toLocaleDateString("en-GB")}
              </span>
            ) : (
              new Date(todayDate).toLocaleDateString("en-GB")
            )}
          </p>
        </div>

        {/* Second Column: Shop Details */}
        <div className="text-left">
          <p className="text-lg font-bold">{storedUserData?.outlet_name}</p>
          <p className="text-xs text-gray-600">{storedUserData?.address}</p>
          <p className="text-xs text-gray-600">
            Mobile- {storedUserData?.phone}
          </p>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse border border-gray-400 font-nunito">
          {/* Table Head */}
          <thead>
            <tr
              style={{ backgroundColor: "#d3e3be", border: "2px solid black" }}
            >
              <th className="px-2 text-left text-xs">SL No.</th>
              <th className="px-2 text-left text-xs">Product Name</th>
              <th className="px-2 text-center text-xs">Purchase Price</th>
              <th className="px-2 text-center text-xs">Current Stock</th>
              <th className="px-2 text-center text-xs">Total Price</th>
            </tr>
          </thead>

          {/* Table Body */}
          <tbody>
            {detailsData.map((cData, index) =>
              cData?.completed ? (
                <tr
                  key={index}
                  className="border font-normal border-gray-400 hover:bg-gray-100"
                >
                  <td className="px-2 text-xs">{index + 1}</td>
                  <td className="px-2 text-xs">{cData?.name || "N/A"}</td>
                  <td className="px-2 text-center text-xs">
                    {formatPrice(cData?.purchase_price)}
                  </td>
                  <td className="px-2 text-center text-xs">
                    {cData?.current_stock}
                  </td>
                  <td className="px-2 text-center text-xs">
                    {formatPrice(
                      parseInt(cData?.current_stock) *
                        formatPrice(cData?.purchase_price)
                    )}
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
          <thead>
            <tr className="bg-gray-100">
              <th className="px-2 text-left text-xs">Total</th>
              <th className="px-2 text-left text-xs">
                Total Sales - {formatPrice(fullApiData?.total_sales_amount)}
              </th>
              <th className="px-2 text-center text-xs">
                {formatPrice(fullApiData?.total_purchase_amount)}
              </th>
              <th className="px-2 text-center text-xs">{sumStock}</th>
              <th className="px-2 text-center text-xs">
                {formatPrice(
                  detailsData.reduce((total, cData) => {
                    return cData?.completed
                      ? total + cData?.current_stock * cData?.purchase_price
                      : total;
                  }, 0)
                )}
              </th>
            </tr>
          </thead>
        </table>
      </div>
      {/* Summary Section */}
      {/* <div className="mt-6 text-right text-sm">
        <p className="font-semibold">
          Total Stock: <span>{formatPrice(sumStock)}</span>
        </p>
        <p className="font-semibold">
          Total Sold Amount: <span>{formatPrice(fullApiData?.total_sales_amount)}</span>
        </p>
        <p className="font-semibold">
          Total Purchase Amount: <span>{formatPrice(fullApiData?.total_purchase_amount)}</span>
        </p>
      </div> */}
    </div>
  );
};

export default ProductStockDetails;
