import React, { useState } from "react";

const CustomerWiseDueTable = ({ report, index, total_due }) => {

  return(
    <div className="grid grid-cols-6 md:gap-5  gap-2 items-center justify-start font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
      <div className="text-left">SL</div>
      <div>
        <div className="">
          <div className="font-[500] text-[#000232]">{report?.name}</div>
          <div className="font-[700] text-[#87889E]">#{report?.id}</div>
        </div>
      </div>
      <div>
        <p className="text-start">{report?.paid_amount?.toFixed(2)}</p>
      </div>
      <div>
        <p className="text-start">{report?.after_discount?.toFixed(2)}</p>
      </div>
      <div>
        <p className="text-start">{report?.remaining?.toFixed(2)}</p>
      </div>
      <div>
        <p className="text-start">{report?.invoice_id}</p>
      </div>
{/*       <div className="col-span-6 flex items-center justify-end mr-10 mt-5">
        <p className="font-bold text-[#000232] mr-2">Total Due:</p>
        <p className="text-start">{total_due}</p>
      </div> */}
    </div>
  );
};

export default CustomerWiseDueTable;
