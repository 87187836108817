import React, { useState } from "react";

const CustomerSummaryReport = ({ key, detail, index }) => {

  return(
    <div className="grid grid-cols-6 md:gap-5  gap-2 items-center justify-start font-nunito font-[500] text-[14px] text-[#000232] border-t-[1px] border-solid border-[#E7EBF4] py-[8px]">
      {/* <div> */}
        <div>
        <p className="text-left">{detail?.created_at.substring(0, 10)}</p>
        </div>
        <div>
        <p className="text-center">{detail?.invoice_id}</p>
        </div>
        <div>
        <p className="text-center">{detail?.product_info?.name}</p>
        </div>
        <div>
          <p className="text-center">{detail?.qty}</p>
        </div>
        <div>
          <p className="text-center">{detail?.price}</p>
        </div>
        <div>
          <p className="text-right">{detail?.total_amount}</p>
        </div>
      {/* </div> */}
    </div>
  );
};

export default CustomerSummaryReport;