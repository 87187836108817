import React, { useRef, useState, useEffect, useContext } from "react";
import ContentLayout from "../../layout/ContentLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectBox from "../../forms/Select";
import axios from "axios";
import Swal from "sweetalert2";
import { AiOutlineSearch } from "react-icons/ai";
import pdfIcon from "../../assets/images/pdf-Icon.png";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { get, post } from '../../services/apiService';
import { AppContext } from '../../context/context';
import CustomerSummaryReport from "./CustomerSummaryReport";
import CustomerSummaryPrint from "./CustomerSummaryPrint";

const CustomerSummaryPage = () => {
  const [activeTab, setActiveTab] = useState("daily");
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [toggle, setToggle] = useState(true);
  const [selected, setSelected] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const loadingCallback = (loading) => {
    setLoading(loading);
  };
  const [formData, setFormData] = useState({
    start_date: startDate,
    end_date: endDate,
    customer_id: selected
  });

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setFormData((prevFormData) => ({ ...prevFormData, start_date: date }));
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
    setFormData((prevFormData) => ({ ...prevFormData, end_date: date }));
  };

  const [customerListSelect, setCustomerListSelect] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [currentTotal, setCurrentTotal] = useState(0);
  const { updateCustomerList } = useContext(AppContext);
  const perPage = 10;
  const [searchFilter, setSearchFilter] = useState({
    keyword: "",
  })
  useEffect(() => {
    getCustomers();
  },[searchFilter]);
  const getCustomers = (page) => {
    if (searchFilter.keyword !== "") {
      const reqData = { keyword: searchFilter.keyword };
      const data = {
        keyword: searchFilter.keyword,
      }
      loadingCallback(true);
      post(`search-customer?page=${page}&limit=${perPage}`, data, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            const listData = response.data.data;
            setCustomerData(response.data)
            setCurrentTotal(listData.length)
            setCustomerListSelect(listData)
            console.log("customer names after search", listData)

            const transformedCustomerArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateCustomerList(transformedCustomerArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log(error);
          localStorage.clear();
          localStorage.setItem("token", false);
          // Clear session and local storage
          localStorage.setItem("isPinVerify", false);
          console.log("error", error)
          navigate("/login");
        });
    } else {
      loadingCallback(true);
      get(`customer-lists?page=${page}&limit=${perPage}`, token)
        .then((response) => {
          loadingCallback(false);
          if (response.data.data) {
            const listData = response.data.data;
            const customerNames = response.data.data;
            setCustomerData(response.data)
            setCurrentTotal(listData.length)
            setCustomerListSelect(listData)
            console.log("customer names before search", listData);
            const transformedCustomerArray = listData.map(item => ({
              id: item.id,
              name: item.name,
            }));
            updateCustomerList(transformedCustomerArray)
          }
        })
        .catch((error) => {
          loadingCallback(false);
          console.log("Full error object:", error);

          if (error.response) {
            console.log("Error response data:", error.response.data);
            console.log("Error response status:", error.response.status);
            console.log("Error response headers:", error.response.headers);

            if (error.response.status === 401) {
              localStorage.clear();
              localStorage.setItem("token", false);
              localStorage.setItem("isPinVerify", false);
              navigate("/login");
            } else {
              // Other error handling based on status or data
              console.error("Other API Error:", error.response.data);
            }
          } else {
            // Handle errors without a response (e.g., network issues)
            console.error("Network or other error:", error.message);
          }
        });
      }
  }

  const handleSelectChange = (name, option) => {
    const { value, label } = option;
    setSelected(value);
    setFormData({ ...formData, customer_id: value });
  };

  const [reports, setReports] = useState([]);
  const [invoice, setInvoice] = useState([]);
  const [grandNetTotal, setGrandNetTotal] = useState(0);
  const [grandNetTotalQuantity, setGrandNetTotalQuantity] = useState(0);
  const [salesDetails, setSalesDetails] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [currencyInfo, setCurrencyInfo] = useState(null);
  // report data api
  const handleCustomerSummary = () => {
    console.log("filters", formData)
    loadingCallback(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}customer-summary`, formData, {
        headers: {
          authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        loadingCallback(false);
        console.log("formdata date", formData);
        if (res.data.success) {

          const invoiceList = res.data.data.invoice_list || [];
          const flattenedSalesDetails = invoiceList.flatMap(
            (invoice) => invoice.sales_details || []
          );
          // Extract user_info from the first invoice in the list
          const shopData = invoiceList[0]?.user_info || null;
          const currencyData = invoiceList[0]?.user_info.invoice_settings.currency_info.code || null;

          setReports(res.data.data);
          setInvoice(invoiceList);
          setSalesDetails(flattenedSalesDetails);
          setGrandNetTotal(res.data.grand_net_total)
          setGrandNetTotalQuantity(res.data.grand_net_total_quantity)
          setUserInfo(shopData);
          setCurrencyInfo(currencyData);
          // console.log("all data", res.data.data)
          // console.log("invoices", invoiceList)
          // console.log("sales details", flattenedSalesDetails)
          // console.log("grand net total", res.data.grand_net_total)
          // console.log("start", res.data.start_date)
          // console.log("end", res.data.end_date)
          // console.log("shop data", shopData)
          console.log("currency", currencyData)
        }
      })
      .catch((error) => console.log(error));
  }

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current, // Ref to the printable component
    documentTitle: "Customer Summary Report", // Title of the printed document
    onAfterPrint: () => console.log("Print completed"), // Optional callback after printing
  });

  return(
    <>
      <ContentLayout>
        {/* tabs */}
        <div className="flex flex-row gap-[1%] pt-32 md:pt-0 pb-3 md:!pb-0 fixed md:static bg-[#EBF1FF] md:bg-transparent md:translate-x-0 top-0 left-0 pl-[30px] md:pl-0 w-full z-[9998]">
          <div
            onClick={() => setActiveTab("daily")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "daily"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Daily
          </div>
          <div
            onClick={() => setActiveTab("weekly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "weekly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Weekly
          </div>
          <div
            onClick={() => setActiveTab("monthly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "monthly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Monthly
          </div>
          <div
            onClick={() => setActiveTab("yearly")}
            className={`mt-3 px-3 pt-3 border-b cursor-pointer text-[16px] font-bold font-lato ${
              activeTab === "yearly"
                ? "border-[#407BFF] text-[#407BFF]"
                : "text-[#1C2F48] border-transparent"
            }`}
          >
            Yearly
          </div>
        </div>
        {/* datepicker field */}
        <div className="bg-white rounded-3xl mt-3 font-nunito p-[26px] grid grid-cols-1 gap-y-4 md:gap-y-0 md:gap-x-4 md:grid-cols-12 justify-between items-start">
          {/* card */}
          <div className="shadow-[0_0_5px_rgba(0,0,0,0.1)] w-full col-span-3 items-stretch bg-[#FF7A00] text-white flex justify-between py-7 px-3 rounded-2xl">
            <div className="space-y-1">
              <p className="font-semibold whitespace-nowrap text-xl">
                <sub className="text-[9px] font-extralight ml-1">BDT</sub>
              </p>
              <p className="text-xs font-normal">Customer Summary</p>
            </div>
          </div>
          {/* date pickers */}
          <div className="col-span-7">
            <div className="flex justify-between w-full  items-center mt-[12px] md:mt-0 flex-col md:flex-row font-nunito gap-4">
              <div className="relative border-[#C1CFEF] py-2.5 border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none z-20"
                />
                <span className="bg-white absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                  Start Date
                </span>
              </div>
              <div className="relative border-[#C1CFEF] py-2.5  border w-full flex-1 rounded-xl">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  dateFormat="dd/MM/yyyy"
                  className="w-full px-2 focus:outline-none z-20"
                />
                <span className="bg-white absolute left-2 px-1 font-semibold text-xs top-0 text-[#102048] translate-y-[-50%]">
                  End Date
                </span>
              </div>
            </div>
            {/* filter button */}

            <div className="relative mt-[.7rem]">
              {/* <SelectBox
                options={options}
                name={"CustomerSummary"}
                label={"CustomerSummary"}
                placeholder="Select Customer"
                onChange={handleSelectChange.bind(this, "Customer Summary Report")}
                value={formData["customer_name"]}
              /> */}
              <SelectBox
                name="customer_id"
                options={customerListSelect}
                value={formData["customer_id"]}
                label={"Customer"}
                onChange={handleSelectChange.bind(this, "Customer Summary Report")}
                placeholder="Select Customer"
                onInputChangeData={(input) => { setSearchFilter({...searchFilter, keyword: input})}}
              />
            </div>
          </div>
          <button
            onClick={() => handleCustomerSummary()}
            className=" bg-[#034AFF] cursor-pointer col-span-2 font-nunito text-white rounded-xl px-3 xl:px-5 py-2 text-md"
          >
            Report
          </button>
        </div>
        {/* table */}
        <div>
          <div className="bg-white rounded-3xl font-nunito mt-4 p-[26px]">
            <div className="flex justify-between items-center">
              {/* section title & switch,search & pdf button*/}
              <div className="flex gap-3 items-center">
                {/* section title */}
                <h2 className="text-xl font-semibold">Customer Summary</h2>
              </div>
              {/* switch,search & pdf button */}
              <div className="flex gap-3 items-center">
                <div className="flex relative w-[95%]">
                    <input
                      autoFocus
                      type="text"
                      className="bg-[#E5EDFF] rounded-[11px] w-[100%] pt-[14px] pr-[45px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                      placeholder="Search Transaction"
                    />
                    <div className="absolute top-[17px] left-[10px]">
                      <AiOutlineSearch className="text-base text-[#407BFF] font-bold"></AiOutlineSearch>
                    </div>

                    <div className="absolute top-[17px]  right-[10px]">
                      <div
                        className=" relative flex flex-col items-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M0.83268 12.6665C0.83268 12.3932 1.05935 12.1665 1.33268 12.1665L8.66602 12.1665C8.93935 12.1665 9.16602 12.3932 9.16602 12.6665C9.16602 12.9398 8.93935 13.1665 8.66602 13.1665L1.33268 13.1665C1.05935 13.1665 0.83268 12.9398 0.83268 12.6665Z"
                            fill="#102048"
                          />
                          <path
                            d="M10.8327 12.6665C10.8327 12.3932 11.0594 12.1665 11.3327 12.1665L14.666 12.1665C14.9393 12.1665 15.166 12.3932 15.166 12.6665C15.166 12.9398 14.9393 13.1665 14.666 13.1665L11.3327 13.1665C11.0594 13.1665 10.8327 12.9398 10.8327 12.6665Z"
                            fill="#102048"
                          />
                          <path
                            d="M0.832684 8C0.832684 7.72667 1.05935 7.5 1.33268 7.5L4.66602 7.5C4.93935 7.5 5.16602 7.72667 5.16602 8C5.16602 8.27333 4.93935 8.5 4.66602 8.5L1.33268 8.5C1.05935 8.5 0.832684 8.27333 0.832684 8Z"
                            fill="#102048"
                          />
                          <path
                            d="M6.83268 8C6.83268 7.72667 7.05935 7.5 7.33268 7.5L14.666 7.5C14.9393 7.5 15.166 7.72667 15.166 8C15.166 8.27333 14.9393 8.5 14.666 8.5L7.33268 8.5C7.05935 8.5 6.83268 8.27333 6.83268 8Z"
                            fill="#102048"
                          />
                          <path
                            d="M0.83268 3.3335C0.83268 3.06016 1.05935 2.8335 1.33268 2.8335L8.66602 2.8335C8.93935 2.8335 9.16602 3.06016 9.16602 3.3335C9.16602 3.60683 8.93935 3.8335 8.66602 3.8335L1.33268 3.8335C1.05935 3.8335 0.83268 3.60683 0.83268 3.3335Z"
                            fill="#102048"
                          />
                          <path
                            d="M10.8327 3.3335C10.8327 3.06016 11.0594 2.8335 11.3327 2.8335L14.666 2.8335C14.9393 2.8335 15.166 3.06016 15.166 3.3335C15.166 3.60683 14.9393 3.8335 14.666 3.8335L11.3327 3.8335C11.0594 3.8335 10.8327 3.60683 10.8327 3.3335Z"
                            fill="#102048"
                          />
                          <path
                            d="M8.16602 4.66667L8.16602 2C8.16602 1.72667 8.39268 1.5 8.66602 1.5C8.93935 1.5 9.16602 1.72667 9.16602 2L9.16602 4.66667C9.16602 4.94 8.93935 5.16667 8.66602 5.16667C8.39268 5.16667 8.16602 4.94 8.16602 4.66667Z"
                            fill="#102048"
                          />
                          <path
                            d="M8.16602 14.0002L8.16602 11.3335C8.16602 11.0602 8.39268 10.8335 8.66602 10.8335C8.93935 10.8335 9.16602 11.0602 9.16602 11.3335L9.16602 14.0002C9.16602 14.2735 8.93935 14.5002 8.66602 14.5002C8.39268 14.5002 8.16602 14.2735 8.16602 14.0002Z"
                            fill="#102048"
                          />
                          <path
                            d="M6.83398 9.33317L6.83398 6.6665C6.83398 6.39317 7.06065 6.1665 7.33398 6.1665C7.60732 6.1665 7.83398 6.39317 7.83398 6.6665L7.83398 9.33317C7.83398 9.6065 7.60732 9.83317 7.33398 9.83317C7.06065 9.83317 6.83398 9.6065 6.83398 9.33317Z"
                            fill="#102048"
                          />
                        </svg>
                      </div>
                    </div>
                </div>
                <div
                  onClick={handlePrint}
                  className="bg-[#EBF1FF] rounded-full w-[34px] h-[34px] flex items-center justify-center cursor-pointer transform hover:scale-110 duration-200"
                >
                  <img className="w-[16px]" src={pdfIcon} alt="pdfIcon" />
                </div>
                {/* Hidden component for printing */}
                <div style={{ display: "none" }}>
                  <CustomerSummaryPrint
                    ref={printRef}
                    salesDetails={salesDetails}
                    grandNetTotal={grandNetTotal}
                    userInfo={userInfo}
                    invoice={invoice}
                    grandNetTotalQuantity={grandNetTotalQuantity}
                    formData={formData}
                    currencyData={currencyInfo}/>
                </div>
              </div>
            </div>
            <div>
              <div className=" grid grid-cols-6 text-left text-[14px] mt-3 py-[8px] font-nunito font-bold text-[#000232]  border-b-[1px] border-[#E7EBF4]">
                  {/* <div className="text-left">
                    <p className="text-[14px] font-bold justify-start items-center flex">
                      <input
                        className="me-1"
                        type="checkbox"
                        // onChange={handleSelectAll}
                      />{" "}
                      Select All{" "}
                    </p>
                  </div> */}
                  <div className="text-left">Date</div>
                  <div className="text-center">Invoice No.</div>
                  <div className="text-center">Products</div>
                  <div className="text-center">Qty/Unit</div>
                  <div className="text-center">Price</div>
                  <div className="text-right">Total Amount</div>
              </div>
              {salesDetails.map((detail, index) => {
                return(
                  <CustomerSummaryReport
                    key={detail.id}
                    detail={detail}
                  />
                )
              })}
              <div className="flex items-center justify-end mr-10 mt-5">
                  <p className="font-bold text-[#000232] mr-2">Grand Net Total:</p>
                  <p className="text-start">{grandNetTotal?.toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      </ContentLayout>
    </>
  );

};

export default CustomerSummaryPage;