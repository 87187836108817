import axios from "axios";
import React, { useContext, useState, useRef, useEffect } from "react";
//import Select2 from "react-chosen";
import Resizer from "react-image-file-resizer";
import { useNavigate } from 'react-router-dom';
import { uploadFile,formatPrice,invoiceCalculate } from '../utils/Common';
import { AppContext } from '../context/context';
import ImageUploader2 from './ImageUploader2';
import { get,post } from "../services/apiService";
import { useFlashMessage } from "../context/FlashMessageContext";
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';


import classNames from 'classnames';
import { set } from 'react-hook-form';
import SelectImei from "./SelectImei";
import SelectBox from "./Select";
import { FlashMessage } from "../utils/FlashMessage";


const SalesReturnForm = props => {
const [productListSelect, setProductListSelect] = useState([]);
const [productSelect, setProductSelect] = useState('');
const [searchProdFilter, setSearchProdFilter] = useState({
  keyword: "",
})
    const {
        loadingCallback,
        navigate,
        setShowModal,
        updateModalTitle,
        setWarningMessage,
        type
    } = props;
    const showModalWarning = (msg, data = null) => {
      Swal.fire({
        title: msg,
        text: "",
        icon: 'warning',
      });
    };
    const { state, isLoading, updateInvoiceList } = useContext(AppContext);
    const { invoiceList, invoiceSetting } = state;
    const token = localStorage.getItem('token');
    const { getFlashMessageStyle, setSuccessFlashMessage, flashMessage, setErrorFlashMessage } = useFlashMessage(); // Retrieve the dispatch function from your state management library

    const [totalQty, setTotalQty] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [singleProductPrice, setSingleProductPrice] = useState(0);
    const [imei, setImei] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const handleReturnCheckboxChange = () => {
      setIsChecked(!isChecked);
    };
    const handleCheckboxChange = (item, index) => {


      // Check if the clicked checkbox is already selected
      const isSelected = selectedProducts.includes(item);

      if (isSelected) {
        // If the item is already selected, unselect it
        setSelectedProducts([]);
        setTotalQty(0);
        setTotalPrice(0);
      } else {
        // Check if there are already selected items
        if (selectedProducts.length > 0) {
          // Show an alert and return without changing the selection
          // alert("Only one selection allowed.");
          // return;
          showModalWarning('Please select one product at a time');
          return;
        }
        // If the item is not selected, select it
        setSelectedProducts([item]);
        if(item?.product_imei && Array.isArray(item.product_imei) && item.product_imei[index]?.imei)
          setImei((prevImei) => {
            // Create a copy of the current imei state
            const updatedImei = [...prevImei];

            // Update the specific index with the new value
            updatedImei[index] = item.product_imei[index].imei;

            return updatedImei;
          });
        setTotalQty(Number(item.qty));
        setSingleProductPrice(Number(item.price));
        setTotalPrice(Number(item.qty) * Number(item.price));
      }
    };

    const [currentPage, setCurrentPage] = useState(1);
    const [returnedQty, setReturnedQty] = useState(null);
    const [returnedAmount, setReturnedAmount] = useState(null);
    const perPage = 20;
    const [showModalDescription, setShowModalDescription] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');

    const handleQtyChange = (value) => {
      if (value > totalQty) {
        return;
      } else {
        setReturnedQty(value);
        setReturnedAmount(value * singleProductPrice);
        setTotalPrice(value * singleProductPrice);
      }

    };

    useEffect(() => {
        getInvoicelist(type,searchKeyword);
    }, [type,searchKeyword,productSelect]);
    const [filteredInvoice, setFilteredInvoice] = useState([]);
    const getInvoicelist = async(type,searchKeyword='') => {
        loadingCallback(true);

        if (searchKeyword !== "" || productSelect !== '') {
            setFilteredInvoice([{ id: '', name: 'Select Invoice' }]);
            const page = 1;
            const reqData = { keyword: searchKeyword, product_id: productSelect };
            const url = type === 'salesReturnList' ? `search-invoice` : `search-purchase-invoice`;
            post(url, reqData, token)
            .then((response) => {
                loadingCallback(false);
                const options = [];
                if(response.status === 200)
                {
                    const listData = response.data.data;
                    if (listData.length > 0) {
                        const invoiceOptions = listData.map(item => ({
                            id: item.id,
                            //icon: <RiMoneyDollarCircleLine/>,
                            name: item.invoice_id,
                            //sname: item.customer_name,
                            sales_details: type === 'salesReturnList' ? item.sales_details : item.purchase_details,
                            price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').grandTotal) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').grandTotal)+' BDT',
                            due: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').dueAmount) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').dueAmount)+' BDT',
                            //time: timeDiff(new Date(item.updated_at)),
                            colors: "#00A3EE",
                            created_at: item.created_at
                        }));
                        options.push(...invoiceOptions);
                    } else {
                        console.log("Data Not Found!");
                    }
                    setFilteredInvoice(options);
                }
            })
            .catch((error) => {
                loadingCallback(false);
                console.log(error);
                if (error?.response?.status === 401) {
                    localStorage.clear();
                    localStorage.setItem('token', false);
                    // Clear session and local storage
                    localStorage.setItem('isPinVerify', false);

                    navigate('/login');
                }
            });
        } else {
            const url = type === 'salesReturnList' ? `invoice-list?page=1&limit=20` : `purchase-invoice-list?page=1&limit=20`;
            setFilteredInvoice([]);
            get(url, token)
                .then((response) => {
                    loadingCallback(false);
                    const options = [];
                    if (response.data.data) {
                        const listData = response.data.data;

                        if (listData.length > 0) {
                            const invoiceOptions = listData.map(item => ({
                                id: item.id,
                                //icon: <RiMoneyDollarCircleLine/>,
                                name: item.invoice_id,
                                //sname: item.customer_name,
                                sales_details: type === 'salesReturnList' ? item.sales_details : item.purchase_details,
                                price: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').grandTotal ) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').grandTotal)+' BDT',
                                due: invoiceSetting?.currency_info?.code ? formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').dueAmount) +' '+invoiceSetting.currency_info.code : formatPrice(invoiceCalculate(item,type === 'salesReturnList' ? 'sales' : 'purchase').dueAmount)+' BDT',
                                //time: timeDiff(new Date(item.updated_at)),
                                colors: "#00A3EE",
                                created_at: item.created_at
                            }));
                            options.push(...invoiceOptions);
                        } else {
                            console.log("Data Not Found!");
                        }
                        setFilteredInvoice(options)
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const onPageChange = (page) => {
        setCurrentPage(page);
    };
    const shouldRenderPagination = invoiceList.total > perPage;

    //const handleShowModal = () => setShowModalDescription(true);
    const handleShowModal = () => {
        updateModalTitle('Return Description');
        setShowModalDescription(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const formObject = {};

        formData.forEach((value, key) => {
            formObject[key] = value;
        });
        if (type==='salesReturnList') {
          formObject.return_stock_status = isChecked ? 1 : 0;
        }
        if (selectedInvoice) {
            formObject.invoice_id = selectedInvoice.name;
        }
        const transformedProductArray = selectedProducts.map(item => ({
            product_id: item?.product_id,
            return_qty: formObject.return_qty,
            return_amount: formObject.return_amount,
            return_unit_price: item.price,
            return_stock_status: isChecked ? 1 : 0,
            details_id: item.id,
            imei: imei
        }));
        formObject.product = transformedProductArray;
        // console.log("============");
        // console.log(formObject);
        // console.log(selectedProducts);



        loadingCallback(true);

        const url = type === 'salesReturnList' ? `save-sales-return` : `save-purchase-return`;
        post(url, formObject, token)
        .then((response) => {
          loadingCallback(false);
          if (response.status === 200) {
            //setCustomer(response.data);
            setShowModal(false);
            setShowModalDescription(false);
            // setSuccessFlashMessage('invoice returned successfully');
            FlashMessage('invoice returned successfully','success');
          } else {
            console.log("invoice return failed");
            // setErrorFlashMessage('invoice return failed');
            FlashMessage('invoice return failed','error');
            setWarningMessage(true);
            const timer = setTimeout(() => {
                setWarningMessage(false);
            }, 3000);
            return () => clearTimeout(timer);
          }
        })
        .catch((error) => {
          loadingCallback(false);
          if (error?.response?.status === 401) {
            localStorage.clear();
            localStorage.setItem('token', false);
            // Clear session and local storage
            localStorage.setItem('isPinVerify', false);

            navigate('/login');
          }
          console.log(error);
        });
    }
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const handleInvoiceSelect = (event) => {
        console.log(event)
        // if (event.target.value === "") {
        //     setSelectedInvoice(null);
        //     return;
        // }
        if (event.value === "") {
            // setSelectedInvoice(null);
            return;
        }

        /*Previous data reset*/
        setSelectedInvoice(null);
        setSelectedProducts([]);
        setTotalQty(0);
        setTotalPrice(0);

        // const invoiceId = parseInt(event.target.value);
        // const selectedInvoiceData = filteredInvoice.find((invoice) => invoice.id === invoiceId);
        // setSelectedInvoice(selectedInvoiceData);
         const invoiceId = parseInt(event.value);
        const selectedInvoiceData = filteredInvoice.find((invoice) => invoice.id === invoiceId);
        setSelectedInvoice(selectedInvoiceData);
        // setShowNewCustomerForm(false);
        // // Update the value of the input field
        // if (selectedCustomer.district) {
        //     getCityList(selectedCustomer.district);
        // }
    };

    const backModalDescription = () => {
        setShowModalDescription(false);
    };


    useEffect(() => {
      getProductList(currentPage);
    }, [currentPage, searchProdFilter]);

    const getProductList = (page) => {
      if (searchProdFilter.keyword !== "") {
        const reqData = { keyword: searchProdFilter.keyword };
        const data = {
          keyword: searchProdFilter.keyword,
        }
        loadingCallback(true);
        post(`search-product-v1?page=${page}&limit=${perPage}`, data, token)
          .then((response) => {
            loadingCallback(false);
            if (response.status === 200) {
              const listData = response.data.data;
              // setProductData(response.data)
              // setProductTotal(listData.length)
              setProductListSelect(listData)
              console.log("product names after search", listData)

              const transformedProductArray = listData.map(item => ({
                id: item.id,
                name: item.name,
              }));
              //updateProductList(transformedProductArray)
            }
          })
          .catch((error) => {
            loadingCallback(false);
            console.log(error);
            localStorage.clear();
            localStorage.setItem("token", false);
            localStorage.setItem("isPinVerify", false);
            console.log("error", error)
            navigate("/login");
          });
      } else {
        loadingCallback(true);
        get(`product?page=${page}&limit=${perPage}`, token)
          .then((response) => {
            loadingCallback(false);
            if (response?.data?.data) {
              const listData = response?.data?.data;
              // setProductData(response.data)
              // setProductTotal(listData.length)
              setProductListSelect(listData)
              console.log("product names before search", listData);
              const transformedProductArray = listData.map(item => ({
                id: item.id,
                name: item.name,
              }));
              //updateProductList(transformedProductArray)
            }
          })
          .catch((error) => {
            loadingCallback(false);
            console.log("Full error object:", error);

            if (error.response) {
              console.log("Error response data:", error.response.data);
              console.log("Error response status:", error.response.status);
              console.log("Error response headers:", error.response.headers);

              if (error.response.status === 401) {
                localStorage.clear();
                localStorage.setItem("token", false);
                localStorage.setItem("isPinVerify", false);
                navigate("/login");
              } else {
                // Other error handling based on status or data
                console.error("Other API Error:", error.response.data);
              }
            } else {
              // Handle errors without a response (e.g., network issues)
              console.error("Network or other error:", error.message);
            }
          });

      }
    };




    return (
        <form
            method="get"
            className="col-sm-12 min-h-[40vh]"
            data-autosubmit="false"
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            <ToastContainer />
            {!showModalDescription ? (
                <div className="flex flex-col">
                    {/* <div className="flex flex-row mb-3">
                        <input
                            autoFocus
                            type="text"
                            id="invoiceSearch"
                            name="invoiceSearch"
                            className="bg-[#F1F1FA] rounded-[11px] w-[100%] pt-[14px] pr-[28px] pl-[35px] pb-[12px] placeholder:text-[#91919F] placeholder:text-[14px] focus:outline-none"
                            placeholder="Search Invoice"
                            value={searchKeyword}
                            onChange={(e) => {
                                setSearchKeyword(e.target.value);
                            }}
                        />
                    </div>


                    {filteredInvoice && filteredInvoice.length > 0 && (
                        <div className="flex flex-row mb-3">

                            <div className="w-full d-flex align-items-center">
                                <select
                                    id="invoice_id"
                                    name="invoice_id"
                                    className={classNames(
                                        'w-full',
                                        'rounded-[15px]',
                                        'p-3',
                                        'bg-white'
                                    )}
                                    style={{  border: "1px solid #ccc" }}
                                    onChange={handleInvoiceSelect}
                                    value={selectedInvoice ? selectedInvoice.id : ""}
                                >
                                    {filteredInvoice && filteredInvoice.map((invoice) => (
                                        <option key={invoice.id} value={invoice.id}>
                                            {invoice.name}
                                        </option>
                                    ))}
                                </select>

                            </div>
                        </div>
                    )} */}

      <div className="mb-3">
        <label htmlFor="invoiceSelect" className="form-label">
          Select Invoice
        </label>

        <div className="d-flex align-items-center">
          <SelectBox
            name="invoice_id"
            options={filteredInvoice}
            onChange={handleInvoiceSelect}
            value={selectedInvoice ? selectedInvoice.id : ""}
            onInputChangeData={inputText=>setSearchKeyword(inputText)}
            placeholder="Select Invoice"
            className={classNames(
              'w-full',
              'rounded-[15px]',
              'bg-white'
            )}
          />

        </div>
    </div>



    <div className="mb-3">
        <label htmlFor="invoiceSelect" className="form-label">
          Select Product
        </label>

        <div className="d-flex align-items-center">
            {console.log('products ',productListSelect)}
        <SelectImei
                  name="product_name"
                  options={productListSelect}
                  value={productListSelect[0]}
                  label={"Product"}
                  placeholder="Select Product"
                  onChange={(e) => {
                    console.log('products onchange ', e.id)
                    setProductSelect(e.id);
                        }}
                  onInputChangeData={(input) => { setSearchProdFilter({...searchProdFilter, keyword: input})}}
                />

        </div>
    </div>


                    {selectedInvoice && (

                    <>
                        {(() => { // Enclose the block of code in an IIFE (Immediately Invoked Function Expression)
                            const invoiceDate = new Date(selectedInvoice.created_at);
                            const year = invoiceDate.getFullYear();
                            const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
                            const day = String(invoiceDate.getDate()).padStart(2, '0');
                            const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
                            const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
                            const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';

                            //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
                            const formattedDate = `${year}-${month}-${day}`;
                            const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
                            // let totalPrice = 0;
                            // let totalQty = 0;
                            // You can perform any desired operations with the declared variables here
                            return (
                                <>
                                    <div className="border-1 border-solid border-[#F1F1FA] rounded-[15px] px-[16px] py-[12px] flex flex-col">
                                        <div className="flex flex-row justify-between mb-[16px]">
                                            <div className="text-[#000232] text-[12px] font-bold font-lato">{type === 'salesReturnList' ? 'Sold Product List' : 'Purchased Product List'}</div>
                                            <div className="text-[#000232] text-[12px] font-500 font-lato">Date : {formattedDateTime}</div>
                                        </div>

                                        <div className="flex flex-col">
                                            <div className="text-[#000232] text-[12px] font-bold mb-[10px] flex flex-row">
                                                <div className="text-left w-[30%]">Product Name</div>
                                                <div className="text-left w-[30%]">Unit Price</div>
                                                <div className="text-center w-[10%]">Qty</div>
                                                <div className="text-right w-[30%]">Total Amount</div>
                                            </div>

                                            {/* {selectedInvoice.sales_details.map((item) => {
                                                //totalQty += Number(item.qty);
                                                const subtotalPrice = formatPrice(item.qty * item.price);
                                                //totalPrice += Number(subtotalPrice);
                                                return (
                                                    <div key={item.id} className="border-t-[1px] border-solid py-[11px] border-[#E7EBF4] flex flex-row gap-1 md:gap-2">
                                                        <div className="w-[30%] flex flex-row items-center gap-2">
                                                            <input
                                                              type="checkbox"
                                                              name="product"
                                                              id={`product_${item.product_id}`}
                                                              value=""
                                                              className="rounded-[6px]"
                                                              checked={selectedProducts.includes(item)}
                                                              onChange={() => handleCheckboxChange(item)}
                                                              //onChange={(e) => handleCheckboxChange(e,item)}
                                                               />
                                                            <div className="flex flex-col justify-left items-left">
                                                              <label className="text-[#000232] text-[14px] font-nunito font-400">{item?.product_info?.name}</label>
                                                              <label className="text-[#000232] text-[10px] font-nunito font-100">{item?.product_imei[0]?.imei}</label>
                                                            </div>

                                                        </div>
                                                        <div className="w-[30%] text-[#000232] text-[12px] font-nunito font-400">{item.price}</div>
                                                        <div className="w-[10%] text-[#000232] text-[12px] font-nunito font-400 text-center">{item.qty}</div>
                                                        <div className="w-[30%] text-[#000232] text-[14px] font-nunito font-400 text-right">{subtotalPrice} BDT</div>
                                                    </div>
                                                );
                                            }
                                            )} */}

                              {selectedInvoice.sales_details.map((item, index) => {
                                  const subtotalPrice = formatPrice(item.qty * item.price);

                                  const imei = item?.product_imei && Array.isArray(item.product_imei) && item.product_imei[0]?.imei
                                  ? item.product_imei[0]?.imei
                                  : "";

                                  return (
                                      <div
                                          key={item.id}
                                          className="border-t-[1px] border-solid py-[11px] border-[#E7EBF4] flex flex-row gap-1 md:gap-2"
                                      >
                                          <div className="w-[30%] flex flex-row items-center gap-2">
                                              <input
                                                  type="checkbox"
                                                  name="product"
                                                  id={`product_${item.product_id}`}
                                                  value=""
                                                  className="rounded-[6px]"
                                                  checked={selectedProducts.includes(item)}
                                                  onChange={() => handleCheckboxChange(item, 0)}
                                              />
                                              <div className="flex flex-col justify-left items-left">
                                                <label className="text-[#000232] text-[14px] font-nunito font-400">
                                                    {item?.product_info?.name}
                                                </label>
                                                <label className="text-[#000232] text-[12px] font-nunito font-100 mt-1">
                                                    {imei && imei}
                                                </label>
                                              </div>
                                          </div>
                                          <div className="w-[30%] text-[#000232] text-[12px] font-nunito font-400">{item.price}</div>
                                          <div className="w-[10%] text-[#000232] text-[12px] font-nunito font-400 text-center">{item?.remaining_qty ? item?.remaining_qty : item?.qty }</div>
                                          <div className="w-[30%] text-[#000232] text-[14px] font-nunito font-400 text-right">{subtotalPrice} BDT</div>
                                      </div>
                                  );
                              })}

                                        </div>
                                    </div>

                                    <input onClick={handleShowModal} type="submit" className="w-full mt-[25px] bg-[#034AFF] text-[18px] text-[#FCFCFC] py-[6px] rounded-[16px] font-nunito font-600" name="order" value="Next" />
                                </>
                            );
                        })()}
                    </>
                    )}
                </div>
            ) : (
                <div className="flex flex-col">

                    {selectedInvoice && (

                    <>
                        {(() => { // Enclose the block of code in an IIFE (Immediately Invoked Function Expression)
                            const invoiceDate = new Date(selectedInvoice.created_at);
                            const year = invoiceDate.getFullYear();
                            const month = String(invoiceDate.getMonth() + 1).padStart(2, '0');
                            const day = String(invoiceDate.getDate()).padStart(2, '0');
                            const hours = String(invoiceDate.getHours() % 12).padStart(2, '0');
                            const minutes = String(invoiceDate.getMinutes()).padStart(2, '0');
                            const ampm = invoiceDate.getHours() >= 12 ? 'PM' : 'AM';

                            //const shop_logo = `${BASE_URL}/layoutlogo.svg`;
                            const formattedDate = `${year}-${month}-${day}`;
                            const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}${ampm}`;
                            //let totalPrice = 0;
                            //let totalQty = 0;
                            {selectedInvoice.sales_details.map((item) => {
                                //totalQty += Number(item.qty);
                                const subtotalPrice = formatPrice(item.qty * item.price);
                                //totalPrice += Number(subtotalPrice);
                            })}
                            // You can perform any desired operations with the declared variables here
                            return (
                                <>
                                    <textarea
                                    className="bg-white border-1 border-solid border-[#F1F1FA] min-h-[110px] py-[10px] px-[16px] rounded-[15px] focus:outline-none"
                                    placeholder="Return Description"
                                    name="return_description"></textarea>

                                    <div className="flex flex-row gap-3 mt-[16px]">
                                        <div className="flex-1 flex flex-row justify-between px-[16px] py-[19px] border-1 border-solid border-[#F1F1FA] rounded-[15px]">
                                            <div className="text-[#000232] text-[14px] font-500">Purchase Qty</div>
                                            <input type="text" className="bg-white border-none w-[30px] focus:outline-none" placeholder="" value={totalQty} />
                                        </div>
                                        <div className="flex-1 flex flex-row justify-between px-[16px] py-[19px] border-1 border-solid border-[#F1F1FA] rounded-[15px]">
                                            <div className="text-[#000232] text-[14px] font-500">Return Qty</div>
                                            <input
                                            type="text"
                                            name="return_qty"
                                            className="bg-white w-[49px] border-1 border-solid border-[#000232] rounded-[4px] focus:outline-none"
                                            placeholder=""
                                            onChange={(e) => {
                                              e.preventDefault();
                                              // Validate input to allow only numbers
                                              const inputValue = e.target.value;
                                              const onlyNumbers = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
                                              handleQtyChange(onlyNumbers);
                                            }}
                                            value={returnedQty !== null ? returnedQty : totalQty} // Use the updated value in the input field
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-row items-center gap-3 mt-[16px]">
                                        <input
                                            type="text"
                                            name="return_amount"
                                            className="bg-white w-[70%] border-1 border-solid border-[#F1F1FA] rounded-[15px] py-[18px] px-[16px] focus:outline-none"
                                            placeholder="Return Amount"
                                            onChange={(e) => {
                                                e.preventDefault();
                                                setReturnedAmount(e.target.value?e.target.value:'');
                                            }}
                                            value={returnedAmount !== null ? returnedAmount :totalPrice}
                                        />
                                        <div className="flex-1 text-[24px] text-[#000232] font-bold font-nunito text-right">{totalPrice} BDT</div>
                                    </div>
                                    {type === 'salesReturnList' && (
                                    <div className="flex flex-row items-center gap-2 mt-[16px]">
                                      <input
                                      type="checkbox"
                                      name="status"
                                      id="return_stock"
                                      checked={isChecked}
                                      onChange={handleReturnCheckboxChange}
                                      />
                                      <label for="return_stock" className="text-[#91919F] text-[16px] font-nunito font-400">Return to Stock</label>
                                    </div>
                                    )}

                                    <div className="mt-[25px] flex flex-row gap-2 items-center font-nunito font-[600] text-[18px] text-center">
                                    <button  onClick={backModalDescription} type="button" className="w-[50%] rounded-[16px] py-[7px] text-[#034AFF] border-[1px] border-solid border-[#034AFF]">Back</button>
                                       <button
                                        type="submit"
                                        className="w-[50%] bg-[#034AFF] text-[#FCFCFC] py-[8px] rounded-[16px]"
                                        > Done
                                        </button>
                                    </div>
                                </>
                            );
                        })()}
                    </>
                    )}

                </div>
            )}



        </form>
    );
};

export default SalesReturnForm;
