import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ContentLayout from "../../layout/ContentLayout";
import arrowLeft from "../../assets/images/arrow-left.png";
import { ToastContainer, toast } from 'react-toastify';
import Input from "../../forms/Input";
import DatePicker from "react-datepicker";
import { AppContext } from "../../context/context";
import { useFlashMessage } from '../../context/FlashMessageContext';
import { FlashMessage } from "../../utils/FlashMessage";
import { get, post } from "../../services/apiService";
import CommonUploader from "../../forms/CommonUploader.js";
import Resizer from "react-image-file-resizer";
import { useLocation } from 'react-router-dom';
import Swal from "sweetalert2";
import {
  uploadFileMultipleInputVariants,
} from '../../utils/Common';

const EmployeeEditPage = () => {

  const { id } = useParams();
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const loadingCallback = loading => {
    setLoading(loading);
  };
  const navigate = useNavigate();
  const [warningMessage, setWarningMessage] = useState(false);

  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [formData, setFormData] = useState({
    emp_name: "",
    emp_email: "",
    emp_phone: "",
    dob: "",
    blood_group: "",
    address: "",
    emergency_contact: "",
    emergency_contact_rel: "",
    work_email: "",
    password: "",
    designation: "",
    emp_role: "",
    join_date: null,
    status: "",
    id: "",
    image: "",
    u_email: "",
    link: "",
    warehouse: "",
    department: ""
  });

  //---------------------
  const location = useLocation();
  const [hasPermission, setHasPermission] = useState(false);
  useEffect(() => {
    const employeeApiResponse = localStorage.getItem("employee");
    if (!employeeApiResponse) {
      setHasPermission(true);
      return;
    }
    if (employeeApiResponse) {
      const employeeData = JSON.parse(employeeApiResponse);
      const hrmFeature = employeeData.role.features.find(
        (feature) => feature.name === "HRM"
      );
      if (hrmFeature && hrmFeature.status === 1) {
        const editHrmOptionActive = hrmFeature.feature_options.find(
          (option) => option.name === "Edit" && option.status === 1
        );
        if (editHrmOptionActive && location.pathname === "employee-edit/:id") {
          setHasPermission(true);
          return;
        }
      }
      setHasPermission(false);
      navigate("/dashboard", { replace: true });
      setTimeout(() => {
        Swal.fire({
          icon: "error",
          title: "Permission Denied",
          text: "You do not have permission for this feature.",
        });
      }, 100);
    }
  }, [navigate, location]);
  //---------------------

  useEffect(() => {
    if (id) {
      if (hasPermission) {
        getEmployeeDetails(id);
      }
    }
  }, [id,hasPermission]);

  const getEmployeeDetails = (id) => {
    loadingCallback(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}employee/${id}`,{
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      if(response?.data.success){
        loadingCallback(false)
        console.log("employee details",response);
        const employee = response?.data?.data;
        const employeeWorkingDays = employee.working_day ? employee.working_day.split(',') : [];
        setSelectedDays(employeeWorkingDays);
        setFormData({
          id: employee.id || "",
          emp_name: employee.name || "",
          emp_email: employee.email || "",
          emp_phone: employee.mobile_number || "",
          dob: employee.dob || "",
          blood_group: employee.blood_group_id || "",
          emergency_contact: employee.emergency_mobile_number || "",
          emergency_contact_rel: employee.relation_with || "",
          designation: employee.designation_id || "",
          emp_role: employee.role_id || "",
          join_date: employee.joining_date || "",
          status: employee.status || "",
          working_day: employee.working_day || "",
          image: employee.emp_image || "",
          u_email: employee.u_email || "",
          address: employee.address || "",
          //pin: employee.pin || "",
          work_email: employee.work_email || "",
          password: "",
          u_email: employee.u_email || "",
          link: employee.link || "",
          warehouse: employee.warehouse_id || "",
          department: employee.department_id || ""
        });
      }
    })
    .catch((error) => {
      navigate('/login');
      console.log(error);
    });
  };

  //----------
  const { state, updateBloodGroups } = useContext(AppContext);
  const [bloodGroupList, setBloodGroupList] = useState(
    state?.bloodGroups?.map((item) => ({ ...item, name: item.blood_group_name }))
  );
  useEffect(() => {
    if(hasPermission){
    getBloodGroupList();
    }
  }, [hasPermission]);
  const getBloodGroupList = () => {
    axios
    .get(
        `${process.env.REACT_APP_API_URL}get-blood-group`,
        {
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        }
    )
    .then((response) => {
        if(response.data.success)
        {
          updateBloodGroups(response.data.data)
          setBloodGroupList(response.data.data.map((item) => ({ ...item, name: item.blood_group_name })));
        }
    })
    .catch((error) => {
        console.log(error);
    });
  };
  //---------
  //--------
  const [designationList, setDesignationList] = useState([]);
  useEffect(() => {
    if(hasPermission){
    getDesignations();
    }
  }, [hasPermission]);
  const getDesignations = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}designation`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setDesignationList(response.data.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };
  //------------
  const [roleList, setRoleList] = useState([]);

  useEffect(() => {
    if(hasPermission){
    getRoles();
    }
  }, [hasPermission]);

  const getRoles = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}role-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          setRoleList(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };
  //----------
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const [selectedDays, setSelectedDays] = useState([]);
  const handleDayClick = (day) => {
    let updatedDays = [...selectedDays];
    if (updatedDays.includes(day)) {
      // Remove the day if it's already selected
      updatedDays = updatedDays.filter((d) => d !== day);
    } else {
      // Add the day if it's not selected
      updatedDays.push(day);
    }
    setSelectedDays(updatedDays);
    // Update formData with comma-separated string of selected days
    setFormData({
      ...formData,
      working_day: updatedDays.join(',')
    });
  };
  //---------
  const [departmentList, setDepartmentList] = useState([]);

  useEffect(() => {
    if(hasPermission){
    getDepartments();
    }
  }, []);

  const getDepartments = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}department`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          const dListData= response.data.data.data
          console.log("department list", dListData)
          setDepartmentList(dListData);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };
  //-----------
  const [warehouseList, setWarehouseList] = useState([]);

  useEffect(() => {
    if(hasPermission){
    getWarehouses();
    }
  }, [hasPermission]);

  const getWarehouses = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}warehouses-list`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          const wListData= response.data.data
          console.log("warehouse list", wListData)
          setWarehouseList(wListData);
        }
      })
      .catch((error) => {
        console.error("Error fetching designations:", error);
      });
  };


  //-----------

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "blood_group" && value === "Select Blood Group") {
      setFormData({
        ...formData,
        blood_group: "",
      });
    }
    else if (name === "emergency_contact_rel" && value === "Select") {
      setFormData({
        ...formData,
        emergency_contact_rel: "",
      });
    }
    else if (name === "designation" && value === "Select Type") {
      setFormData({
        ...formData,
        designation: "",
      });
    }
    else if (name === "emp_role" && value === "Select Type") {
      setFormData({
        ...formData,
        emp_role: "",
      });
    }
    else if (name === "status" && value === "Select Type") {
      setFormData({
        ...formData,
        status: "",
      });
    }
    else if (name === "warehouse" && value === "Select") {
      setFormData({
        ...formData,
        warehouse: "",
      });
    }
    else if (name === "department" && value === "Select") {
      setFormData({
        ...formData,
        department: "",
      });
    }
    else {
      setFormData({
        ...formData,
        [name]: value, // Update formData as the user types
      });
    }
    console.log("input data", formData);
  };
  //---------
  const handleDobDateChange = (date) => {
    setFormData({
      ...formData,
      dob: date,
    });
  };
  const handleJoinDateChange = (date) => {
    setFormData({
      ...formData,
      join_date: date,
    });
  };
  //-----------
  const [uploadDetails, setUploadDetails] = useState([]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
  });

  const handleImageChange = async (files, name, id=null) => {
    let tempUploadDetails = uploadDetails;
    const allowedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    for (let i = 0; i < files.length; i++) {

      // Validate file type
      if (!allowedImageTypes.includes(files[i].type)) {
        FlashMessage(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`,'error');
        console.error(`Invalid file type for ${files[i].name}. Only JPG, JPEG, and PNG are allowed.`);
        continue; // Skip the current iteration for invalid file types
      }

      let size = files[i].size / 1024;
      let sizeName = '';

      if (size > 1024) {
        size = (size / 1024).toFixed(2);
        sizeName = size + 'MB';
      } else {
        sizeName = size.toFixed(2) + 'KB';
      }
      const resizedImage = await resizeFile(files[i]);
      // Extract base64 data
      const base64Data = resizedImage.split(',')[1];

      // Convert base64 to Blob
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "image/png" }); // Adjust the MIME type accordingly

      // Create a File from Blob
      const file = new File([blob],files[i].name, { type: "image/png" }); // Adjust the file name and MIME type accordingly

      const existingIndex = tempUploadDetails.findIndex(
          (variant) => variant.input_name === name
        );
        if (existingIndex !== -1) {
          loadingCallback(true);
          // If a variant with the same name exists, update it
          tempUploadDetails[existingIndex] = {
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          };
          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );
          if (brandLogo) {
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
            loadingCallback(false);
          }
        } else {
          loadingCallback(true);
          tempUploadDetails.push({
            name: files[i].name,
            size: sizeName,
            percentage: 0,
            file: file,
            input_name: name,
            image_path: URL.createObjectURL(files[i]),
          });

          const brandLogo = await uploadFileMultipleInputVariants(
            tempUploadDetails,
            name
          );

          if (brandLogo) {
            loadingCallback(false);
            setFormData({
              ...formData,
              [name]: brandLogo.path,
            });
          }
        }
    }
    setUploadDetails(tempUploadDetails);
  };

  //-----------
  const handleSubmit = (e) => {
    e.preventDefault();  // Prevent the default form submission

    setLoading(true); // Trigger loading state

    // Prepare the data to be sent to the API
    const employeeData = {
      emp_id: formData.id,  // Pass emp_id to update an existing employee or leave undefined to create a new one
      emp_image: formData.image,
      name: formData.emp_name,
      joining_date: formData.join_date,
      dob: formData.dob,
      address: formData.address,
      // pin: formData.pin,
      email: formData.emp_email,
      work_email: formData.work_email,
      mobile_number: formData.emp_phone,
      blood_group_id: formData.blood_group,
      designation_id: formData.designation,
      role_id: formData.emp_role,
      emergency_mobile_number: formData.emergency_contact,
      relation_with: formData.emergency_contact_rel,
      working_day: selectedDays.join(','), // Join selected days into a comma-separated string
      status: formData.status,
      u_email: formData.u_email,
      password: formData.password,
      link: formData.link,
      department_id: formData.department,
      warehouse_id: formData.warehouse
    };

    axios.post(
      `${process.env.REACT_APP_API_URL}update-employee`,  // API URL for both update and create
      employeeData,  // Send employee data
      {
        headers: {
          authorization: `Bearer ${token}`,  // Attach authorization token
          "Content-Type": "application/json", // Ensure proper content type
        },
      }
    )
    .then((response) => {
      setLoading(false);  // Stop loading
      if (response.data.success) {
        console.log("Employee updated successfully", response);
        setWarningMessage("Employee updated successfully!");
        navigate('/employee');  // Redirect to employee list
      } else {
        setWarningMessage("Error: Employee could not be updated.");
      }
    })
    .catch((error) => {
      setLoading(false);  // Stop loading
      console.error("Error updating employee:", error);
      setWarningMessage("Error updating employee. Please try again.");
    });
  };


  return(
    <ContentLayout>
      <div className="main-content recentTableContent">
      <ToastContainer />
        <form
        method="post"
        data-autosubmit="false"
        autoComplete="off"
        onSubmit={handleSubmit}
        >
          <div className="flex flex-row gap-2 items-center mb-5">
            {/* <Link className="block w-[24px]" to={`/employee`}>
              <img src={arrowLeft} className="w-[100%]" />
            </Link> */}
            <h1 className='text-[#000232] text-[22px] font-[600] font-lato'>Update Employee</h1>
          </div>
          <div className="text-center flex items-center justify-center py-4">
            <CommonUploader
              name={"image"}
              isBig={true}
              onChange={handleImageChange}
              image={formData.image ? formData.image : null}
              className="w-[140px]"
            ></CommonUploader>
          </div>
          {/* {formData?.emp_image && (
            <div className="text-center flex items-center justify-center py-3">
              <img
                src={formData.emp_image}
                alt="Employee"
                className="w-[140px] h-[140px] object-cover rounded-full"
              />
            </div>
          )} */}
          <div className="w-full">
            {/* Section 1 */}
            <div className="flex flex-col sm:flex-row gap-3 mb-4 font-semibold">Personal Information</div>
            <div className="flex flex-col sm:flex-row gap-3 mb-4">
              <div className="flex-1">
                <div className="relative">
                  <Input
                    label="Name"
                    id="emp_name"
                    name="emp_name"
                    placeholder="Name"
                    value={formData.emp_name}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-3 mb-4">
              <div className="flex-1">
                <div className="relative">
                  <Input
                    label="Email"
                    id="emp_email"
                    name="emp_email"
                    placeholder="Email"
                    value={formData.emp_email}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="flex-1">
                  <Input
                    label="Phone"
                    id="emp_phone"
                    name="emp_phone"
                    placeholder="Phone"
                    value={formData.emp_phone}
                    onChange={handleChange}
                    // error={formErrors.customer_phone}
                    // setFormErrors={setFormErrors}
                  />
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-3 mb-4">
              <div className="w-[100%] sm:w-[30%] relative border-[#C1CFEF] border py-2.5 flex-1 rounded-xl">
                <DatePicker
                    selected={formData.dob}
                    onChange={handleDobDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    className="w-full px-3 focus:outline-none text-sm"
                  />
                  <span className="bg-white font-nunito absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                    Date of Birth
                  </span>
              </div>
              <div className="w-[100%] sm:w-[30%] relative">
                <select
                  name="blood_group"
                  onChange={handleChange}
                  value={formData.blood_group}
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <option disabled=""> Select Blood Group</option>
                  {bloodGroupList.map((item, index) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Blood Group
                </label>
              </div>
              <div className="w-[100%] sm:w-[40%]">
                <Input
                  label="Address"
                  id="address"
                  name="address"
                  placeholder="Address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* Section 2 */}
            <div className="flex flex-col sm:flex-row gap-3 mb-4 font-semibold mt-4">Emergency Information</div>
            <div className="flex flex-col sm:flex-row gap-3 mb-4">
              <div className="flex-1">
                  <Input
                    label="Emergency Contact"
                    id="emergency_contact"
                    name="emergency_contact"
                    placeholder="Phone"
                    value={formData.emergency_contact}
                    onChange={handleChange}
                    // error={formErrors.customer_phone}
                    // setFormErrors={setFormErrors}
                  />
              </div>
              <div className="flex-1">
                <div className="relative">
                  <select
                    name="emergency_contact_rel"
                    className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                    style={{ maxHeight: "300px", overflowY: "scroll" }}
                    onChange={handleChange}
                    value={formData.emergency_contact_rel}
                  >
                    <option disabled=""> Select </option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Wife">Wife</option>
                    <option value="Husband">Husband</option>
                  </select>
                  <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                    Relationship with Emergency Contact
                  </label>
                </div>
              </div>
            </div>

            {/* Section 3 */}
            <div className="flex flex-col sm:flex-row gap-3 mb-4 font-semibold mt-4">Professional Information</div>
            <div className="flex flex-col sm:flex-row gap-3 mb-4">
              <div className=" w-[100%] sm:w-[33%] relative">
                <div className="relative">
                  <Input
                    label="Work Email"
                    id="work_email"
                    name="work_email"
                    placeholder="1234@gmail.com"
                    value={formData.work_email}
                    onChange={handleChange}
                    // error={formErrors.customer_email}
                    // setFormErrors={setFormErrors}
                  />
                </div>
              </div>
              <div className="w-[100%] sm:w-[25%] relative">
              <div className="relative">
                <Input
                  label="Password"
                  id="password"
                  name="password"
                  placeholder="*******"
                  value={formData.password}
                  onChange={handleChange}
                  // error={formErrors.customer_email}
                  // setFormErrors={setFormErrors}
                />
              </div>
              </div>
              <div className="w-[100%] sm:w-[33%] relative">
                  <select
                  name="warehouse"
                  onChange={handleChange}
                  value={formData.warehouse}
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <option disabled=""> Select </option>
                  {warehouseList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Warehouse
                </label>
              </div>
              <div className="w-[100%] sm:w-[33%] relative">
                <select
                  name="department"
                  onChange={handleChange}
                  value={formData.department}
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <option disabled=""> Select </option>
                  {departmentList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Department
                </label>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-3 mb-4">
              <div className="w-[100%] sm:w-[20%] relative">
                <select
                  name="designation"
                  onChange={handleChange}
                  value={formData.designation}
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <option disabled=""> Select Type </option>
                  {designationList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Designation
                </label>
              </div>
              <div className="w-[100%] sm:w-[20%] relative">
                <select
                  name="emp_role"
                  onChange={handleChange}
                  value={formData.emp_role}
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                >
                  <option disabled=""> Select Type </option>
                  {roleList.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Role
                </label>
              </div>
              <div className="w-[100%] sm:w-[20%] relative border-[#C1CFEF] border py-2.5 flex-1 rounded-xl">
                <DatePicker
                    selected={formData.join_date}
                    onChange={handleJoinDateChange}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    className="w-full px-3 focus:outline-none text-sm"
                  />
                  <span className="bg-white font-nunito absolute left-2 font-semibold px-1 text-xs top-0 text-[#102048] translate-y-[-50%]">
                    Joining Date
                  </span>
              </div>
              <div className="w-[100%] sm:w-[20%] relative">
                <select
                  name="status"
                  className="select w-full text-[#102048] font-normal placeholder:text-[#91919F] bg-transparent outline-none pl-[15px]  text-sm rounded-[10px] border border-[#C1CFEF] focus:outline-none"
                  style={{ maxHeight: "300px", overflowY: "scroll" }}
                  onChange={handleChange}
                  value={formData.status}
                >
                  <option disabled=""> Select Type </option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                <label className="absolute font-nunito text-xs text-[#102048] -top-[8px] left-[12px] bg-white px-1 font-semibold">
                  Active/Inactive
                </label>
              </div>
            </div>

            <div className="flex flex-col sm:flex-row gap-3 mb-4">
                  {days.map((day) => (
                    <div className="w-[100%] sm:w-[14%] relative" key={day}>
                      <div
                        className={`bg-white border-2 rounded-[16px] text-[18px] font-nunito font-[600] py-3 px-[50px] flex items-center justify-center mt-3 cursor-pointer
                          ${selectedDays.includes(day) ? 'text-blue-500 border-blue-500' : 'text-gray-400 border-gray-300'}`}
                        onClick={() => handleDayClick(day)}
                      >
                        {day}
                      </div>
                    </div>
                  ))}
            </div>

            <div className="flex items-center justify-center mt-3">
              <button
                type="submit"
                className="bg-[#407BFF] rounded-[16px] text-[#FFF] text-[18px] font-nunito font-[600] py-3 px-[167px] flex items-center mb-5"
              >
                Update
              </button>
            </div>
          </div>
        </form>

      </div>
    </ContentLayout>
  );

}

export default EmployeeEditPage;