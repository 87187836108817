
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/context';
import { formatPrice, formatDate } from '../../utils/Common';



const RecentPurchaseInvoiceItem = props => {
  const { invoice, className } = props;
  const { config, state } = useContext(AppContext);
  const { BASE_URL } = config;
  const navigate = useNavigate();

  const handleClick = e => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/purchase-invoice/${invoice.name}`);
  };
  const handleEditClick = e => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/purchase-invoice/edit/${invoice.name}`);
  };
  return (
    <div className="" >
      <div className="hrtable"></div>
      <div className="recent-table-data">
          <div className="first">
              {/* <div className="images">
                  <img src={`${BASE_URL}/dollaricon.svg`} alt="dollar" />
              </div> */}
              <div className="firstdata w-full">
                  <h1>{invoice.name}</h1>
                  <p>{invoice.sname}</p>
              </div>
          </div>
          <div className="second text-center">
              <p>{invoice.price}</p>
          </div>
          <div className='due text-center text-[#000] text-[16px] font-nunito font-500 flex items-center'>
            <p className='w-full'>{invoice.due}</p>
          </div>
          <div className="fourth">
              <h1>{formatDate(invoice.date)}</h1>
              <p>{invoice.time}</p>
          </div>
          <div className='fifth'>
            <div className='viewBtn cursor-pointer' onClick={handleClick}>View</div>
            {console.log('invoice ',invoice)}
            <div className='editBtn cursor-pointer' onClick={handleEditClick}>Edit</div>
          </div>
      </div>
    </div>
  );
};

RecentPurchaseInvoiceItem.defaultProps = { className: null };

export default RecentPurchaseInvoiceItem;